import * as t from "io-ts";
import { subscribedC as imported29_subscribedC } from "./subscribed";
import { rfpC as imported54_rfpC } from "./rfpBase";
import { StateInfoCU as imported7_StateInfoCU } from "../domaintables/states";
import { bondOfferingDetailsC as imported39_bondOfferingDetailsC } from "./bondOffering";
import { withIdC as imported222_withIdC, withIdC as imported223_withIdC } from "./threadThrough";
import { issuerWithAddressC as imported5_issuerWithAddressC, withIssuerC as imported11_withIssuerC, issuerC as imported46_issuerC, withIssuerC as imported10_withIssuerC } from "./issuer";

export const watchlistIssuerC = t.type({
  issuer: imported29_subscribedC(imported5_issuerWithAddressC),
  offerings: t.number,
  rfps: t.number
});
export type WatchlistIssuerC = typeof watchlistIssuerC;
export type WatchlistIssuer = t.TypeOf<WatchlistIssuerC>;


export const watchlistStateDataC = t.type({
  state: imported7_StateInfoCU,
  issuer: t.readonlyArray(imported46_issuerC),
  offerings: t.readonlyArray(imported10_withIssuerC(imported222_withIdC(imported39_bondOfferingDetailsC))),
  rfps: t.readonlyArray(imported11_withIssuerC(imported223_withIdC(imported54_rfpC)))
});
export type WatchlistStateDataC = typeof watchlistStateDataC;
export type WatchlistStateData = t.TypeOf<WatchlistStateDataC>;


