import * as t from "io-ts";
import { offeringParticipantWithTypeC as imported1_offeringParticipantWithTypeC } from "./offeringParticipant";
import { mediaC as imported89_mediaC } from "./media";
import { taggedContentC as imported211_taggedContentC, taggedContentC as imported212_taggedContentC, taggedContentC as imported213_taggedContentC } from "./taggedContent";
import { bankC as imported21_bankC } from "./bank";
import { bondOfferingC as imported28_bondOfferingC } from "./bondOfferingBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { roadShowDataC as imported33_roadShowDataC } from "./roadshow";
import { relatedOfferingParticipantLinkC as imported19_relatedOfferingParticipantLinkC } from "./relatedContent";
import { withIdC as imported278_withIdC, hasManyLinkC as imported175_hasManyLinkC, withIdC as imported276_withIdC, withIdC as imported277_withIdC, withIdC as imported275_withIdC } from "./threadThrough";
import { issuerWithAddressC as imported9_issuerWithAddressC } from "./issuer";

export const investorPortalOfferingC = t.type({
  offering: imported275_withIdC(imported211_taggedContentC(imported28_bondOfferingC)),
  issuer: imported9_issuerWithAddressC,
  bank: optionFromNullable(imported21_bankC),
  subscribed: t.boolean,
  documents: t.readonlyArray(imported276_withIdC(imported212_taggedContentC(imported89_mediaC))),
  roadshows: t.readonlyArray(imported277_withIdC(imported213_taggedContentC(imported33_roadShowDataC))),
  participants: t.readonlyArray(imported175_hasManyLinkC(imported278_withIdC(imported1_offeringParticipantWithTypeC), imported19_relatedOfferingParticipantLinkC))
});
export type InvestorPortalOfferingC = typeof investorPortalOfferingC;
export type InvestorPortalOffering = t.TypeOf<InvestorPortalOfferingC>;


