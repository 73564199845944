import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { issuerAdmin as imported114_issuerAdmin, bankerAdmin as imported22_bankerAdmin, bankerUser as imported21_bankerUser, issuerUser as imported103_issuerUser, salesAdmin as imported1_salesAdmin, admin as imported2_admin, admin as imported3_admin, issuerAdmin as imported115_issuerAdmin } from "./roles";

export const billing = {
  _tag: `Billing`,
  name: `Billing`
} as const;

export const billingTaggedC = t.type({
  _tag: t.literal(`Billing`)
});
export type BillingTaggedC = typeof billingTaggedC;
export type BillingTagged = t.TypeOf<BillingTaggedC>;
export type Billing = BillingTagged & typeof billing;
export const billingC = pipe(billingTaggedC, c => new t.Type<Billing, BillingTagged>(
  `Billing`,
  (u: unknown): u is Billing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Billing> => pipe(c.decode(u), E.map(x => ({ ...x, ...billing }))),
  (x: Billing): BillingTagged => ({ ...x, _tag: `Billing`}),
));
export type BillingC = typeof billingC;


export const disclaimer = {
  _tag: `Disclaimer`,
  name: `Disclaimers`
} as const;

export const disclaimerTaggedC = t.type({
  _tag: t.literal(`Disclaimer`)
});
export type DisclaimerTaggedC = typeof disclaimerTaggedC;
export type DisclaimerTagged = t.TypeOf<DisclaimerTaggedC>;
export type Disclaimer = DisclaimerTagged & typeof disclaimer;
export const disclaimerC = pipe(disclaimerTaggedC, c => new t.Type<Disclaimer, DisclaimerTagged>(
  `Disclaimer`,
  (u: unknown): u is Disclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Disclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...disclaimer }))),
  (x: Disclaimer): DisclaimerTagged => ({ ...x, _tag: `Disclaimer`}),
));
export type DisclaimerC = typeof disclaimerC;


export const internalFlag = {
  _tag: `InternalFlag`,
  name: `Internal Flags`
} as const;

export const internalFlagTaggedC = t.type({
  _tag: t.literal(`InternalFlag`)
});
export type InternalFlagTaggedC = typeof internalFlagTaggedC;
export type InternalFlagTagged = t.TypeOf<InternalFlagTaggedC>;
export type InternalFlag = InternalFlagTagged & typeof internalFlag;
export const internalFlagC = pipe(internalFlagTaggedC, c => new t.Type<InternalFlag, InternalFlagTagged>(
  `InternalFlag`,
  (u: unknown): u is InternalFlag => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InternalFlag> => pipe(c.decode(u), E.map(x => ({ ...x, ...internalFlag }))),
  (x: InternalFlag): InternalFlagTagged => ({ ...x, _tag: `InternalFlag`}),
));
export type InternalFlagC = typeof internalFlagC;


export const investorRequirement = {
  _tag: `InvestorRequirement`,
  name: `Investor Requirements`
} as const;

export const investorRequirementTaggedC = t.type({
  _tag: t.literal(`InvestorRequirement`)
});
export type InvestorRequirementTaggedC = typeof investorRequirementTaggedC;
export type InvestorRequirementTagged = t.TypeOf<InvestorRequirementTaggedC>;
export type InvestorRequirement = InvestorRequirementTagged & typeof investorRequirement;
export const investorRequirementC = pipe(investorRequirementTaggedC, c => new t.Type<InvestorRequirement, InvestorRequirementTagged>(
  `InvestorRequirement`,
  (u: unknown): u is InvestorRequirement => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorRequirement> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorRequirement }))),
  (x: InvestorRequirement): InvestorRequirementTagged => ({ ...x, _tag: `InvestorRequirement`}),
));
export type InvestorRequirementC = typeof investorRequirementC;


export const sectionToggle = {
  _tag: `SectionToggle`,
  name: `Section Toggles`
} as const;

export const sectionToggleTaggedC = t.type({
  _tag: t.literal(`SectionToggle`)
});
export type SectionToggleTaggedC = typeof sectionToggleTaggedC;
export type SectionToggleTagged = t.TypeOf<SectionToggleTaggedC>;
export type SectionToggle = SectionToggleTagged & typeof sectionToggle;
export const sectionToggleC = pipe(sectionToggleTaggedC, c => new t.Type<SectionToggle, SectionToggleTagged>(
  `SectionToggle`,
  (u: unknown): u is SectionToggle => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SectionToggle> => pipe(c.decode(u), E.map(x => ({ ...x, ...sectionToggle }))),
  (x: SectionToggle): SectionToggleTagged => ({ ...x, _tag: `SectionToggle`}),
));
export type SectionToggleC = typeof sectionToggleC;


export const visual = {
  _tag: `Visual`,
  name: `Visual`
} as const;

export const visualTaggedC = t.type({
  _tag: t.literal(`Visual`)
});
export type VisualTaggedC = typeof visualTaggedC;
export type VisualTagged = t.TypeOf<VisualTaggedC>;
export type Visual = VisualTagged & typeof visual;
export const visualC = pipe(visualTaggedC, c => new t.Type<Visual, VisualTagged>(
  `Visual`,
  (u: unknown): u is Visual => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Visual> => pipe(c.decode(u), E.map(x => ({ ...x, ...visual }))),
  (x: Visual): VisualTagged => ({ ...x, _tag: `Visual`}),
));
export type VisualC = typeof visualC;


export const allFeatureTypeC = [billingC, disclaimerC, internalFlagC, investorRequirementC, sectionToggleC, visualC] as const;
export const allFeatureTypeNames = [`Billing`, `Disclaimer`, `InternalFlag`, `InvestorRequirement`, `SectionToggle`, `Visual`] as const;
export type FeatureTypeName = (typeof allFeatureTypeNames)[number];

export const FeatureTypeCU = t.union([billingC, disclaimerC, internalFlagC, investorRequirementC, sectionToggleC, visualC]);
export type FeatureTypeCU = typeof FeatureTypeCU;
export type FeatureTypeU = t.TypeOf<FeatureTypeCU>;

export const featureTypeOrd: Ord.Ord<FeatureTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allFeatureType = [billing, disclaimer, internalFlag, investorRequirement, sectionToggle, visual] as const;
export type FeatureTypeMap<A> = { [K in FeatureTypeName]: A };


export const bankUserRoles = {
  _tag: `BankUserRoles`,
  roles: [imported22_bankerAdmin, imported21_bankerUser]
} as const;

export const bankUserRolesTaggedC = t.type({
  _tag: t.literal(`BankUserRoles`)
});
export type BankUserRolesTaggedC = typeof bankUserRolesTaggedC;
export type BankUserRolesTagged = t.TypeOf<BankUserRolesTaggedC>;
export type BankUserRoles = BankUserRolesTagged & typeof bankUserRoles;
export const bankUserRolesC = pipe(bankUserRolesTaggedC, c => new t.Type<BankUserRoles, BankUserRolesTagged>(
  `BankUserRoles`,
  (u: unknown): u is BankUserRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankUserRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankUserRoles }))),
  (x: BankUserRoles): BankUserRolesTagged => ({ ...x, _tag: `BankUserRoles`}),
));
export type BankUserRolesC = typeof bankUserRolesC;


export const issuerUserRoles = {
  _tag: `IssuerUserRoles`,
  roles: [imported114_issuerAdmin, imported103_issuerUser]
} as const;

export const issuerUserRolesTaggedC = t.type({
  _tag: t.literal(`IssuerUserRoles`)
});
export type IssuerUserRolesTaggedC = typeof issuerUserRolesTaggedC;
export type IssuerUserRolesTagged = t.TypeOf<IssuerUserRolesTaggedC>;
export type IssuerUserRoles = IssuerUserRolesTagged & typeof issuerUserRoles;
export const issuerUserRolesC = pipe(issuerUserRolesTaggedC, c => new t.Type<IssuerUserRoles, IssuerUserRolesTagged>(
  `IssuerUserRoles`,
  (u: unknown): u is IssuerUserRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerUserRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerUserRoles }))),
  (x: IssuerUserRoles): IssuerUserRolesTagged => ({ ...x, _tag: `IssuerUserRoles`}),
));
export type IssuerUserRolesC = typeof issuerUserRolesC;


export const issuerAdminRoles = {
  _tag: `IssuerAdminRoles`,
  roles: [imported115_issuerAdmin]
} as const;

export const issuerAdminRolesTaggedC = t.type({
  _tag: t.literal(`IssuerAdminRoles`)
});
export type IssuerAdminRolesTaggedC = typeof issuerAdminRolesTaggedC;
export type IssuerAdminRolesTagged = t.TypeOf<IssuerAdminRolesTaggedC>;
export type IssuerAdminRoles = IssuerAdminRolesTagged & typeof issuerAdminRoles;
export const issuerAdminRolesC = pipe(issuerAdminRolesTaggedC, c => new t.Type<IssuerAdminRoles, IssuerAdminRolesTagged>(
  `IssuerAdminRoles`,
  (u: unknown): u is IssuerAdminRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerAdminRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerAdminRoles }))),
  (x: IssuerAdminRoles): IssuerAdminRolesTagged => ({ ...x, _tag: `IssuerAdminRoles`}),
));
export type IssuerAdminRolesC = typeof issuerAdminRolesC;


export const bondLinkAdminRoles = {
  _tag: `BondLinkAdminRoles`,
  roles: [imported2_admin, imported1_salesAdmin]
} as const;

export const bondLinkAdminRolesTaggedC = t.type({
  _tag: t.literal(`BondLinkAdminRoles`)
});
export type BondLinkAdminRolesTaggedC = typeof bondLinkAdminRolesTaggedC;
export type BondLinkAdminRolesTagged = t.TypeOf<BondLinkAdminRolesTaggedC>;
export type BondLinkAdminRoles = BondLinkAdminRolesTagged & typeof bondLinkAdminRoles;
export const bondLinkAdminRolesC = pipe(bondLinkAdminRolesTaggedC, c => new t.Type<BondLinkAdminRoles, BondLinkAdminRolesTagged>(
  `BondLinkAdminRoles`,
  (u: unknown): u is BondLinkAdminRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkAdminRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkAdminRoles }))),
  (x: BondLinkAdminRoles): BondLinkAdminRolesTagged => ({ ...x, _tag: `BondLinkAdminRoles`}),
));
export type BondLinkAdminRolesC = typeof bondLinkAdminRolesC;


export const bondLinkSuperAdminRoles = {
  _tag: `BondLinkSuperAdminRoles`,
  roles: [imported3_admin]
} as const;

export const bondLinkSuperAdminRolesTaggedC = t.type({
  _tag: t.literal(`BondLinkSuperAdminRoles`)
});
export type BondLinkSuperAdminRolesTaggedC = typeof bondLinkSuperAdminRolesTaggedC;
export type BondLinkSuperAdminRolesTagged = t.TypeOf<BondLinkSuperAdminRolesTaggedC>;
export type BondLinkSuperAdminRoles = BondLinkSuperAdminRolesTagged & typeof bondLinkSuperAdminRoles;
export const bondLinkSuperAdminRolesC = pipe(bondLinkSuperAdminRolesTaggedC, c => new t.Type<BondLinkSuperAdminRoles, BondLinkSuperAdminRolesTagged>(
  `BondLinkSuperAdminRoles`,
  (u: unknown): u is BondLinkSuperAdminRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkSuperAdminRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkSuperAdminRoles }))),
  (x: BondLinkSuperAdminRoles): BondLinkSuperAdminRolesTagged => ({ ...x, _tag: `BondLinkSuperAdminRoles`}),
));
export type BondLinkSuperAdminRolesC = typeof bondLinkSuperAdminRolesC;


export const allFeatureRolesC = [bankUserRolesC, issuerUserRolesC, issuerAdminRolesC, bondLinkAdminRolesC, bondLinkSuperAdminRolesC] as const;
export const allFeatureRolesNames = [`BankUserRoles`, `IssuerUserRoles`, `IssuerAdminRoles`, `BondLinkAdminRoles`, `BondLinkSuperAdminRoles`] as const;
export type FeatureRolesName = (typeof allFeatureRolesNames)[number];

export const FeatureRolesCU = t.union([bankUserRolesC, issuerUserRolesC, issuerAdminRolesC, bondLinkAdminRolesC, bondLinkSuperAdminRolesC]);
export type FeatureRolesCU = typeof FeatureRolesCU;
export type FeatureRolesU = t.TypeOf<FeatureRolesCU>;

export const featureRolesOrd: Ord.Ord<FeatureRolesU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allFeatureRoles = [bankUserRoles, issuerUserRoles, issuerAdminRoles, bondLinkAdminRoles, bondLinkSuperAdminRoles] as const;
export type FeatureRolesMap<A> = { [K in FeatureRolesName]: A };


export const bankCalendar = {
  _tag: `BankCalendar`,
  default: false,
  dependsOn: O.none,
  description: `Show bank calendar on site`,
  featureType: sectionToggle,
  humanName: `Bank Calendar`,
  id: 85,
  notes: O.none,
  roles: bankUserRoles
} as const;

export const bankCalendarTaggedC = t.type({
  _tag: t.literal(`BankCalendar`)
});
export type BankCalendarTaggedC = typeof bankCalendarTaggedC;
export type BankCalendarTagged = t.TypeOf<BankCalendarTaggedC>;
export type BankCalendar = BankCalendarTagged & typeof bankCalendar;
export const bankCalendarC = pipe(bankCalendarTaggedC, c => new t.Type<BankCalendar, BankCalendarTagged>(
  `BankCalendar`,
  (u: unknown): u is BankCalendar => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankCalendar> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankCalendar }))),
  (x: BankCalendar): BankCalendarTagged => ({ ...x, _tag: `BankCalendar`}),
));
export type BankCalendarC = typeof bankCalendarC;


export const dealView = {
  _tag: `DealView`,
  default: true,
  dependsOn: O.none,
  description: `Show deal view on site`,
  featureType: sectionToggle,
  humanName: `Deal View`,
  id: 88,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const dealViewTaggedC = t.type({
  _tag: t.literal(`DealView`)
});
export type DealViewTaggedC = typeof dealViewTaggedC;
export type DealViewTagged = t.TypeOf<DealViewTaggedC>;
export type DealView = DealViewTagged & typeof dealView;
export const dealViewC = pipe(dealViewTaggedC, c => new t.Type<DealView, DealViewTagged>(
  `DealView`,
  (u: unknown): u is DealView => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DealView> => pipe(c.decode(u), E.map(x => ({ ...x, ...dealView }))),
  (x: DealView): DealViewTagged => ({ ...x, _tag: `DealView`}),
));
export type DealViewC = typeof dealViewC;


export const copyBankAsIssuerParticipant = {
  _tag: `CopyBankAsIssuerParticipant`,
  default: false,
  dependsOn: O.none,
  description: `Whether to add the bank as an offering participant for every newly created issuer`,
  featureType: internalFlag,
  humanName: `Copy Bank As Issuer Participant`,
  id: 89,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const copyBankAsIssuerParticipantTaggedC = t.type({
  _tag: t.literal(`CopyBankAsIssuerParticipant`)
});
export type CopyBankAsIssuerParticipantTaggedC = typeof copyBankAsIssuerParticipantTaggedC;
export type CopyBankAsIssuerParticipantTagged = t.TypeOf<CopyBankAsIssuerParticipantTaggedC>;
export type CopyBankAsIssuerParticipant = CopyBankAsIssuerParticipantTagged & typeof copyBankAsIssuerParticipant;
export const copyBankAsIssuerParticipantC = pipe(copyBankAsIssuerParticipantTaggedC, c => new t.Type<CopyBankAsIssuerParticipant, CopyBankAsIssuerParticipantTagged>(
  `CopyBankAsIssuerParticipant`,
  (u: unknown): u is CopyBankAsIssuerParticipant => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CopyBankAsIssuerParticipant> => pipe(c.decode(u), E.map(x => ({ ...x, ...copyBankAsIssuerParticipant }))),
  (x: CopyBankAsIssuerParticipant): CopyBankAsIssuerParticipantTagged => ({ ...x, _tag: `CopyBankAsIssuerParticipant`}),
));
export type CopyBankAsIssuerParticipantC = typeof copyBankAsIssuerParticipantC;


export const bondOfferings = {
  _tag: `BondOfferings`,
  default: false,
  dependsOn: O.none,
  description: `Show bond offerings on site`,
  featureType: sectionToggle,
  humanName: `Bond Offerings`,
  id: 1,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const bondOfferingsTaggedC = t.type({
  _tag: t.literal(`BondOfferings`)
});
export type BondOfferingsTaggedC = typeof bondOfferingsTaggedC;
export type BondOfferingsTagged = t.TypeOf<BondOfferingsTaggedC>;
export type BondOfferings = BondOfferingsTagged & typeof bondOfferings;
export const bondOfferingsC = pipe(bondOfferingsTaggedC, c => new t.Type<BondOfferings, BondOfferingsTagged>(
  `BondOfferings`,
  (u: unknown): u is BondOfferings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferings }))),
  (x: BondOfferings): BondOfferingsTagged => ({ ...x, _tag: `BondOfferings`}),
));
export type BondOfferingsC = typeof bondOfferingsC;


export const bondOfferingsGeneralInfo = {
  _tag: `BondOfferingsGeneralInfo`,
  default: false,
  dependsOn: O.none,
  description: `Show general offering information message`,
  featureType: sectionToggle,
  humanName: `Bond Offerings General Info`,
  id: 3,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const bondOfferingsGeneralInfoTaggedC = t.type({
  _tag: t.literal(`BondOfferingsGeneralInfo`)
});
export type BondOfferingsGeneralInfoTaggedC = typeof bondOfferingsGeneralInfoTaggedC;
export type BondOfferingsGeneralInfoTagged = t.TypeOf<BondOfferingsGeneralInfoTaggedC>;
export type BondOfferingsGeneralInfo = BondOfferingsGeneralInfoTagged & typeof bondOfferingsGeneralInfo;
export const bondOfferingsGeneralInfoC = pipe(bondOfferingsGeneralInfoTaggedC, c => new t.Type<BondOfferingsGeneralInfo, BondOfferingsGeneralInfoTagged>(
  `BondOfferingsGeneralInfo`,
  (u: unknown): u is BondOfferingsGeneralInfo => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsGeneralInfo> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsGeneralInfo }))),
  (x: BondOfferingsGeneralInfo): BondOfferingsGeneralInfoTagged => ({ ...x, _tag: `BondOfferingsGeneralInfo`}),
));
export type BondOfferingsGeneralInfoC = typeof bondOfferingsGeneralInfoC;


export const bondOfferingsDisclaimer = {
  _tag: `BondOfferingsDisclaimer`,
  default: true,
  dependsOn: O.none,
  description: `Enable offering disclaimer`,
  featureType: disclaimer,
  humanName: `Bond Offerings Disclaimer`,
  id: 4,
  notes: O.some(`When enabled, a link to the disclaimer will automatically be added to your site footer.`),
  roles: issuerUserRoles
} as const;

export const bondOfferingsDisclaimerTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDisclaimer`)
});
export type BondOfferingsDisclaimerTaggedC = typeof bondOfferingsDisclaimerTaggedC;
export type BondOfferingsDisclaimerTagged = t.TypeOf<BondOfferingsDisclaimerTaggedC>;
export type BondOfferingsDisclaimer = BondOfferingsDisclaimerTagged & typeof bondOfferingsDisclaimer;
export const bondOfferingsDisclaimerC = pipe(bondOfferingsDisclaimerTaggedC, c => new t.Type<BondOfferingsDisclaimer, BondOfferingsDisclaimerTagged>(
  `BondOfferingsDisclaimer`,
  (u: unknown): u is BondOfferingsDisclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDisclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDisclaimer }))),
  (x: BondOfferingsDisclaimer): BondOfferingsDisclaimerTagged => ({ ...x, _tag: `BondOfferingsDisclaimer`}),
));
export type BondOfferingsDisclaimerC = typeof bondOfferingsDisclaimerC;


export const documents = {
  _tag: `Documents`,
  default: true,
  dependsOn: O.none,
  description: `Show documents on site`,
  featureType: sectionToggle,
  humanName: `Documents`,
  id: 55,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const documentsTaggedC = t.type({
  _tag: t.literal(`Documents`)
});
export type DocumentsTaggedC = typeof documentsTaggedC;
export type DocumentsTagged = t.TypeOf<DocumentsTaggedC>;
export type Documents = DocumentsTagged & typeof documents;
export const documentsC = pipe(documentsTaggedC, c => new t.Type<Documents, DocumentsTagged>(
  `Documents`,
  (u: unknown): u is Documents => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Documents> => pipe(c.decode(u), E.map(x => ({ ...x, ...documents }))),
  (x: Documents): DocumentsTagged => ({ ...x, _tag: `Documents`}),
));
export type DocumentsC = typeof documentsC;


export const reportsRequireLogin = {
  _tag: `ReportsRequireLogin`,
  default: false,
  dependsOn: O.none,
  description: `Require visitors to log in before viewing or downloading documents`,
  featureType: investorRequirement,
  humanName: `Reports Require Login`,
  id: 5,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const reportsRequireLoginTaggedC = t.type({
  _tag: t.literal(`ReportsRequireLogin`)
});
export type ReportsRequireLoginTaggedC = typeof reportsRequireLoginTaggedC;
export type ReportsRequireLoginTagged = t.TypeOf<ReportsRequireLoginTaggedC>;
export type ReportsRequireLogin = ReportsRequireLoginTagged & typeof reportsRequireLogin;
export const reportsRequireLoginC = pipe(reportsRequireLoginTaggedC, c => new t.Type<ReportsRequireLogin, ReportsRequireLoginTagged>(
  `ReportsRequireLogin`,
  (u: unknown): u is ReportsRequireLogin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ReportsRequireLogin> => pipe(c.decode(u), E.map(x => ({ ...x, ...reportsRequireLogin }))),
  (x: ReportsRequireLogin): ReportsRequireLoginTagged => ({ ...x, _tag: `ReportsRequireLogin`}),
));
export type ReportsRequireLoginC = typeof reportsRequireLoginC;


export const siteDisclaimer = {
  _tag: `SiteDisclaimer`,
  default: false,
  dependsOn: O.none,
  description: `Enable general disclaimer`,
  featureType: disclaimer,
  humanName: `Site Disclaimer`,
  id: 6,
  notes: O.some(`When enabled, a link to the disclaimer will automatically be added to your site footer.`),
  roles: issuerUserRoles
} as const;

export const siteDisclaimerTaggedC = t.type({
  _tag: t.literal(`SiteDisclaimer`)
});
export type SiteDisclaimerTaggedC = typeof siteDisclaimerTaggedC;
export type SiteDisclaimerTagged = t.TypeOf<SiteDisclaimerTaggedC>;
export type SiteDisclaimer = SiteDisclaimerTagged & typeof siteDisclaimer;
export const siteDisclaimerC = pipe(siteDisclaimerTaggedC, c => new t.Type<SiteDisclaimer, SiteDisclaimerTagged>(
  `SiteDisclaimer`,
  (u: unknown): u is SiteDisclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SiteDisclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...siteDisclaimer }))),
  (x: SiteDisclaimer): SiteDisclaimerTagged => ({ ...x, _tag: `SiteDisclaimer`}),
));
export type SiteDisclaimerC = typeof siteDisclaimerC;


export const siteDisclaimerModal = {
  _tag: `SiteDisclaimerModal`,
  default: false,
  dependsOn: O.some(siteDisclaimer),
  description: `Require visitors to accept general disclaimer before viewing site`,
  featureType: disclaimer,
  humanName: `Site Disclaimer Modal`,
  id: 7,
  notes: O.some(`You must have "Enable general disclaimer" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export const siteDisclaimerModalTaggedC = t.type({
  _tag: t.literal(`SiteDisclaimerModal`)
});
export type SiteDisclaimerModalTaggedC = typeof siteDisclaimerModalTaggedC;
export type SiteDisclaimerModalTagged = t.TypeOf<SiteDisclaimerModalTaggedC>;
export type SiteDisclaimerModal = SiteDisclaimerModalTagged & typeof siteDisclaimerModal;
export const siteDisclaimerModalC = pipe(siteDisclaimerModalTaggedC, c => new t.Type<SiteDisclaimerModal, SiteDisclaimerModalTagged>(
  `SiteDisclaimerModal`,
  (u: unknown): u is SiteDisclaimerModal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SiteDisclaimerModal> => pipe(c.decode(u), E.map(x => ({ ...x, ...siteDisclaimerModal }))),
  (x: SiteDisclaimerModal): SiteDisclaimerModalTagged => ({ ...x, _tag: `SiteDisclaimerModal`}),
));
export type SiteDisclaimerModalC = typeof siteDisclaimerModalC;


export const offeringsDisclaimerModal = {
  _tag: `OfferingsDisclaimerModal`,
  default: true,
  dependsOn: O.some(bondOfferingsDisclaimer),
  description: `Require visitors to accept offering disclaimer before viewing bond information`,
  featureType: disclaimer,
  humanName: `Offerings Disclaimer Modal`,
  id: 8,
  notes: O.some(`You must have "Enable offering disclaimer" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export const offeringsDisclaimerModalTaggedC = t.type({
  _tag: t.literal(`OfferingsDisclaimerModal`)
});
export type OfferingsDisclaimerModalTaggedC = typeof offeringsDisclaimerModalTaggedC;
export type OfferingsDisclaimerModalTagged = t.TypeOf<OfferingsDisclaimerModalTaggedC>;
export type OfferingsDisclaimerModal = OfferingsDisclaimerModalTagged & typeof offeringsDisclaimerModal;
export const offeringsDisclaimerModalC = pipe(offeringsDisclaimerModalTaggedC, c => new t.Type<OfferingsDisclaimerModal, OfferingsDisclaimerModalTagged>(
  `OfferingsDisclaimerModal`,
  (u: unknown): u is OfferingsDisclaimerModal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingsDisclaimerModal> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringsDisclaimerModal }))),
  (x: OfferingsDisclaimerModal): OfferingsDisclaimerModalTagged => ({ ...x, _tag: `OfferingsDisclaimerModal`}),
));
export type OfferingsDisclaimerModalC = typeof offeringsDisclaimerModalC;


export const relatedIssuers = {
  _tag: `RelatedIssuers`,
  default: false,
  dependsOn: O.none,
  description: `Show related issuers in site navigation`,
  featureType: sectionToggle,
  humanName: `Related Issuers`,
  id: 9,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const relatedIssuersTaggedC = t.type({
  _tag: t.literal(`RelatedIssuers`)
});
export type RelatedIssuersTaggedC = typeof relatedIssuersTaggedC;
export type RelatedIssuersTagged = t.TypeOf<RelatedIssuersTaggedC>;
export type RelatedIssuers = RelatedIssuersTagged & typeof relatedIssuers;
export const relatedIssuersC = pipe(relatedIssuersTaggedC, c => new t.Type<RelatedIssuers, RelatedIssuersTagged>(
  `RelatedIssuers`,
  (u: unknown): u is RelatedIssuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RelatedIssuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...relatedIssuers }))),
  (x: RelatedIssuers): RelatedIssuersTagged => ({ ...x, _tag: `RelatedIssuers`}),
));
export type RelatedIssuersC = typeof relatedIssuersC;


export const projects = {
  _tag: `Projects`,
  default: true,
  dependsOn: O.none,
  description: `Show projects on site`,
  featureType: sectionToggle,
  humanName: `Projects`,
  id: 13,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const projectsTaggedC = t.type({
  _tag: t.literal(`Projects`)
});
export type ProjectsTaggedC = typeof projectsTaggedC;
export type ProjectsTagged = t.TypeOf<ProjectsTaggedC>;
export type Projects = ProjectsTagged & typeof projects;
export const projectsC = pipe(projectsTaggedC, c => new t.Type<Projects, ProjectsTagged>(
  `Projects`,
  (u: unknown): u is Projects => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Projects> => pipe(c.decode(u), E.map(x => ({ ...x, ...projects }))),
  (x: Projects): ProjectsTagged => ({ ...x, _tag: `Projects`}),
));
export type ProjectsC = typeof projectsC;


export const emma = {
  _tag: `Emma`,
  default: true,
  dependsOn: O.none,
  description: `Show CUSIP-9 section on site`,
  featureType: sectionToggle,
  humanName: `Emma`,
  id: 19,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const emmaTaggedC = t.type({
  _tag: t.literal(`Emma`)
});
export type EmmaTaggedC = typeof emmaTaggedC;
export type EmmaTagged = t.TypeOf<EmmaTaggedC>;
export type Emma = EmmaTagged & typeof emma;
export const emmaC = pipe(emmaTaggedC, c => new t.Type<Emma, EmmaTagged>(
  `Emma`,
  (u: unknown): u is Emma => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Emma> => pipe(c.decode(u), E.map(x => ({ ...x, ...emma }))),
  (x: Emma): EmmaTagged => ({ ...x, _tag: `Emma`}),
));
export type EmmaC = typeof emmaC;


export const draftMode = {
  _tag: `DraftMode`,
  default: false,
  dependsOn: O.none,
  description: `Require draft approval before publishing changes`,
  featureType: sectionToggle,
  humanName: `Draft Mode`,
  id: 23,
  notes: O.none,
  roles: issuerAdminRoles
} as const;

export const draftModeTaggedC = t.type({
  _tag: t.literal(`DraftMode`)
});
export type DraftModeTaggedC = typeof draftModeTaggedC;
export type DraftModeTagged = t.TypeOf<DraftModeTaggedC>;
export type DraftMode = DraftModeTagged & typeof draftMode;
export const draftModeC = pipe(draftModeTaggedC, c => new t.Type<DraftMode, DraftModeTagged>(
  `DraftMode`,
  (u: unknown): u is DraftMode => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DraftMode> => pipe(c.decode(u), E.map(x => ({ ...x, ...draftMode }))),
  (x: DraftMode): DraftModeTagged => ({ ...x, _tag: `DraftMode`}),
));
export type DraftModeC = typeof draftModeC;


export const exitDisclaimer = {
  _tag: `ExitDisclaimer`,
  default: false,
  dependsOn: O.none,
  description: `Show exit disclaimer before viewing a related issuer site`,
  featureType: disclaimer,
  humanName: `Exit Disclaimer`,
  id: 24,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const exitDisclaimerTaggedC = t.type({
  _tag: t.literal(`ExitDisclaimer`)
});
export type ExitDisclaimerTaggedC = typeof exitDisclaimerTaggedC;
export type ExitDisclaimerTagged = t.TypeOf<ExitDisclaimerTaggedC>;
export type ExitDisclaimer = ExitDisclaimerTagged & typeof exitDisclaimer;
export const exitDisclaimerC = pipe(exitDisclaimerTaggedC, c => new t.Type<ExitDisclaimer, ExitDisclaimerTagged>(
  `ExitDisclaimer`,
  (u: unknown): u is ExitDisclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ExitDisclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...exitDisclaimer }))),
  (x: ExitDisclaimer): ExitDisclaimerTagged => ({ ...x, _tag: `ExitDisclaimer`}),
));
export type ExitDisclaimerC = typeof exitDisclaimerC;


export const news = {
  _tag: `News`,
  default: true,
  dependsOn: O.none,
  description: `Show news on site`,
  featureType: sectionToggle,
  humanName: `News`,
  id: 26,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const newsTaggedC = t.type({
  _tag: t.literal(`News`)
});
export type NewsTaggedC = typeof newsTaggedC;
export type NewsTagged = t.TypeOf<NewsTaggedC>;
export type News = NewsTagged & typeof news;
export const newsC = pipe(newsTaggedC, c => new t.Type<News, NewsTagged>(
  `News`,
  (u: unknown): u is News => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, News> => pipe(c.decode(u), E.map(x => ({ ...x, ...news }))),
  (x: News): NewsTagged => ({ ...x, _tag: `News`}),
));
export type NewsC = typeof newsC;


export const faq = {
  _tag: `Faq`,
  default: true,
  dependsOn: O.none,
  description: `Show FAQ page on site`,
  featureType: sectionToggle,
  humanName: `Faq`,
  id: 27,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const faqTaggedC = t.type({
  _tag: t.literal(`Faq`)
});
export type FaqTaggedC = typeof faqTaggedC;
export type FaqTagged = t.TypeOf<FaqTaggedC>;
export type Faq = FaqTagged & typeof faq;
export const faqC = pipe(faqTaggedC, c => new t.Type<Faq, FaqTagged>(
  `Faq`,
  (u: unknown): u is Faq => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Faq> => pipe(c.decode(u), E.map(x => ({ ...x, ...faq }))),
  (x: Faq): FaqTagged => ({ ...x, _tag: `Faq`}),
));
export type FaqC = typeof faqC;


export const bondRatings = {
  _tag: `BondRatings`,
  default: true,
  dependsOn: O.none,
  description: `Show bond ratings on site`,
  featureType: sectionToggle,
  humanName: `Bond Ratings`,
  id: 29,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const bondRatingsTaggedC = t.type({
  _tag: t.literal(`BondRatings`)
});
export type BondRatingsTaggedC = typeof bondRatingsTaggedC;
export type BondRatingsTagged = t.TypeOf<BondRatingsTaggedC>;
export type BondRatings = BondRatingsTagged & typeof bondRatings;
export const bondRatingsC = pipe(bondRatingsTaggedC, c => new t.Type<BondRatings, BondRatingsTagged>(
  `BondRatings`,
  (u: unknown): u is BondRatings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondRatings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondRatings }))),
  (x: BondRatings): BondRatingsTagged => ({ ...x, _tag: `BondRatings`}),
));
export type BondRatingsC = typeof bondRatingsC;


export const showArchivedDocs = {
  _tag: `ShowArchivedDocs`,
  default: false,
  dependsOn: O.none,
  description: `Show document archive on site`,
  featureType: sectionToggle,
  humanName: `Show Archived Docs`,
  id: 32,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const showArchivedDocsTaggedC = t.type({
  _tag: t.literal(`ShowArchivedDocs`)
});
export type ShowArchivedDocsTaggedC = typeof showArchivedDocsTaggedC;
export type ShowArchivedDocsTagged = t.TypeOf<ShowArchivedDocsTaggedC>;
export type ShowArchivedDocs = ShowArchivedDocsTagged & typeof showArchivedDocs;
export const showArchivedDocsC = pipe(showArchivedDocsTaggedC, c => new t.Type<ShowArchivedDocs, ShowArchivedDocsTagged>(
  `ShowArchivedDocs`,
  (u: unknown): u is ShowArchivedDocs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ShowArchivedDocs> => pipe(c.decode(u), E.map(x => ({ ...x, ...showArchivedDocs }))),
  (x: ShowArchivedDocs): ShowArchivedDocsTagged => ({ ...x, _tag: `ShowArchivedDocs`}),
));
export type ShowArchivedDocsC = typeof showArchivedDocsC;


export const financeTeam = {
  _tag: `FinanceTeam`,
  default: true,
  dependsOn: O.none,
  description: `Show team on site`,
  featureType: sectionToggle,
  humanName: `Finance Team`,
  id: 34,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const financeTeamTaggedC = t.type({
  _tag: t.literal(`FinanceTeam`)
});
export type FinanceTeamTaggedC = typeof financeTeamTaggedC;
export type FinanceTeamTagged = t.TypeOf<FinanceTeamTaggedC>;
export type FinanceTeam = FinanceTeamTagged & typeof financeTeam;
export const financeTeamC = pipe(financeTeamTaggedC, c => new t.Type<FinanceTeam, FinanceTeamTagged>(
  `FinanceTeam`,
  (u: unknown): u is FinanceTeam => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinanceTeam> => pipe(c.decode(u), E.map(x => ({ ...x, ...financeTeam }))),
  (x: FinanceTeam): FinanceTeamTagged => ({ ...x, _tag: `FinanceTeam`}),
));
export type FinanceTeamC = typeof financeTeamC;


export const financeCalendar = {
  _tag: `FinanceCalendar`,
  default: true,
  dependsOn: O.none,
  description: `Show events on site`,
  featureType: sectionToggle,
  humanName: `Finance Calendar`,
  id: 35,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const financeCalendarTaggedC = t.type({
  _tag: t.literal(`FinanceCalendar`)
});
export type FinanceCalendarTaggedC = typeof financeCalendarTaggedC;
export type FinanceCalendarTagged = t.TypeOf<FinanceCalendarTaggedC>;
export type FinanceCalendar = FinanceCalendarTagged & typeof financeCalendar;
export const financeCalendarC = pipe(financeCalendarTaggedC, c => new t.Type<FinanceCalendar, FinanceCalendarTagged>(
  `FinanceCalendar`,
  (u: unknown): u is FinanceCalendar => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinanceCalendar> => pipe(c.decode(u), E.map(x => ({ ...x, ...financeCalendar }))),
  (x: FinanceCalendar): FinanceCalendarTagged => ({ ...x, _tag: `FinanceCalendar`}),
));
export type FinanceCalendarC = typeof financeCalendarC;


export const menuItems = {
  _tag: `MenuItems`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom menu items`,
  featureType: sectionToggle,
  humanName: `Menu Items`,
  id: 37,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const menuItemsTaggedC = t.type({
  _tag: t.literal(`MenuItems`)
});
export type MenuItemsTaggedC = typeof menuItemsTaggedC;
export type MenuItemsTagged = t.TypeOf<MenuItemsTaggedC>;
export type MenuItems = MenuItemsTagged & typeof menuItems;
export const menuItemsC = pipe(menuItemsTaggedC, c => new t.Type<MenuItems, MenuItemsTagged>(
  `MenuItems`,
  (u: unknown): u is MenuItems => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MenuItems> => pipe(c.decode(u), E.map(x => ({ ...x, ...menuItems }))),
  (x: MenuItems): MenuItemsTagged => ({ ...x, _tag: `MenuItems`}),
));
export type MenuItemsC = typeof menuItemsC;


export const showDocumentDisclaimer = {
  _tag: `ShowDocumentDisclaimer`,
  default: false,
  dependsOn: O.none,
  description: `Enable document disclaimer`,
  featureType: disclaimer,
  humanName: `Show Document Disclaimer`,
  id: 39,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const showDocumentDisclaimerTaggedC = t.type({
  _tag: t.literal(`ShowDocumentDisclaimer`)
});
export type ShowDocumentDisclaimerTaggedC = typeof showDocumentDisclaimerTaggedC;
export type ShowDocumentDisclaimerTagged = t.TypeOf<ShowDocumentDisclaimerTaggedC>;
export type ShowDocumentDisclaimer = ShowDocumentDisclaimerTagged & typeof showDocumentDisclaimer;
export const showDocumentDisclaimerC = pipe(showDocumentDisclaimerTaggedC, c => new t.Type<ShowDocumentDisclaimer, ShowDocumentDisclaimerTagged>(
  `ShowDocumentDisclaimer`,
  (u: unknown): u is ShowDocumentDisclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ShowDocumentDisclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...showDocumentDisclaimer }))),
  (x: ShowDocumentDisclaimer): ShowDocumentDisclaimerTagged => ({ ...x, _tag: `ShowDocumentDisclaimer`}),
));
export type ShowDocumentDisclaimerC = typeof showDocumentDisclaimerC;


export const documentDisclaimerModal = {
  _tag: `DocumentDisclaimerModal`,
  default: false,
  dependsOn: O.some(showDocumentDisclaimer),
  description: `Require visitors to accept document disclaimer before viewing or downloading documents`,
  featureType: disclaimer,
  humanName: `Document Disclaimer Modal`,
  id: 40,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const documentDisclaimerModalTaggedC = t.type({
  _tag: t.literal(`DocumentDisclaimerModal`)
});
export type DocumentDisclaimerModalTaggedC = typeof documentDisclaimerModalTaggedC;
export type DocumentDisclaimerModalTagged = t.TypeOf<DocumentDisclaimerModalTaggedC>;
export type DocumentDisclaimerModal = DocumentDisclaimerModalTagged & typeof documentDisclaimerModal;
export const documentDisclaimerModalC = pipe(documentDisclaimerModalTaggedC, c => new t.Type<DocumentDisclaimerModal, DocumentDisclaimerModalTagged>(
  `DocumentDisclaimerModal`,
  (u: unknown): u is DocumentDisclaimerModal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentDisclaimerModal> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentDisclaimerModal }))),
  (x: DocumentDisclaimerModal): DocumentDisclaimerModalTagged => ({ ...x, _tag: `DocumentDisclaimerModal`}),
));
export type DocumentDisclaimerModalC = typeof documentDisclaimerModalC;


export const customPage1 = {
  _tag: `CustomPage1`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 1`,
  featureType: sectionToggle,
  humanName: `Custom Page 1`,
  id: 41,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const customPage1TaggedC = t.type({
  _tag: t.literal(`CustomPage1`)
});
export type CustomPage1TaggedC = typeof customPage1TaggedC;
export type CustomPage1Tagged = t.TypeOf<CustomPage1TaggedC>;
export type CustomPage1 = CustomPage1Tagged & typeof customPage1;
export const customPage1C = pipe(customPage1TaggedC, c => new t.Type<CustomPage1, CustomPage1Tagged>(
  `CustomPage1`,
  (u: unknown): u is CustomPage1 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage1> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage1 }))),
  (x: CustomPage1): CustomPage1Tagged => ({ ...x, _tag: `CustomPage1`}),
));
export type CustomPage1C = typeof customPage1C;


export const customPage2 = {
  _tag: `CustomPage2`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 2`,
  featureType: sectionToggle,
  humanName: `Custom Page 2`,
  id: 42,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const customPage2TaggedC = t.type({
  _tag: t.literal(`CustomPage2`)
});
export type CustomPage2TaggedC = typeof customPage2TaggedC;
export type CustomPage2Tagged = t.TypeOf<CustomPage2TaggedC>;
export type CustomPage2 = CustomPage2Tagged & typeof customPage2;
export const customPage2C = pipe(customPage2TaggedC, c => new t.Type<CustomPage2, CustomPage2Tagged>(
  `CustomPage2`,
  (u: unknown): u is CustomPage2 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage2> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage2 }))),
  (x: CustomPage2): CustomPage2Tagged => ({ ...x, _tag: `CustomPage2`}),
));
export type CustomPage2C = typeof customPage2C;


export const cusip6 = {
  _tag: `Cusip6`,
  default: false,
  dependsOn: O.none,
  description: `Show CUSIP-6 section on site`,
  featureType: sectionToggle,
  humanName: `Cusip 6`,
  id: 49,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const cusip6TaggedC = t.type({
  _tag: t.literal(`Cusip6`)
});
export type Cusip6TaggedC = typeof cusip6TaggedC;
export type Cusip6Tagged = t.TypeOf<Cusip6TaggedC>;
export type Cusip6 = Cusip6Tagged & typeof cusip6;
export const cusip6C = pipe(cusip6TaggedC, c => new t.Type<Cusip6, Cusip6Tagged>(
  `Cusip6`,
  (u: unknown): u is Cusip6 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6 }))),
  (x: Cusip6): Cusip6Tagged => ({ ...x, _tag: `Cusip6`}),
));
export type Cusip6C = typeof cusip6C;


export const contact = {
  _tag: `Contact`,
  default: true,
  dependsOn: O.none,
  description: `Show contact info on site`,
  featureType: sectionToggle,
  humanName: `Contact`,
  id: 52,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const contactTaggedC = t.type({
  _tag: t.literal(`Contact`)
});
export type ContactTaggedC = typeof contactTaggedC;
export type ContactTagged = t.TypeOf<ContactTaggedC>;
export type Contact = ContactTagged & typeof contact;
export const contactC = pipe(contactTaggedC, c => new t.Type<Contact, ContactTagged>(
  `Contact`,
  (u: unknown): u is Contact => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Contact> => pipe(c.decode(u), E.map(x => ({ ...x, ...contact }))),
  (x: Contact): ContactTagged => ({ ...x, _tag: `Contact`}),
));
export type ContactC = typeof contactC;


export const invoiceEmails = {
  _tag: `InvoiceEmails`,
  default: true,
  dependsOn: O.none,
  description: `Send email notifications of new invoices`,
  featureType: billing,
  humanName: `Invoice Emails`,
  id: 54,
  notes: O.none,
  roles: issuerAdminRoles
} as const;

export const invoiceEmailsTaggedC = t.type({
  _tag: t.literal(`InvoiceEmails`)
});
export type InvoiceEmailsTaggedC = typeof invoiceEmailsTaggedC;
export type InvoiceEmailsTagged = t.TypeOf<InvoiceEmailsTaggedC>;
export type InvoiceEmails = InvoiceEmailsTagged & typeof invoiceEmails;
export const invoiceEmailsC = pipe(invoiceEmailsTaggedC, c => new t.Type<InvoiceEmails, InvoiceEmailsTagged>(
  `InvoiceEmails`,
  (u: unknown): u is InvoiceEmails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvoiceEmails> => pipe(c.decode(u), E.map(x => ({ ...x, ...invoiceEmails }))),
  (x: InvoiceEmails): InvoiceEmailsTagged => ({ ...x, _tag: `InvoiceEmails`}),
));
export type InvoiceEmailsC = typeof invoiceEmailsC;


export const roadShows = {
  _tag: `RoadShows`,
  default: false,
  dependsOn: O.none,
  description: `Show roadshows on site`,
  featureType: sectionToggle,
  humanName: `Road Shows`,
  id: 58,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const roadShowsTaggedC = t.type({
  _tag: t.literal(`RoadShows`)
});
export type RoadShowsTaggedC = typeof roadShowsTaggedC;
export type RoadShowsTagged = t.TypeOf<RoadShowsTaggedC>;
export type RoadShows = RoadShowsTagged & typeof roadShows;
export const roadShowsC = pipe(roadShowsTaggedC, c => new t.Type<RoadShows, RoadShowsTagged>(
  `RoadShows`,
  (u: unknown): u is RoadShows => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadShows> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadShows }))),
  (x: RoadShows): RoadShowsTagged => ({ ...x, _tag: `RoadShows`}),
));
export type RoadShowsC = typeof roadShowsC;


export const displayCalendarOfferings = {
  _tag: `DisplayCalendarOfferings`,
  default: true,
  dependsOn: O.some(financeCalendar),
  description: `Include upcoming bond sales on events list`,
  featureType: visual,
  humanName: `Display Calendar Offerings`,
  id: 62,
  notes: O.some(`You must have "Show events on site" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export const displayCalendarOfferingsTaggedC = t.type({
  _tag: t.literal(`DisplayCalendarOfferings`)
});
export type DisplayCalendarOfferingsTaggedC = typeof displayCalendarOfferingsTaggedC;
export type DisplayCalendarOfferingsTagged = t.TypeOf<DisplayCalendarOfferingsTaggedC>;
export type DisplayCalendarOfferings = DisplayCalendarOfferingsTagged & typeof displayCalendarOfferings;
export const displayCalendarOfferingsC = pipe(displayCalendarOfferingsTaggedC, c => new t.Type<DisplayCalendarOfferings, DisplayCalendarOfferingsTagged>(
  `DisplayCalendarOfferings`,
  (u: unknown): u is DisplayCalendarOfferings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DisplayCalendarOfferings> => pipe(c.decode(u), E.map(x => ({ ...x, ...displayCalendarOfferings }))),
  (x: DisplayCalendarOfferings): DisplayCalendarOfferingsTagged => ({ ...x, _tag: `DisplayCalendarOfferings`}),
));
export type DisplayCalendarOfferingsC = typeof displayCalendarOfferingsC;


export const offeringParticipants = {
  _tag: `OfferingParticipants`,
  default: true,
  dependsOn: O.none,
  description: `Show offering participants on bonds landing page`,
  featureType: sectionToggle,
  humanName: `Offering Participants`,
  id: 64,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const offeringParticipantsTaggedC = t.type({
  _tag: t.literal(`OfferingParticipants`)
});
export type OfferingParticipantsTaggedC = typeof offeringParticipantsTaggedC;
export type OfferingParticipantsTagged = t.TypeOf<OfferingParticipantsTaggedC>;
export type OfferingParticipants = OfferingParticipantsTagged & typeof offeringParticipants;
export const offeringParticipantsC = pipe(offeringParticipantsTaggedC, c => new t.Type<OfferingParticipants, OfferingParticipantsTagged>(
  `OfferingParticipants`,
  (u: unknown): u is OfferingParticipants => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingParticipants> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringParticipants }))),
  (x: OfferingParticipants): OfferingParticipantsTagged => ({ ...x, _tag: `OfferingParticipants`}),
));
export type OfferingParticipantsC = typeof offeringParticipantsC;


export const customPage3 = {
  _tag: `CustomPage3`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 3`,
  featureType: sectionToggle,
  humanName: `Custom Page 3`,
  id: 65,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const customPage3TaggedC = t.type({
  _tag: t.literal(`CustomPage3`)
});
export type CustomPage3TaggedC = typeof customPage3TaggedC;
export type CustomPage3Tagged = t.TypeOf<CustomPage3TaggedC>;
export type CustomPage3 = CustomPage3Tagged & typeof customPage3;
export const customPage3C = pipe(customPage3TaggedC, c => new t.Type<CustomPage3, CustomPage3Tagged>(
  `CustomPage3`,
  (u: unknown): u is CustomPage3 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage3> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage3 }))),
  (x: CustomPage3): CustomPage3Tagged => ({ ...x, _tag: `CustomPage3`}),
));
export type CustomPage3C = typeof customPage3C;


export const customPage4 = {
  _tag: `CustomPage4`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 4`,
  featureType: sectionToggle,
  humanName: `Custom Page 4`,
  id: 66,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const customPage4TaggedC = t.type({
  _tag: t.literal(`CustomPage4`)
});
export type CustomPage4TaggedC = typeof customPage4TaggedC;
export type CustomPage4Tagged = t.TypeOf<CustomPage4TaggedC>;
export type CustomPage4 = CustomPage4Tagged & typeof customPage4;
export const customPage4C = pipe(customPage4TaggedC, c => new t.Type<CustomPage4, CustomPage4Tagged>(
  `CustomPage4`,
  (u: unknown): u is CustomPage4 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage4> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage4 }))),
  (x: CustomPage4): CustomPage4Tagged => ({ ...x, _tag: `CustomPage4`}),
));
export type CustomPage4C = typeof customPage4C;


export const customPage5 = {
  _tag: `CustomPage5`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 5`,
  featureType: sectionToggle,
  humanName: `Custom Page 5`,
  id: 67,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const customPage5TaggedC = t.type({
  _tag: t.literal(`CustomPage5`)
});
export type CustomPage5TaggedC = typeof customPage5TaggedC;
export type CustomPage5Tagged = t.TypeOf<CustomPage5TaggedC>;
export type CustomPage5 = CustomPage5Tagged & typeof customPage5;
export const customPage5C = pipe(customPage5TaggedC, c => new t.Type<CustomPage5, CustomPage5Tagged>(
  `CustomPage5`,
  (u: unknown): u is CustomPage5 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage5> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage5 }))),
  (x: CustomPage5): CustomPage5Tagged => ({ ...x, _tag: `CustomPage5`}),
));
export type CustomPage5C = typeof customPage5C;


export const roadshowLoginRequired = {
  _tag: `RoadshowLoginRequired`,
  default: true,
  dependsOn: O.some(roadShows),
  description: `Require visitors to log in before viewing roadshows`,
  featureType: investorRequirement,
  humanName: `Roadshow Login Required`,
  id: 68,
  notes: O.some(`You must have "Show roadshows on site" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export const roadshowLoginRequiredTaggedC = t.type({
  _tag: t.literal(`RoadshowLoginRequired`)
});
export type RoadshowLoginRequiredTaggedC = typeof roadshowLoginRequiredTaggedC;
export type RoadshowLoginRequiredTagged = t.TypeOf<RoadshowLoginRequiredTaggedC>;
export type RoadshowLoginRequired = RoadshowLoginRequiredTagged & typeof roadshowLoginRequired;
export const roadshowLoginRequiredC = pipe(roadshowLoginRequiredTaggedC, c => new t.Type<RoadshowLoginRequired, RoadshowLoginRequiredTagged>(
  `RoadshowLoginRequired`,
  (u: unknown): u is RoadshowLoginRequired => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadshowLoginRequired> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadshowLoginRequired }))),
  (x: RoadshowLoginRequired): RoadshowLoginRequiredTagged => ({ ...x, _tag: `RoadshowLoginRequired`}),
));
export type RoadshowLoginRequiredC = typeof roadshowLoginRequiredC;


export const disclaimerSessionLengths = {
  _tag: `DisclaimerSessionLengths`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom disclaimer session lengths`,
  featureType: disclaimer,
  humanName: `Disclaimer Session Lengths`,
  id: 69,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const disclaimerSessionLengthsTaggedC = t.type({
  _tag: t.literal(`DisclaimerSessionLengths`)
});
export type DisclaimerSessionLengthsTaggedC = typeof disclaimerSessionLengthsTaggedC;
export type DisclaimerSessionLengthsTagged = t.TypeOf<DisclaimerSessionLengthsTaggedC>;
export type DisclaimerSessionLengths = DisclaimerSessionLengthsTagged & typeof disclaimerSessionLengths;
export const disclaimerSessionLengthsC = pipe(disclaimerSessionLengthsTaggedC, c => new t.Type<DisclaimerSessionLengths, DisclaimerSessionLengthsTagged>(
  `DisclaimerSessionLengths`,
  (u: unknown): u is DisclaimerSessionLengths => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DisclaimerSessionLengths> => pipe(c.decode(u), E.map(x => ({ ...x, ...disclaimerSessionLengths }))),
  (x: DisclaimerSessionLengths): DisclaimerSessionLengthsTagged => ({ ...x, _tag: `DisclaimerSessionLengths`}),
));
export type DisclaimerSessionLengthsC = typeof disclaimerSessionLengthsC;


export const privateDocuments = {
  _tag: `PrivateDocuments`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable private document management in the issuer portal`,
  featureType: sectionToggle,
  humanName: `Private Documents`,
  id: 71,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const privateDocumentsTaggedC = t.type({
  _tag: t.literal(`PrivateDocuments`)
});
export type PrivateDocumentsTaggedC = typeof privateDocumentsTaggedC;
export type PrivateDocumentsTagged = t.TypeOf<PrivateDocumentsTaggedC>;
export type PrivateDocuments = PrivateDocumentsTagged & typeof privateDocuments;
export const privateDocumentsC = pipe(privateDocumentsTaggedC, c => new t.Type<PrivateDocuments, PrivateDocumentsTagged>(
  `PrivateDocuments`,
  (u: unknown): u is PrivateDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PrivateDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...privateDocuments }))),
  (x: PrivateDocuments): PrivateDocumentsTagged => ({ ...x, _tag: `PrivateDocuments`}),
));
export type PrivateDocumentsC = typeof privateDocumentsC;


export const emmaIntegrated = {
  _tag: `EmmaIntegrated`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable the ability to publish documents to MSRB EMMA® when uploading to BondLink`,
  featureType: internalFlag,
  humanName: `Emma Integrated`,
  id: 72,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const emmaIntegratedTaggedC = t.type({
  _tag: t.literal(`EmmaIntegrated`)
});
export type EmmaIntegratedTaggedC = typeof emmaIntegratedTaggedC;
export type EmmaIntegratedTagged = t.TypeOf<EmmaIntegratedTaggedC>;
export type EmmaIntegrated = EmmaIntegratedTagged & typeof emmaIntegrated;
export const emmaIntegratedC = pipe(emmaIntegratedTaggedC, c => new t.Type<EmmaIntegrated, EmmaIntegratedTagged>(
  `EmmaIntegrated`,
  (u: unknown): u is EmmaIntegrated => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmmaIntegrated> => pipe(c.decode(u), E.map(x => ({ ...x, ...emmaIntegrated }))),
  (x: EmmaIntegrated): EmmaIntegratedTagged => ({ ...x, _tag: `EmmaIntegrated`}),
));
export type EmmaIntegratedC = typeof emmaIntegratedC;


export const showArchivedBonds = {
  _tag: `ShowArchivedBonds`,
  default: false,
  dependsOn: O.none,
  description: `Show bond archive on site`,
  featureType: sectionToggle,
  humanName: `Show Archived Bonds`,
  id: 77,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const showArchivedBondsTaggedC = t.type({
  _tag: t.literal(`ShowArchivedBonds`)
});
export type ShowArchivedBondsTaggedC = typeof showArchivedBondsTaggedC;
export type ShowArchivedBondsTagged = t.TypeOf<ShowArchivedBondsTaggedC>;
export type ShowArchivedBonds = ShowArchivedBondsTagged & typeof showArchivedBonds;
export const showArchivedBondsC = pipe(showArchivedBondsTaggedC, c => new t.Type<ShowArchivedBonds, ShowArchivedBondsTagged>(
  `ShowArchivedBonds`,
  (u: unknown): u is ShowArchivedBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ShowArchivedBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...showArchivedBonds }))),
  (x: ShowArchivedBonds): ShowArchivedBondsTagged => ({ ...x, _tag: `ShowArchivedBonds`}),
));
export type ShowArchivedBondsC = typeof showArchivedBondsC;


export const showIRMALetter = {
  _tag: `ShowIRMALetter`,
  default: false,
  dependsOn: O.none,
  description: `Show IRMA Letter on site`,
  featureType: sectionToggle,
  humanName: `Show IRMA Letter`,
  id: 78,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const showIRMALetterTaggedC = t.type({
  _tag: t.literal(`ShowIRMALetter`)
});
export type ShowIRMALetterTaggedC = typeof showIRMALetterTaggedC;
export type ShowIRMALetterTagged = t.TypeOf<ShowIRMALetterTaggedC>;
export type ShowIRMALetter = ShowIRMALetterTagged & typeof showIRMALetter;
export const showIRMALetterC = pipe(showIRMALetterTaggedC, c => new t.Type<ShowIRMALetter, ShowIRMALetterTagged>(
  `ShowIRMALetter`,
  (u: unknown): u is ShowIRMALetter => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ShowIRMALetter> => pipe(c.decode(u), E.map(x => ({ ...x, ...showIRMALetter }))),
  (x: ShowIRMALetter): ShowIRMALetterTagged => ({ ...x, _tag: `ShowIRMALetter`}),
));
export type ShowIRMALetterC = typeof showIRMALetterC;


export const homepageFeaturedItems = {
  _tag: `HomepageFeaturedItems`,
  default: false,
  dependsOn: O.none,
  description: `Show "Featured Items" section`,
  featureType: sectionToggle,
  humanName: `Homepage Featured Items`,
  id: 79,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const homepageFeaturedItemsTaggedC = t.type({
  _tag: t.literal(`HomepageFeaturedItems`)
});
export type HomepageFeaturedItemsTaggedC = typeof homepageFeaturedItemsTaggedC;
export type HomepageFeaturedItemsTagged = t.TypeOf<HomepageFeaturedItemsTaggedC>;
export type HomepageFeaturedItems = HomepageFeaturedItemsTagged & typeof homepageFeaturedItems;
export const homepageFeaturedItemsC = pipe(homepageFeaturedItemsTaggedC, c => new t.Type<HomepageFeaturedItems, HomepageFeaturedItemsTagged>(
  `HomepageFeaturedItems`,
  (u: unknown): u is HomepageFeaturedItems => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HomepageFeaturedItems> => pipe(c.decode(u), E.map(x => ({ ...x, ...homepageFeaturedItems }))),
  (x: HomepageFeaturedItems): HomepageFeaturedItemsTagged => ({ ...x, _tag: `HomepageFeaturedItems`}),
));
export type HomepageFeaturedItemsC = typeof homepageFeaturedItemsC;


export const debtProfile = {
  _tag: `DebtProfile`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable the debt data section`,
  featureType: sectionToggle,
  humanName: `Debt Profile`,
  id: 80,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export const debtProfileTaggedC = t.type({
  _tag: t.literal(`DebtProfile`)
});
export type DebtProfileTaggedC = typeof debtProfileTaggedC;
export type DebtProfileTagged = t.TypeOf<DebtProfileTaggedC>;
export type DebtProfile = DebtProfileTagged & typeof debtProfile;
export const debtProfileC = pipe(debtProfileTaggedC, c => new t.Type<DebtProfile, DebtProfileTagged>(
  `DebtProfile`,
  (u: unknown): u is DebtProfile => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DebtProfile> => pipe(c.decode(u), E.map(x => ({ ...x, ...debtProfile }))),
  (x: DebtProfile): DebtProfileTagged => ({ ...x, _tag: `DebtProfile`}),
));
export type DebtProfileC = typeof debtProfileC;


export const esgProgram = {
  _tag: `EsgProgram`,
  default: false,
  dependsOn: O.none,
  description: `Show ESG program on site`,
  featureType: sectionToggle,
  humanName: `Esg Program`,
  id: 81,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const esgProgramTaggedC = t.type({
  _tag: t.literal(`EsgProgram`)
});
export type EsgProgramTaggedC = typeof esgProgramTaggedC;
export type EsgProgramTagged = t.TypeOf<EsgProgramTaggedC>;
export type EsgProgram = EsgProgramTagged & typeof esgProgram;
export const esgProgramC = pipe(esgProgramTaggedC, c => new t.Type<EsgProgram, EsgProgramTagged>(
  `EsgProgram`,
  (u: unknown): u is EsgProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgProgram }))),
  (x: EsgProgram): EsgProgramTagged => ({ ...x, _tag: `EsgProgram`}),
));
export type EsgProgramC = typeof esgProgramC;


export const linksPage = {
  _tag: `LinksPage`,
  default: false,
  dependsOn: O.none,
  description: `Show links page on site`,
  featureType: sectionToggle,
  humanName: `Links Page`,
  id: 82,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const linksPageTaggedC = t.type({
  _tag: t.literal(`LinksPage`)
});
export type LinksPageTaggedC = typeof linksPageTaggedC;
export type LinksPageTagged = t.TypeOf<LinksPageTaggedC>;
export type LinksPage = LinksPageTagged & typeof linksPage;
export const linksPageC = pipe(linksPageTaggedC, c => new t.Type<LinksPage, LinksPageTagged>(
  `LinksPage`,
  (u: unknown): u is LinksPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LinksPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...linksPage }))),
  (x: LinksPage): LinksPageTagged => ({ ...x, _tag: `LinksPage`}),
));
export type LinksPageC = typeof linksPageC;


export const activeRfps = {
  _tag: `ActiveRfps`,
  default: false,
  dependsOn: O.none,
  description: `Show active RFPs on site`,
  featureType: sectionToggle,
  humanName: `Active Rfps`,
  id: 83,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const activeRfpsTaggedC = t.type({
  _tag: t.literal(`ActiveRfps`)
});
export type ActiveRfpsTaggedC = typeof activeRfpsTaggedC;
export type ActiveRfpsTagged = t.TypeOf<ActiveRfpsTaggedC>;
export type ActiveRfps = ActiveRfpsTagged & typeof activeRfps;
export const activeRfpsC = pipe(activeRfpsTaggedC, c => new t.Type<ActiveRfps, ActiveRfpsTagged>(
  `ActiveRfps`,
  (u: unknown): u is ActiveRfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ActiveRfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...activeRfps }))),
  (x: ActiveRfps): ActiveRfpsTagged => ({ ...x, _tag: `ActiveRfps`}),
));
export type ActiveRfpsC = typeof activeRfpsC;


export const archivedRfps = {
  _tag: `ArchivedRfps`,
  default: false,
  dependsOn: O.none,
  description: `Show archived RFPs on site`,
  featureType: sectionToggle,
  humanName: `Archived Rfps`,
  id: 84,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const archivedRfpsTaggedC = t.type({
  _tag: t.literal(`ArchivedRfps`)
});
export type ArchivedRfpsTaggedC = typeof archivedRfpsTaggedC;
export type ArchivedRfpsTagged = t.TypeOf<ArchivedRfpsTaggedC>;
export type ArchivedRfps = ArchivedRfpsTagged & typeof archivedRfps;
export const archivedRfpsC = pipe(archivedRfpsTaggedC, c => new t.Type<ArchivedRfps, ArchivedRfpsTagged>(
  `ArchivedRfps`,
  (u: unknown): u is ArchivedRfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ArchivedRfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...archivedRfps }))),
  (x: ArchivedRfps): ArchivedRfpsTagged => ({ ...x, _tag: `ArchivedRfps`}),
));
export type ArchivedRfpsC = typeof archivedRfpsC;


export const displayCalendarRfps = {
  _tag: `DisplayCalendarRfps`,
  default: false,
  dependsOn: O.some(financeCalendar),
  description: `Include upcoming RFPs on events list`,
  featureType: visual,
  humanName: `Display Calendar Rfps`,
  id: 86,
  notes: O.some(`You must have "Show events on site" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export const displayCalendarRfpsTaggedC = t.type({
  _tag: t.literal(`DisplayCalendarRfps`)
});
export type DisplayCalendarRfpsTaggedC = typeof displayCalendarRfpsTaggedC;
export type DisplayCalendarRfpsTagged = t.TypeOf<DisplayCalendarRfpsTaggedC>;
export type DisplayCalendarRfps = DisplayCalendarRfpsTagged & typeof displayCalendarRfps;
export const displayCalendarRfpsC = pipe(displayCalendarRfpsTaggedC, c => new t.Type<DisplayCalendarRfps, DisplayCalendarRfpsTagged>(
  `DisplayCalendarRfps`,
  (u: unknown): u is DisplayCalendarRfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DisplayCalendarRfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...displayCalendarRfps }))),
  (x: DisplayCalendarRfps): DisplayCalendarRfpsTagged => ({ ...x, _tag: `DisplayCalendarRfps`}),
));
export type DisplayCalendarRfpsC = typeof displayCalendarRfpsC;


export const bondPrograms = {
  _tag: `BondPrograms`,
  default: false,
  dependsOn: O.none,
  description: `Show bond programs on site`,
  featureType: sectionToggle,
  humanName: `Bond Programs`,
  id: 87,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export const bondProgramsTaggedC = t.type({
  _tag: t.literal(`BondPrograms`)
});
export type BondProgramsTaggedC = typeof bondProgramsTaggedC;
export type BondProgramsTagged = t.TypeOf<BondProgramsTaggedC>;
export type BondPrograms = BondProgramsTagged & typeof bondPrograms;
export const bondProgramsC = pipe(bondProgramsTaggedC, c => new t.Type<BondPrograms, BondProgramsTagged>(
  `BondPrograms`,
  (u: unknown): u is BondPrograms => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondPrograms> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondPrograms }))),
  (x: BondPrograms): BondProgramsTagged => ({ ...x, _tag: `BondPrograms`}),
));
export type BondProgramsC = typeof bondProgramsC;


export const allFeatureC = [bankCalendarC, dealViewC, copyBankAsIssuerParticipantC, bondOfferingsC, bondOfferingsGeneralInfoC, bondOfferingsDisclaimerC, reportsRequireLoginC, siteDisclaimerC, siteDisclaimerModalC, offeringsDisclaimerModalC, relatedIssuersC, projectsC, emmaC, draftModeC, exitDisclaimerC, newsC, faqC, bondRatingsC, showArchivedDocsC, financeTeamC, financeCalendarC, menuItemsC, showDocumentDisclaimerC, documentDisclaimerModalC, customPage1C, customPage2C, cusip6C, contactC, invoiceEmailsC, documentsC, roadShowsC, displayCalendarOfferingsC, offeringParticipantsC, customPage3C, customPage4C, customPage5C, roadshowLoginRequiredC, disclaimerSessionLengthsC, privateDocumentsC, emmaIntegratedC, showArchivedBondsC, showIRMALetterC, homepageFeaturedItemsC, debtProfileC, esgProgramC, linksPageC, activeRfpsC, archivedRfpsC, displayCalendarRfpsC, bondProgramsC] as const;
export const allFeatureNames = [`BankCalendar`, `DealView`, `CopyBankAsIssuerParticipant`, `BondOfferings`, `BondOfferingsGeneralInfo`, `BondOfferingsDisclaimer`, `ReportsRequireLogin`, `SiteDisclaimer`, `SiteDisclaimerModal`, `OfferingsDisclaimerModal`, `RelatedIssuers`, `Projects`, `Emma`, `DraftMode`, `ExitDisclaimer`, `News`, `Faq`, `BondRatings`, `ShowArchivedDocs`, `FinanceTeam`, `FinanceCalendar`, `MenuItems`, `ShowDocumentDisclaimer`, `DocumentDisclaimerModal`, `CustomPage1`, `CustomPage2`, `Cusip6`, `Contact`, `InvoiceEmails`, `Documents`, `RoadShows`, `DisplayCalendarOfferings`, `OfferingParticipants`, `CustomPage3`, `CustomPage4`, `CustomPage5`, `RoadshowLoginRequired`, `DisclaimerSessionLengths`, `PrivateDocuments`, `EmmaIntegrated`, `ShowArchivedBonds`, `ShowIRMALetter`, `HomepageFeaturedItems`, `DebtProfile`, `EsgProgram`, `LinksPage`, `ActiveRfps`, `ArchivedRfps`, `DisplayCalendarRfps`, `BondPrograms`] as const;
export type FeatureName = (typeof allFeatureNames)[number];

export const FeatureCU = t.union([bankCalendarC, dealViewC, copyBankAsIssuerParticipantC, bondOfferingsC, bondOfferingsGeneralInfoC, bondOfferingsDisclaimerC, reportsRequireLoginC, siteDisclaimerC, siteDisclaimerModalC, offeringsDisclaimerModalC, relatedIssuersC, projectsC, emmaC, draftModeC, exitDisclaimerC, newsC, faqC, bondRatingsC, showArchivedDocsC, financeTeamC, financeCalendarC, menuItemsC, showDocumentDisclaimerC, documentDisclaimerModalC, customPage1C, customPage2C, cusip6C, contactC, invoiceEmailsC, documentsC, roadShowsC, displayCalendarOfferingsC, offeringParticipantsC, customPage3C, customPage4C, customPage5C, roadshowLoginRequiredC, disclaimerSessionLengthsC, privateDocumentsC, emmaIntegratedC, showArchivedBondsC, showIRMALetterC, homepageFeaturedItemsC, debtProfileC, esgProgramC, linksPageC, activeRfpsC, archivedRfpsC, displayCalendarRfpsC, bondProgramsC]);
export type FeatureCU = typeof FeatureCU;
export type FeatureU = t.TypeOf<FeatureCU>;

export const featureOrd: Ord.Ord<FeatureU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allFeature = [bankCalendar, dealView, copyBankAsIssuerParticipant, bondOfferings, bondOfferingsGeneralInfo, bondOfferingsDisclaimer, reportsRequireLogin, siteDisclaimer, siteDisclaimerModal, offeringsDisclaimerModal, relatedIssuers, projects, emma, draftMode, exitDisclaimer, news, faq, bondRatings, showArchivedDocs, financeTeam, financeCalendar, menuItems, showDocumentDisclaimer, documentDisclaimerModal, customPage1, customPage2, cusip6, contact, invoiceEmails, documents, roadShows, displayCalendarOfferings, offeringParticipants, customPage3, customPage4, customPage5, roadshowLoginRequired, disclaimerSessionLengths, privateDocuments, emmaIntegrated, showArchivedBonds, showIRMALetter, homepageFeaturedItems, debtProfile, esgProgram, linksPage, activeRfps, archivedRfps, displayCalendarRfps, bondPrograms] as const;
export type FeatureMap<A> = { [K in FeatureName]: A };


