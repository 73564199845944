import { WithStatusCU as imported217_WithStatusCU, WithStatusCU as imported213_WithStatusCU, withIdC as imported188_withIdC, WithStatusCU as imported216_WithStatusCU, WithStatusCU as imported215_WithStatusCU, WithStatusCU as imported214_WithStatusCU, withIdC as imported189_withIdC, WithStatusCU as imported218_WithStatusCU } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { offeringParticipantC as imported15_offeringParticipantC } from "./offeringParticipant";
import { issuerRatingC as imported12_issuerRatingC, programRatingC as imported12_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported33_RatingAgencyCU, RatingAgencyCU as imported34_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { ratingWithRelatedContentC as imported19_ratingWithRelatedContentC, ratingWithRelatedContentC as imported20_ratingWithRelatedContentC } from "./rating";
import { taggedContentC as imported119_taggedContentC, taggedContentC as imported118_taggedContentC, taggedContentC as imported120_taggedContentC } from "./taggedContent";
import { bondProgramC as imported15_bondProgramC } from "./bondProgramBase";
import { bondProgramWithRelatedContentC as imported3_bondProgramWithRelatedContentC } from "./bondProgram";
import { subscribedC as imported23_subscribedC, subscribedC as imported24_subscribedC } from "./subscribed";
import { customPageDataOC as imported3_customPageDataOC } from "./customPages";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { roadShowDataC as imported19_roadShowDataC } from "./roadshow";
import { bondOfferingWithRelatedContentC as imported9_bondOfferingWithRelatedContentC, bondOfferingWithRelatedContentC as imported10_bondOfferingWithRelatedContentC } from "./bondOffering";
import { clientTextItemC as imported15_clientTextItemC, clientTextItemC as imported16_clientTextItemC } from "./clientTextItem";

export const bondsSsrDataC = t.type({
  offerings: t.readonlyArray(imported23_subscribedC(imported213_WithStatusCU(imported118_taggedContentC(imported9_bondOfferingWithRelatedContentC)))),
  roadshows: t.readonlyArray(imported188_withIdC(imported119_taggedContentC(imported19_roadShowDataC))),
  participants: t.readonlyArray(imported214_WithStatusCU(imported15_offeringParticipantC)),
  generalInfo: optionFromNullable(imported15_clientTextItemC),
  issuerRatings: t.readonlyArray(t.tuple([imported33_RatingAgencyCU, readonlyNonEmptyArrayC(imported215_WithStatusCU(imported19_ratingWithRelatedContentC(imported12_issuerRatingC)))])),
  programRatings: t.readonlyArray(t.tuple([imported189_withIdC(imported15_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported34_RatingAgencyCU, readonlyNonEmptyArrayC(imported216_WithStatusCU(imported20_ratingWithRelatedContentC(imported12_programRatingC)))]))])),
  programs: t.readonlyArray(imported217_WithStatusCU(imported3_bondProgramWithRelatedContentC)),
  customPages: t.readonlyArray(imported3_customPageDataOC)
});
export type BondsSsrDataC = typeof bondsSsrDataC;
export type BondsSsrData = t.TypeOf<BondsSsrDataC>;


export const archivedBondsSsrDataC = t.type({
  offerings: t.readonlyArray(imported24_subscribedC(imported218_WithStatusCU(imported120_taggedContentC(imported10_bondOfferingWithRelatedContentC)))),
  generalInfo: optionFromNullable(imported16_clientTextItemC)
});
export type ArchivedBondsSsrDataC = typeof archivedBondsSsrDataC;
export type ArchivedBondsSsrData = t.TypeOf<ArchivedBondsSsrDataC>;


