// This file was generated by postcss-transpiler. Do not edit by hand.

export const form = {
  ".custom-indicator": {
    css: ".custom-indicator",
    html: "custom-indicator",
    attrs: {
      ".disabled": {
        css: ".disabled",
        html: "disabled"
      }
    },
    ".indicator": {
      css: ".indicator",
      html: "indicator"
    },
    ".icon": {
      css: ".icon",
      html: "icon"
    },
    ".icon-label": {
      css: ".icon-label",
      html: "icon-label"
    },
    "input": {
      css: "input",
      html: "input",
      ".option-label": {
        css: ".option-label",
        html: "option-label",
        ".subtitle": {
          css: ".subtitle",
          html: "subtitle"
        }
      },
      ":checked": {
        css: ":checked",
        html: "checked",
        ".indicator": {
          css: ".indicator",
          html: "indicator"
        },
        ".option-label": {
          css: ".option-label",
          html: "option-label"
        }
      },
      ".indicator": {
        css: ".indicator",
        html: "indicator"
      },
      "[type=\"checkbox\"]": {
        css: "[type=\"checkbox\"]",
        html: "[type=\"checkbox\"]",
        ".indicator": {
          css: ".indicator",
          html: "indicator"
        }
      },
      "[type=\"radio\"]": {
        css: "[type=\"radio\"]",
        html: "[type=\"radio\"]",
        ".indicator": {
          css: ".indicator",
          html: "indicator"
        }
      }
    },
    ".option-label": {
      css: ".option-label",
      html: "option-label"
    }
  },
  ".custom-select": {
    css: ".custom-select",
    html: "custom-select",
    ".icon": {
      css: ".icon",
      html: "icon"
    }
  },
  "input": {
    css: "input",
    html: "input"
  },
  "textarea": {
    css: "textarea",
    html: "textarea"
  },
  ".custom-select-options": {
    css: ".custom-select-options",
    html: "custom-select-options",
    ".custom-option": {
      css: ".custom-option",
      html: "custom-option",
      attrs: {
        ".selected": {
          css: ".selected",
          html: "selected"
        }
      },
      ":not": {
        css: ":not",
        html: "not"
      }
    }
  },
  "label": {
    css: "label",
    html: "label"
  },
  ".note-text": {
    css: ".note-text",
    html: "note-text"
  },
  "select": {
    css: "select",
    html: "select",
    attrs: {
      ".custom-select": {
        css: ".custom-select",
        html: "custom-select"
      }
    }
  },
  ".custom-select-wrapper": {
    css: ".custom-select-wrapper",
    html: "custom-select-wrapper",
    attrs: {
      ".show": {
        css: ".show",
        html: "show"
      }
    },
    ".custom-select": {
      css: ".custom-select",
      html: "custom-select",
      "svg": {
        css: "svg",
        html: "svg"
      }
    },
    ".custom-select-options": {
      css: ".custom-select-options",
      html: "custom-select-options"
    }
  },
  ".checkbox-group": {
    css: ".checkbox-group",
    html: "checkbox-group"
  },
  "form": {
    css: "form",
    html: "form",
    ".alert": {
      css: ".alert",
      html: "alert"
    },
    ".data-vertical": {
      css: ".data-vertical",
      html: "data-vertical",
      "dd": {
        css: "dd",
        html: "dd",
        attrs: {
          ".error-text": {
            css: ".error-text",
            html: "error-text"
          },
          ".form-error": {
            css: ".form-error",
            html: "form-error"
          }
        }
      }
    },
    "p": {
      css: "p",
      html: "p",
      attrs: {
        ".error-text": {
          css: ".error-text",
          html: "error-text"
        },
        ".form-error": {
          css: ".form-error",
          html: "form-error"
        }
      }
    }
  },
  ".form-input": {
    css: ".form-input",
    html: "form-input",
    attrs: {
      ".disabled": {
        css: ".disabled",
        html: "disabled"
      },
      ".input-group": {
        css: ".input-group",
        html: "input-group"
      },
      ".has-danger": {
        css: ".has-danger",
        html: "has-danger"
      }
    },
    ".icon": {
      css: ".icon",
      html: "icon"
    },
    ".indicator": {
      css: ".indicator",
      html: "indicator"
    },
    ".icon-label": {
      css: ".icon-label",
      html: "icon-label"
    },
    ".input-group-container": {
      css: ".input-group-container",
      html: "input-group-container"
    },
    "input": {
      css: "input",
      html: "input",
      ".label": {
        css: ".label",
        html: "label"
      }
    },
    ".input-group-postfix": {
      css: ".input-group-postfix",
      html: "input-group-postfix",
      attrs: {
        ".has-value": {
          css: ".has-value",
          html: "has-value"
        }
      },
      ".has-value-show": {
        css: ".has-value-show",
        html: "has-value-show"
      },
      ".has-value-hide": {
        css: ".has-value-hide",
        html: "has-value-hide"
      },
      ".btn": {
        css: ".btn",
        html: "btn",
        ".icon": {
          css: ".icon",
          html: "icon"
        }
      }
    },
    ".error-text": {
      css: ".error-text",
      html: "error-text"
    },
    ".input-postfix": {
      css: ".input-postfix",
      html: "input-postfix",
      attrs: {
        ".no-action": {
          css: ".no-action",
          html: "no-action"
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    },
    ".input-prefix": {
      css: ".input-prefix",
      html: "input-prefix",
      attrs: {
        ".no-action": {
          css: ".no-action",
          html: "no-action"
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    },
    ".tox-edit-area": {
      css: ".tox-edit-area",
      html: "tox-edit-area"
    },
    "label": {
      css: "label",
      html: "label"
    },
    "textarea": {
      css: "textarea",
      html: "textarea"
    },
    ".custom-indicator": {
      css: ".custom-indicator",
      html: "custom-indicator",
      "input": {
        css: "input",
        html: "input",
        ":checked": {
          css: ":checked",
          html: "checked",
          ".indicator": {
            css: ".indicator",
            html: "indicator"
          }
        },
        ".indicator": {
          css: ".indicator",
          html: "indicator"
        }
      }
    },
    ".btn-action": {
      css: ".btn-action",
      html: "btn-action"
    },
    ".readonly-input-button": {
      css: ".readonly-input-button",
      html: "readonly-input-button"
    },
    ".input-container-inner": {
      css: ".input-container-inner",
      html: "input-container-inner"
    },
    ".input-icon": {
      css: ".input-icon",
      html: "input-icon",
      "input": {
        css: "input",
        html: "input"
      },
      ".icon": {
        css: ".icon",
        html: "icon",
        attrs: {
          ".button": {
            css: ".button",
            html: "button"
          }
        }
      }
    },
    ".date-picker-wrapper": {
      css: ".date-picker-wrapper",
      html: "date-picker-wrapper",
      ".input-icon": {
        css: ".input-icon",
        html: "input-icon",
        ".icon": {
          css: ".icon",
          html: "icon"
        }
      }
    },
    ".label-container": {
      css: ".label-container",
      html: "label-container",
      ".char-count": {
        css: ".char-count",
        html: "char-count"
      }
    }
  },
  ".form-input-prefix": {
    css: ".form-input-prefix",
    html: "form-input-prefix",
    attrs: {
      ".text-prefix": {
        css: ".text-prefix",
        html: "text-prefix"
      },
      ".icon-prefix": {
        css: ".icon-prefix",
        html: "icon-prefix"
      }
    },
    "input": {
      css: "input",
      html: "input"
    }
  },
  ".form-input-postfix": {
    css: ".form-input-postfix",
    html: "form-input-postfix",
    attrs: {
      ".text-postfix": {
        css: ".text-postfix",
        html: "text-postfix"
      },
      ".icon-postfix": {
        css: ".icon-postfix",
        html: "icon-postfix"
      }
    },
    "input": {
      css: "input",
      html: "input"
    }
  },
  ".btn-action": {
    css: ".btn-action",
    html: "btn-action",
    ".error-text": {
      css: ".error-text",
      html: "error-text"
    }
  },
  ".custom-indicator-description": {
    css: ".custom-indicator-description",
    html: "custom-indicator-description"
  },
  ".search": {
    css: ".search",
    html: "search",
    attrs: {
      ".form-input": {
        css: ".form-input",
        html: "form-input"
      },
      ".input-group": {
        css: ".input-group",
        html: "input-group"
      }
    },
    "input": {
      css: "input",
      html: "input"
    },
    ".input-group-postfix": {
      css: ".input-group-postfix",
      html: "input-group-postfix",
      ".btn": {
        css: ".btn",
        html: "btn",
        ".icon": {
          css: ".icon",
          html: "icon"
        }
      }
    },
    ".input-group-container": {
      css: ".input-group-container",
      html: "input-group-container"
    },
    ".btn": {
      css: ".btn",
      html: "btn"
    },
    ".btn-callout": {
      css: ".btn-callout",
      html: "btn-callout",
      "svg": {
        css: "svg",
        html: "svg"
      }
    },
    ".btn-secondary": {
      css: ".btn-secondary",
      html: "btn-secondary",
      "svg": {
        css: "svg",
        html: "svg"
      }
    }
  },
  ".form-section": {
    css: ".form-section",
    html: "form-section",
    ".field-group-container": {
      css: ".field-group-container",
      html: "field-group-container"
    },
    ".field-group": {
      css: ".field-group",
      html: "field-group",
      ".related-content": {
        css: ".related-content",
        html: "related-content",
        ".callouts": {
          css: ".callouts",
          html: "callouts",
          ".callout": {
            css: ".callout",
            html: "callout"
          }
        },
        ".alert": {
          css: ".alert",
          html: "alert",
          attrs: {
            ".alert-subtle": {
              css: ".alert-subtle",
              html: "alert-subtle"
            }
          }
        }
      },
      ".attachments": {
        css: ".attachments",
        html: "attachments",
        ".callout-item": {
          css: ".callout-item",
          html: "callout-item"
        }
      },
      ".related-list": {
        css: ".related-list",
        html: "related-list",
        ".callout-item": {
          css: ".callout-item",
          html: "callout-item"
        }
      }
    },
    ".section-tip": {
      css: ".section-tip",
      html: "section-tip"
    },
    ".form-input-row": {
      css: ".form-input-row",
      html: "form-input-row"
    },
    ".title-group": {
      css: ".title-group",
      html: "title-group",
      ".subtitle": {
        css: ".subtitle",
        html: "subtitle"
      },
      ".title": {
        css: ".title",
        html: "title"
      }
    }
  },
  "odd": {
    css: "odd",
    html: "odd"
  },
  ".date-select": {
    css: ".date-select",
    html: "date-select",
    ".form-input": {
      css: ".form-input",
      html: "form-input"
    }
  },
  ".form": {
    css: ".form",
    html: "form",
    attrs: {
      ".disabled": {
        css: ".disabled",
        html: "disabled"
      }
    }
  },
  ".data-vertical": {
    css: ".data-vertical",
    html: "data-vertical",
    "form": {
      css: "form",
      html: "form",
      "dd": {
        css: "dd",
        html: "dd",
        attrs: {
          ".error-text": {
            css: ".error-text",
            html: "error-text"
          },
          ".form-error": {
            css: ".form-error",
            html: "form-error"
          }
        }
      }
    }
  }
};
