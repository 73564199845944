import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { cusip9BondName as imported9_cusip9BondName, cusip9IssueDate as imported4_cusip9IssueDate, cusip6Name as imported7_cusip6Name, cusip9MaturityDate as imported4_cusip9MaturityDate, cusip6Name as imported6_cusip6Name, cusip9Cusip as imported4_cusip9Cusip, cusip9DatedDate as imported4_cusip9DatedDate, cusip9BondName as imported8_cusip9BondName, cusip6Cusip6 as imported3_cusip6Cusip6 } from "./sortColumns";
import * as O from "fp-ts/lib/Option";
import { ASC as imported7_ASC, ASC as imported8_ASC } from "./sortDir";
import { pipe } from "fp-ts/lib/function";
import { customPage5 as imported11_customPage5, customPage1 as imported11_customPage1, customPage2 as imported11_customPage2, customPage4 as imported11_customPage4, customPage3 as imported11_customPage3 } from "./clientTextTypes";
import { customPage3Photos as imported3_customPage3Photos, customPage1Photos as imported3_customPage1Photos, customPage5Photos as imported3_customPage5Photos, customPage2Photos as imported3_customPage2Photos, customPage4Photos as imported3_customPage4Photos } from "./mediaCategories";
import * as Ord from "fp-ts/lib/Ord";
import { news as imported2_news, customPage1 as imported9_customPage1, bondRatings as imported2_bondRatings, customPage2 as imported9_customPage2, customPage4 as imported9_customPage4, customPage3 as imported10_customPage3, faq as imported2_faq, showArchivedBonds as imported2_showArchivedBonds, cusip6 as imported3_cusip6, financeTeam as imported3_financeTeam, customPage5 as imported10_customPage5, esgProgram as imported5_esgProgram, projects as imported3_projects, emma as imported4_emma, bondPrograms as imported3_bondPrograms, showIRMALetter as imported1_showIRMALetter, customPage1 as imported10_customPage1, linksPage as imported3_linksPage, relatedIssuers as imported2_relatedIssuers, customPage5 as imported9_customPage5, contact as imported2_contact, activeRfps as imported3_activeRfps, customPage4 as imported10_customPage4, customPage2 as imported10_customPage2, customPage3 as imported9_customPage3 } from "./featureFlags";

export const emmaLinks = {
  _tag: `EmmaLinks`,
  defaultSortCol: imported8_cusip9BondName,
  defaultSortDir: imported7_ASC,
  enablingFlag: O.some(imported4_emma),
  id: 5,
  name: `MSRB EMMA® Links`,
  sortColumns: [imported9_cusip9BondName, imported4_cusip9Cusip, imported4_cusip9DatedDate, imported4_cusip9IssueDate, imported4_cusip9MaturityDate]
} as const;

export const emmaLinksTaggedC = t.type({
  _tag: t.literal(`EmmaLinks`)
});
export type EmmaLinksTaggedC = typeof emmaLinksTaggedC;
export type EmmaLinksTagged = t.TypeOf<EmmaLinksTaggedC>;
export type EmmaLinks = EmmaLinksTagged & typeof emmaLinks;
export const emmaLinksC = pipe(emmaLinksTaggedC, c => new t.Type<EmmaLinks, EmmaLinksTagged>(
  `EmmaLinks`,
  (u: unknown): u is EmmaLinks => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmmaLinks> => pipe(c.decode(u), E.map(x => ({ ...x, ...emmaLinks }))),
  (x: EmmaLinks): EmmaLinksTagged => ({ ...x, _tag: `EmmaLinks`}),
));
export type EmmaLinksC = typeof emmaLinksC;


export const cusip6 = {
  _tag: `Cusip6`,
  defaultSortCol: imported6_cusip6Name,
  defaultSortDir: imported8_ASC,
  enablingFlag: O.some(imported3_cusip6),
  id: 14,
  name: `CUSIP-6`,
  sortColumns: [imported3_cusip6Cusip6, imported7_cusip6Name]
} as const;

export const cusip6TaggedC = t.type({
  _tag: t.literal(`Cusip6`)
});
export type Cusip6TaggedC = typeof cusip6TaggedC;
export type Cusip6Tagged = t.TypeOf<Cusip6TaggedC>;
export type Cusip6 = Cusip6Tagged & typeof cusip6;
export const cusip6C = pipe(cusip6TaggedC, c => new t.Type<Cusip6, Cusip6Tagged>(
  `Cusip6`,
  (u: unknown): u is Cusip6 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6 }))),
  (x: Cusip6): Cusip6Tagged => ({ ...x, _tag: `Cusip6`}),
));
export type Cusip6C = typeof cusip6C;


export const customPage1 = {
  _tag: `CustomPage1`,
  enablingFlag: O.some(imported9_customPage1),
  ff: imported10_customPage1,
  id: 9,
  index: 1,
  mc: imported3_customPage1Photos,
  name: `Custom Page 1`,
  tt: imported11_customPage1
} as const;

export const customPage1TaggedC = t.type({
  _tag: t.literal(`CustomPage1`)
});
export type CustomPage1TaggedC = typeof customPage1TaggedC;
export type CustomPage1Tagged = t.TypeOf<CustomPage1TaggedC>;
export type CustomPage1 = CustomPage1Tagged & typeof customPage1;
export const customPage1C = pipe(customPage1TaggedC, c => new t.Type<CustomPage1, CustomPage1Tagged>(
  `CustomPage1`,
  (u: unknown): u is CustomPage1 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage1> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage1 }))),
  (x: CustomPage1): CustomPage1Tagged => ({ ...x, _tag: `CustomPage1`}),
));
export type CustomPage1C = typeof customPage1C;


export const customPage2 = {
  _tag: `CustomPage2`,
  enablingFlag: O.some(imported9_customPage2),
  ff: imported10_customPage2,
  id: 10,
  index: 2,
  mc: imported3_customPage2Photos,
  name: `Custom Page 2`,
  tt: imported11_customPage2
} as const;

export const customPage2TaggedC = t.type({
  _tag: t.literal(`CustomPage2`)
});
export type CustomPage2TaggedC = typeof customPage2TaggedC;
export type CustomPage2Tagged = t.TypeOf<CustomPage2TaggedC>;
export type CustomPage2 = CustomPage2Tagged & typeof customPage2;
export const customPage2C = pipe(customPage2TaggedC, c => new t.Type<CustomPage2, CustomPage2Tagged>(
  `CustomPage2`,
  (u: unknown): u is CustomPage2 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage2> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage2 }))),
  (x: CustomPage2): CustomPage2Tagged => ({ ...x, _tag: `CustomPage2`}),
));
export type CustomPage2C = typeof customPage2C;


export const customPage3 = {
  _tag: `CustomPage3`,
  enablingFlag: O.some(imported9_customPage3),
  ff: imported10_customPage3,
  id: 19,
  index: 3,
  mc: imported3_customPage3Photos,
  name: `Custom Page 3`,
  tt: imported11_customPage3
} as const;

export const customPage3TaggedC = t.type({
  _tag: t.literal(`CustomPage3`)
});
export type CustomPage3TaggedC = typeof customPage3TaggedC;
export type CustomPage3Tagged = t.TypeOf<CustomPage3TaggedC>;
export type CustomPage3 = CustomPage3Tagged & typeof customPage3;
export const customPage3C = pipe(customPage3TaggedC, c => new t.Type<CustomPage3, CustomPage3Tagged>(
  `CustomPage3`,
  (u: unknown): u is CustomPage3 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage3> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage3 }))),
  (x: CustomPage3): CustomPage3Tagged => ({ ...x, _tag: `CustomPage3`}),
));
export type CustomPage3C = typeof customPage3C;


export const customPage4 = {
  _tag: `CustomPage4`,
  enablingFlag: O.some(imported9_customPage4),
  ff: imported10_customPage4,
  id: 20,
  index: 4,
  mc: imported3_customPage4Photos,
  name: `Custom Page 4`,
  tt: imported11_customPage4
} as const;

export const customPage4TaggedC = t.type({
  _tag: t.literal(`CustomPage4`)
});
export type CustomPage4TaggedC = typeof customPage4TaggedC;
export type CustomPage4Tagged = t.TypeOf<CustomPage4TaggedC>;
export type CustomPage4 = CustomPage4Tagged & typeof customPage4;
export const customPage4C = pipe(customPage4TaggedC, c => new t.Type<CustomPage4, CustomPage4Tagged>(
  `CustomPage4`,
  (u: unknown): u is CustomPage4 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage4> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage4 }))),
  (x: CustomPage4): CustomPage4Tagged => ({ ...x, _tag: `CustomPage4`}),
));
export type CustomPage4C = typeof customPage4C;


export const customPage5 = {
  _tag: `CustomPage5`,
  enablingFlag: O.some(imported9_customPage5),
  ff: imported10_customPage5,
  id: 21,
  index: 5,
  mc: imported3_customPage5Photos,
  name: `Custom Page 5`,
  tt: imported11_customPage5
} as const;

export const customPage5TaggedC = t.type({
  _tag: t.literal(`CustomPage5`)
});
export type CustomPage5TaggedC = typeof customPage5TaggedC;
export type CustomPage5Tagged = t.TypeOf<CustomPage5TaggedC>;
export type CustomPage5 = CustomPage5Tagged & typeof customPage5;
export const customPage5C = pipe(customPage5TaggedC, c => new t.Type<CustomPage5, CustomPage5Tagged>(
  `CustomPage5`,
  (u: unknown): u is CustomPage5 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage5> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage5 }))),
  (x: CustomPage5): CustomPage5Tagged => ({ ...x, _tag: `CustomPage5`}),
));
export type CustomPage5C = typeof customPage5C;


export const financeTeam = {
  _tag: `FinanceTeam`,
  enablingFlag: O.some(imported3_financeTeam),
  id: 1,
  name: `Team`
} as const;

export const financeTeamTaggedC = t.type({
  _tag: t.literal(`FinanceTeam`)
});
export type FinanceTeamTaggedC = typeof financeTeamTaggedC;
export type FinanceTeamTagged = t.TypeOf<FinanceTeamTaggedC>;
export type FinanceTeam = FinanceTeamTagged & typeof financeTeam;
export const financeTeamC = pipe(financeTeamTaggedC, c => new t.Type<FinanceTeam, FinanceTeamTagged>(
  `FinanceTeam`,
  (u: unknown): u is FinanceTeam => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinanceTeam> => pipe(c.decode(u), E.map(x => ({ ...x, ...financeTeam }))),
  (x: FinanceTeam): FinanceTeamTagged => ({ ...x, _tag: `FinanceTeam`}),
));
export type FinanceTeamC = typeof financeTeamC;


export const infrastructureProjects = {
  _tag: `InfrastructureProjects`,
  enablingFlag: O.some(imported3_projects),
  id: 6,
  name: `Projects`
} as const;

export const infrastructureProjectsTaggedC = t.type({
  _tag: t.literal(`InfrastructureProjects`)
});
export type InfrastructureProjectsTaggedC = typeof infrastructureProjectsTaggedC;
export type InfrastructureProjectsTagged = t.TypeOf<InfrastructureProjectsTaggedC>;
export type InfrastructureProjects = InfrastructureProjectsTagged & typeof infrastructureProjects;
export const infrastructureProjectsC = pipe(infrastructureProjectsTaggedC, c => new t.Type<InfrastructureProjects, InfrastructureProjectsTagged>(
  `InfrastructureProjects`,
  (u: unknown): u is InfrastructureProjects => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InfrastructureProjects> => pipe(c.decode(u), E.map(x => ({ ...x, ...infrastructureProjects }))),
  (x: InfrastructureProjects): InfrastructureProjectsTagged => ({ ...x, _tag: `InfrastructureProjects`}),
));
export type InfrastructureProjectsC = typeof infrastructureProjectsC;


export const news = {
  _tag: `News`,
  enablingFlag: O.some(imported2_news),
  id: 7,
  name: `News & Press Releases`
} as const;

export const newsTaggedC = t.type({
  _tag: t.literal(`News`)
});
export type NewsTaggedC = typeof newsTaggedC;
export type NewsTagged = t.TypeOf<NewsTaggedC>;
export type News = NewsTagged & typeof news;
export const newsC = pipe(newsTaggedC, c => new t.Type<News, NewsTagged>(
  `News`,
  (u: unknown): u is News => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, News> => pipe(c.decode(u), E.map(x => ({ ...x, ...news }))),
  (x: News): NewsTagged => ({ ...x, _tag: `News`}),
));
export type NewsC = typeof newsC;


export const relatedIssuers = {
  _tag: `RelatedIssuers`,
  enablingFlag: O.some(imported2_relatedIssuers),
  id: 8,
  name: `Related Issuers`
} as const;

export const relatedIssuersTaggedC = t.type({
  _tag: t.literal(`RelatedIssuers`)
});
export type RelatedIssuersTaggedC = typeof relatedIssuersTaggedC;
export type RelatedIssuersTagged = t.TypeOf<RelatedIssuersTaggedC>;
export type RelatedIssuers = RelatedIssuersTagged & typeof relatedIssuers;
export const relatedIssuersC = pipe(relatedIssuersTaggedC, c => new t.Type<RelatedIssuers, RelatedIssuersTagged>(
  `RelatedIssuers`,
  (u: unknown): u is RelatedIssuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RelatedIssuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...relatedIssuers }))),
  (x: RelatedIssuers): RelatedIssuersTagged => ({ ...x, _tag: `RelatedIssuers`}),
));
export type RelatedIssuersC = typeof relatedIssuersC;


export const contact = {
  _tag: `Contact`,
  enablingFlag: O.some(imported2_contact),
  id: 11,
  name: `Contact Us`
} as const;

export const contactTaggedC = t.type({
  _tag: t.literal(`Contact`)
});
export type ContactTaggedC = typeof contactTaggedC;
export type ContactTagged = t.TypeOf<ContactTaggedC>;
export type Contact = ContactTagged & typeof contact;
export const contactC = pipe(contactTaggedC, c => new t.Type<Contact, ContactTagged>(
  `Contact`,
  (u: unknown): u is Contact => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Contact> => pipe(c.decode(u), E.map(x => ({ ...x, ...contact }))),
  (x: Contact): ContactTagged => ({ ...x, _tag: `Contact`}),
));
export type ContactC = typeof contactC;


export const homepage = {
  _tag: `Homepage`,
  enablingFlag: O.none,
  id: 18,
  name: `Homepage`
} as const;

export const homepageTaggedC = t.type({
  _tag: t.literal(`Homepage`)
});
export type HomepageTaggedC = typeof homepageTaggedC;
export type HomepageTagged = t.TypeOf<HomepageTaggedC>;
export type Homepage = HomepageTagged & typeof homepage;
export const homepageC = pipe(homepageTaggedC, c => new t.Type<Homepage, HomepageTagged>(
  `Homepage`,
  (u: unknown): u is Homepage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Homepage> => pipe(c.decode(u), E.map(x => ({ ...x, ...homepage }))),
  (x: Homepage): HomepageTagged => ({ ...x, _tag: `Homepage`}),
));
export type HomepageC = typeof homepageC;


export const aboutPage = {
  _tag: `AboutPage`,
  enablingFlag: O.none,
  id: 22,
  name: `About`
} as const;

export const aboutPageTaggedC = t.type({
  _tag: t.literal(`AboutPage`)
});
export type AboutPageTaggedC = typeof aboutPageTaggedC;
export type AboutPageTagged = t.TypeOf<AboutPageTaggedC>;
export type AboutPage = AboutPageTagged & typeof aboutPage;
export const aboutPageC = pipe(aboutPageTaggedC, c => new t.Type<AboutPage, AboutPageTagged>(
  `AboutPage`,
  (u: unknown): u is AboutPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AboutPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...aboutPage }))),
  (x: AboutPage): AboutPageTagged => ({ ...x, _tag: `AboutPage`}),
));
export type AboutPageC = typeof aboutPageC;


export const esgProgram = {
  _tag: `EsgProgram`,
  enablingFlag: O.some(imported5_esgProgram),
  id: 29,
  name: `ESG Program`
} as const;

export const esgProgramTaggedC = t.type({
  _tag: t.literal(`EsgProgram`)
});
export type EsgProgramTaggedC = typeof esgProgramTaggedC;
export type EsgProgramTagged = t.TypeOf<EsgProgramTaggedC>;
export type EsgProgram = EsgProgramTagged & typeof esgProgram;
export const esgProgramC = pipe(esgProgramTaggedC, c => new t.Type<EsgProgram, EsgProgramTagged>(
  `EsgProgram`,
  (u: unknown): u is EsgProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgProgram }))),
  (x: EsgProgram): EsgProgramTagged => ({ ...x, _tag: `EsgProgram`}),
));
export type EsgProgramC = typeof esgProgramC;


export const linksPage = {
  _tag: `LinksPage`,
  enablingFlag: O.some(imported3_linksPage),
  id: 30,
  name: `Links`
} as const;

export const linksPageTaggedC = t.type({
  _tag: t.literal(`LinksPage`)
});
export type LinksPageTaggedC = typeof linksPageTaggedC;
export type LinksPageTagged = t.TypeOf<LinksPageTaggedC>;
export type LinksPage = LinksPageTagged & typeof linksPage;
export const linksPageC = pipe(linksPageTaggedC, c => new t.Type<LinksPage, LinksPageTagged>(
  `LinksPage`,
  (u: unknown): u is LinksPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LinksPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...linksPage }))),
  (x: LinksPage): LinksPageTagged => ({ ...x, _tag: `LinksPage`}),
));
export type LinksPageC = typeof linksPageC;


export const rfpsPage = {
  _tag: `RfpsPage`,
  enablingFlag: O.some(imported3_activeRfps),
  id: 32,
  name: `RFPs`
} as const;

export const rfpsPageTaggedC = t.type({
  _tag: t.literal(`RfpsPage`)
});
export type RfpsPageTaggedC = typeof rfpsPageTaggedC;
export type RfpsPageTagged = t.TypeOf<RfpsPageTaggedC>;
export type RfpsPage = RfpsPageTagged & typeof rfpsPage;
export const rfpsPageC = pipe(rfpsPageTaggedC, c => new t.Type<RfpsPage, RfpsPageTagged>(
  `RfpsPage`,
  (u: unknown): u is RfpsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsPage }))),
  (x: RfpsPage): RfpsPageTagged => ({ ...x, _tag: `RfpsPage`}),
));
export type RfpsPageC = typeof rfpsPageC;


export const bondProgramsPage = {
  _tag: `BondProgramsPage`,
  enablingFlag: O.some(imported3_bondPrograms),
  id: 33,
  name: `Bond Programs`
} as const;

export const bondProgramsPageTaggedC = t.type({
  _tag: t.literal(`BondProgramsPage`)
});
export type BondProgramsPageTaggedC = typeof bondProgramsPageTaggedC;
export type BondProgramsPageTagged = t.TypeOf<BondProgramsPageTaggedC>;
export type BondProgramsPage = BondProgramsPageTagged & typeof bondProgramsPage;
export const bondProgramsPageC = pipe(bondProgramsPageTaggedC, c => new t.Type<BondProgramsPage, BondProgramsPageTagged>(
  `BondProgramsPage`,
  (u: unknown): u is BondProgramsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgramsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgramsPage }))),
  (x: BondProgramsPage): BondProgramsPageTagged => ({ ...x, _tag: `BondProgramsPage`}),
));
export type BondProgramsPageC = typeof bondProgramsPageC;


export const issuerRatings = {
  _tag: `IssuerRatings`,
  enablingFlag: O.none,
  id: 34,
  name: `Issuer Ratings`
} as const;

export const issuerRatingsTaggedC = t.type({
  _tag: t.literal(`IssuerRatings`)
});
export type IssuerRatingsTaggedC = typeof issuerRatingsTaggedC;
export type IssuerRatingsTagged = t.TypeOf<IssuerRatingsTaggedC>;
export type IssuerRatings = IssuerRatingsTagged & typeof issuerRatings;
export const issuerRatingsC = pipe(issuerRatingsTaggedC, c => new t.Type<IssuerRatings, IssuerRatingsTagged>(
  `IssuerRatings`,
  (u: unknown): u is IssuerRatings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerRatings> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerRatings }))),
  (x: IssuerRatings): IssuerRatingsTagged => ({ ...x, _tag: `IssuerRatings`}),
));
export type IssuerRatingsC = typeof issuerRatingsC;


export const bondRatings = {
  _tag: `BondRatings`,
  enablingFlag: O.some(imported2_bondRatings),
  id: 4,
  name: `Bond Ratings`
} as const;

export const bondRatingsTaggedC = t.type({
  _tag: t.literal(`BondRatings`)
});
export type BondRatingsTaggedC = typeof bondRatingsTaggedC;
export type BondRatingsTagged = t.TypeOf<BondRatingsTaggedC>;
export type BondRatings = BondRatingsTagged & typeof bondRatings;
export const bondRatingsC = pipe(bondRatingsTaggedC, c => new t.Type<BondRatings, BondRatingsTagged>(
  `BondRatings`,
  (u: unknown): u is BondRatings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondRatings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondRatings }))),
  (x: BondRatings): BondRatingsTagged => ({ ...x, _tag: `BondRatings`}),
));
export type BondRatingsC = typeof bondRatingsC;


export const faq = {
  _tag: `Faq`,
  enablingFlag: O.some(imported2_faq),
  id: 12,
  name: `FAQ`
} as const;

export const faqTaggedC = t.type({
  _tag: t.literal(`Faq`)
});
export type FaqTaggedC = typeof faqTaggedC;
export type FaqTagged = t.TypeOf<FaqTaggedC>;
export type Faq = FaqTagged & typeof faq;
export const faqC = pipe(faqTaggedC, c => new t.Type<Faq, FaqTagged>(
  `Faq`,
  (u: unknown): u is Faq => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Faq> => pipe(c.decode(u), E.map(x => ({ ...x, ...faq }))),
  (x: Faq): FaqTagged => ({ ...x, _tag: `Faq`}),
));
export type FaqC = typeof faqC;


export const documentsPage = {
  _tag: `DocumentsPage`,
  enablingFlag: O.none,
  id: 23,
  name: `Documents`
} as const;

export const documentsPageTaggedC = t.type({
  _tag: t.literal(`DocumentsPage`)
});
export type DocumentsPageTaggedC = typeof documentsPageTaggedC;
export type DocumentsPageTagged = t.TypeOf<DocumentsPageTaggedC>;
export type DocumentsPage = DocumentsPageTagged & typeof documentsPage;
export const documentsPageC = pipe(documentsPageTaggedC, c => new t.Type<DocumentsPage, DocumentsPageTagged>(
  `DocumentsPage`,
  (u: unknown): u is DocumentsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentsPage }))),
  (x: DocumentsPage): DocumentsPageTagged => ({ ...x, _tag: `DocumentsPage`}),
));
export type DocumentsPageC = typeof documentsPageC;


export const resourcesPage = {
  _tag: `ResourcesPage`,
  enablingFlag: O.none,
  id: 24,
  name: `Resources`
} as const;

export const resourcesPageTaggedC = t.type({
  _tag: t.literal(`ResourcesPage`)
});
export type ResourcesPageTaggedC = typeof resourcesPageTaggedC;
export type ResourcesPageTagged = t.TypeOf<ResourcesPageTaggedC>;
export type ResourcesPage = ResourcesPageTagged & typeof resourcesPage;
export const resourcesPageC = pipe(resourcesPageTaggedC, c => new t.Type<ResourcesPage, ResourcesPageTagged>(
  `ResourcesPage`,
  (u: unknown): u is ResourcesPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ResourcesPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...resourcesPage }))),
  (x: ResourcesPage): ResourcesPageTagged => ({ ...x, _tag: `ResourcesPage`}),
));
export type ResourcesPageC = typeof resourcesPageC;


export const bondsPage = {
  _tag: `BondsPage`,
  enablingFlag: O.none,
  id: 25,
  name: `Bonds`
} as const;

export const bondsPageTaggedC = t.type({
  _tag: t.literal(`BondsPage`)
});
export type BondsPageTaggedC = typeof bondsPageTaggedC;
export type BondsPageTagged = t.TypeOf<BondsPageTaggedC>;
export type BondsPage = BondsPageTagged & typeof bondsPage;
export const bondsPageC = pipe(bondsPageTaggedC, c => new t.Type<BondsPage, BondsPageTagged>(
  `BondsPage`,
  (u: unknown): u is BondsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondsPage }))),
  (x: BondsPage): BondsPageTagged => ({ ...x, _tag: `BondsPage`}),
));
export type BondsPageC = typeof bondsPageC;


export const newsEventsPage = {
  _tag: `NewsEventsPage`,
  enablingFlag: O.none,
  id: 28,
  name: `News & Events`
} as const;

export const newsEventsPageTaggedC = t.type({
  _tag: t.literal(`NewsEventsPage`)
});
export type NewsEventsPageTaggedC = typeof newsEventsPageTaggedC;
export type NewsEventsPageTagged = t.TypeOf<NewsEventsPageTaggedC>;
export type NewsEventsPage = NewsEventsPageTagged & typeof newsEventsPage;
export const newsEventsPageC = pipe(newsEventsPageTaggedC, c => new t.Type<NewsEventsPage, NewsEventsPageTagged>(
  `NewsEventsPage`,
  (u: unknown): u is NewsEventsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewsEventsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...newsEventsPage }))),
  (x: NewsEventsPage): NewsEventsPageTagged => ({ ...x, _tag: `NewsEventsPage`}),
));
export type NewsEventsPageC = typeof newsEventsPageC;


export const bondArchive = {
  _tag: `BondArchive`,
  enablingFlag: O.some(imported2_showArchivedBonds),
  id: 31,
  name: `Bond Archive`
} as const;

export const bondArchiveTaggedC = t.type({
  _tag: t.literal(`BondArchive`)
});
export type BondArchiveTaggedC = typeof bondArchiveTaggedC;
export type BondArchiveTagged = t.TypeOf<BondArchiveTaggedC>;
export type BondArchive = BondArchiveTagged & typeof bondArchive;
export const bondArchiveC = pipe(bondArchiveTaggedC, c => new t.Type<BondArchive, BondArchiveTagged>(
  `BondArchive`,
  (u: unknown): u is BondArchive => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondArchive> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondArchive }))),
  (x: BondArchive): BondArchiveTagged => ({ ...x, _tag: `BondArchive`}),
));
export type BondArchiveC = typeof bondArchiveC;


export const irmaLetter = {
  _tag: `IrmaLetter`,
  enablingFlag: O.some(imported1_showIRMALetter),
  id: 27,
  name: `IRMA Letter`
} as const;

export const irmaLetterTaggedC = t.type({
  _tag: t.literal(`IrmaLetter`)
});
export type IrmaLetterTaggedC = typeof irmaLetterTaggedC;
export type IrmaLetterTagged = t.TypeOf<IrmaLetterTaggedC>;
export type IrmaLetter = IrmaLetterTagged & typeof irmaLetter;
export const irmaLetterC = pipe(irmaLetterTaggedC, c => new t.Type<IrmaLetter, IrmaLetterTagged>(
  `IrmaLetter`,
  (u: unknown): u is IrmaLetter => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IrmaLetter> => pipe(c.decode(u), E.map(x => ({ ...x, ...irmaLetter }))),
  (x: IrmaLetter): IrmaLetterTagged => ({ ...x, _tag: `IrmaLetter`}),
));
export type IrmaLetterC = typeof irmaLetterC;


export const allPageC = [emmaLinksC, cusip6C, customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, financeTeamC, infrastructureProjectsC, newsC, relatedIssuersC, contactC, homepageC, aboutPageC, esgProgramC, linksPageC, rfpsPageC, bondProgramsPageC, issuerRatingsC, bondRatingsC, faqC, documentsPageC, resourcesPageC, bondsPageC, newsEventsPageC, bondArchiveC, irmaLetterC] as const;
export const allPageNames = [`EmmaLinks`, `Cusip6`, `CustomPage1`, `CustomPage2`, `CustomPage3`, `CustomPage4`, `CustomPage5`, `FinanceTeam`, `InfrastructureProjects`, `News`, `RelatedIssuers`, `Contact`, `Homepage`, `AboutPage`, `EsgProgram`, `LinksPage`, `RfpsPage`, `BondProgramsPage`, `IssuerRatings`, `BondRatings`, `Faq`, `DocumentsPage`, `ResourcesPage`, `BondsPage`, `NewsEventsPage`, `BondArchive`, `IrmaLetter`] as const;
export type PageName = (typeof allPageNames)[number];

export const PageCU = t.union([emmaLinksC, cusip6C, customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, financeTeamC, infrastructureProjectsC, newsC, relatedIssuersC, contactC, homepageC, aboutPageC, esgProgramC, linksPageC, rfpsPageC, bondProgramsPageC, issuerRatingsC, bondRatingsC, faqC, documentsPageC, resourcesPageC, bondsPageC, newsEventsPageC, bondArchiveC, irmaLetterC]);
export type PageCU = typeof PageCU;
export type PageU = t.TypeOf<PageCU>;

export const pageOrd: Ord.Ord<PageU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allPage = [emmaLinks, cusip6, customPage1, customPage2, customPage3, customPage4, customPage5, financeTeam, infrastructureProjects, news, relatedIssuers, contact, homepage, aboutPage, esgProgram, linksPage, rfpsPage, bondProgramsPage, issuerRatings, bondRatings, faq, documentsPage, resourcesPage, bondsPage, newsEventsPage, bondArchive, irmaLetter] as const;
export type PageMap<A> = { [K in PageName]: A };


export const allSortableC = [emmaLinksC, cusip6C] as const;
export const allSortableNames = [`EmmaLinks`, `Cusip6`] as const;
export type SortableName = (typeof allSortableNames)[number];

export const SortableCU = t.union([emmaLinksC, cusip6C]);
export type SortableCU = typeof SortableCU;
export type SortableU = t.TypeOf<SortableCU>;

export const sortableOrd: Ord.Ord<SortableU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSortable = [emmaLinks, cusip6] as const;
export type SortableMap<A> = { [K in SortableName]: A };


export const allTitleEditableC = [customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, financeTeamC, emmaLinksC, infrastructureProjectsC, newsC, relatedIssuersC, contactC, cusip6C, homepageC, aboutPageC, esgProgramC, linksPageC, rfpsPageC, bondProgramsPageC, issuerRatingsC] as const;
export const allTitleEditableNames = [`CustomPage1`, `CustomPage2`, `CustomPage3`, `CustomPage4`, `CustomPage5`, `FinanceTeam`, `EmmaLinks`, `InfrastructureProjects`, `News`, `RelatedIssuers`, `Contact`, `Cusip6`, `Homepage`, `AboutPage`, `EsgProgram`, `LinksPage`, `RfpsPage`, `BondProgramsPage`, `IssuerRatings`] as const;
export type TitleEditableName = (typeof allTitleEditableNames)[number];

export const TitleEditableCU = t.union([customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, financeTeamC, emmaLinksC, infrastructureProjectsC, newsC, relatedIssuersC, contactC, cusip6C, homepageC, aboutPageC, esgProgramC, linksPageC, rfpsPageC, bondProgramsPageC, issuerRatingsC]);
export type TitleEditableCU = typeof TitleEditableCU;
export type TitleEditableU = t.TypeOf<TitleEditableCU>;

export const titleEditableOrd: Ord.Ord<TitleEditableU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allTitleEditable = [customPage1, customPage2, customPage3, customPage4, customPage5, financeTeam, emmaLinks, infrastructureProjects, news, relatedIssuers, contact, cusip6, homepage, aboutPage, esgProgram, linksPage, rfpsPage, bondProgramsPage, issuerRatings] as const;
export type TitleEditableMap<A> = { [K in TitleEditableName]: A };


export const allIntroSentenceEditableC = [financeTeamC, bondRatingsC, emmaLinksC, infrastructureProjectsC, faqC, aboutPageC, documentsPageC, resourcesPageC, bondsPageC, newsEventsPageC, esgProgramC, linksPageC, bondArchiveC, rfpsPageC, bondProgramsPageC] as const;
export const allIntroSentenceEditableNames = [`FinanceTeam`, `BondRatings`, `EmmaLinks`, `InfrastructureProjects`, `Faq`, `AboutPage`, `DocumentsPage`, `ResourcesPage`, `BondsPage`, `NewsEventsPage`, `EsgProgram`, `LinksPage`, `BondArchive`, `RfpsPage`, `BondProgramsPage`] as const;
export type IntroSentenceEditableName = (typeof allIntroSentenceEditableNames)[number];

export const IntroSentenceEditableCU = t.union([financeTeamC, bondRatingsC, emmaLinksC, infrastructureProjectsC, faqC, aboutPageC, documentsPageC, resourcesPageC, bondsPageC, newsEventsPageC, esgProgramC, linksPageC, bondArchiveC, rfpsPageC, bondProgramsPageC]);
export type IntroSentenceEditableCU = typeof IntroSentenceEditableCU;
export type IntroSentenceEditableU = t.TypeOf<IntroSentenceEditableCU>;

export const introSentenceEditableOrd: Ord.Ord<IntroSentenceEditableU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIntroSentenceEditable = [financeTeam, bondRatings, emmaLinks, infrastructureProjects, faq, aboutPage, documentsPage, resourcesPage, bondsPage, newsEventsPage, esgProgram, linksPage, bondArchive, rfpsPage, bondProgramsPage] as const;
export type IntroSentenceEditableMap<A> = { [K in IntroSentenceEditableName]: A };


export const allCustomPageC = [customPage1C, customPage2C, customPage3C, customPage4C, customPage5C] as const;
export const allCustomPageNames = [`CustomPage1`, `CustomPage2`, `CustomPage3`, `CustomPage4`, `CustomPage5`] as const;
export type CustomPageName = (typeof allCustomPageNames)[number];

export const CustomPageCU = t.union([customPage1C, customPage2C, customPage3C, customPage4C, customPage5C]);
export type CustomPageCU = typeof CustomPageCU;
export type CustomPageU = t.TypeOf<CustomPageCU>;

export const customPageOrd: Ord.Ord<CustomPageU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCustomPage = [customPage1, customPage2, customPage3, customPage4, customPage5] as const;
export type CustomPageMap<A> = { [K in CustomPageName]: A };


export const allPhotoEditableC = [homepageC, aboutPageC, documentsPageC, resourcesPageC, bondsPageC] as const;
export const allPhotoEditableNames = [`Homepage`, `AboutPage`, `DocumentsPage`, `ResourcesPage`, `BondsPage`] as const;
export type PhotoEditableName = (typeof allPhotoEditableNames)[number];

export const PhotoEditableCU = t.union([homepageC, aboutPageC, documentsPageC, resourcesPageC, bondsPageC]);
export type PhotoEditableCU = typeof PhotoEditableCU;
export type PhotoEditableU = t.TypeOf<PhotoEditableCU>;

export const photoEditableOrd: Ord.Ord<PhotoEditableU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allPhotoEditable = [homepage, aboutPage, documentsPage, resourcesPage, bondsPage] as const;
export type PhotoEditableMap<A> = { [K in PhotoEditableName]: A };


export const allBucketC = [aboutPageC, documentsPageC, resourcesPageC, bondsPageC] as const;
export const allBucketNames = [`AboutPage`, `DocumentsPage`, `ResourcesPage`, `BondsPage`] as const;
export type BucketName = (typeof allBucketNames)[number];

export const BucketCU = t.union([aboutPageC, documentsPageC, resourcesPageC, bondsPageC]);
export type BucketCU = typeof BucketCU;
export type BucketU = t.TypeOf<BucketCU>;

export const bucketOrd: Ord.Ord<BucketU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBucket = [aboutPage, documentsPage, resourcesPage, bondsPage] as const;
export type BucketMap<A> = { [K in BucketName]: A };


