import type { PropsWithChildren } from "react";

import { O, pipe, RA, RNEA } from "@scripts/fp-ts";
import type { OfferingParticipant } from "@scripts/generated/models/offeringParticipant";
import type { ParticipantType } from "@scripts/generated/models/participantType";
import type { WithId, WithStatusU } from "@scripts/generated/models/threadThrough";
import { klass } from "@scripts/react/util/classnames";

import { Grid, GridCol } from "../layout/Grid";
import { AccentDividerSection } from "../layout/Section";
import { ParticipantCard } from "../ParticipantCard";

export type Participant = [WithId<ParticipantType>, RNEA.ReadonlyNonEmptyArray<WithStatusU<OfferingParticipant>>];

type ParticipantsSectionContainerProps = { title: string, sidebarLinkHandle?: string };
export const participantsSectionTitle = "Participants";
export const offeringParticipantsSectionTitle = `Offering ${participantsSectionTitle}`;

export const ParticipantsSectionContainer = (props: PropsWithChildren<ParticipantsSectionContainerProps>) =>
  <AccentDividerSection sectionId={props.sidebarLinkHandle} title={O.some(props.title)}>
    {props.children}
  </AccentDividerSection>;

export const ParticipantCardGrid = (props: { participants: RNEA.ReadonlyNonEmptyArray<WithStatusU<OfferingParticipant>> }) =>
  <Grid klasses={["card-grid", "mb-2"]} attrs={O.none}>
    {pipe(
      props.participants,
      RNEA.map(_ =>
        <GridCol cols={[".c-24", ".c-md-12"]} klasses={""} key={_.data.id}>
          <ParticipantCard participant={_.data.record} />
        </GridCol>
      )
    )}
  </Grid>;

export type ParticipantArray = RNEA.ReadonlyNonEmptyArray<Participant>;
export const Participants = (props: ParticipantsSectionContainerProps & {
  participants: ParticipantArray;
}) =>
  <ParticipantsSectionContainer sidebarLinkHandle={props.sidebarLinkHandle} title={props.title}>
    <div {...klass("d-flex", "flex-col", "gap-2")}>
      {pipe(
        props.participants,
        RA.map(([tpe, participants]) =>
          <div key={`participants list ${tpe.id}`}>
            <h3 className="font-sans-normal-500 mb-1">{tpe.record.name}</h3>
            <ParticipantCardGrid participants={participants} />
          </div>
        ),
      )}
    </div>
  </ParticipantsSectionContainer>;
