import { withModInfoC as imported146_withModInfoC, withIdC as imported195_withIdC, withIdC as imported196_withIdC, withModInfoC as imported147_withModInfoC } from "./threadThrough";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { rfpC as imported43_rfpC } from "./rfpBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { bondOfferingDetailsC as imported32_bondOfferingDetailsC } from "./bondOffering";
import { SubscriptionChangeCU as imported4_SubscriptionChangeCU } from "../domaintables/notificationSubscriptions";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import { markdownC } from "../../codecs/markdown";
import { contactFormSubmissionC as imported15_contactFormSubmissionC } from "./investorActivityTypes";

export const investorC = t.type({
  name: t.string,
  url: t.string
});
export type InvestorC = typeof investorC;
export type Investor = t.TypeOf<InvestorC>;


export const detailsC = t.type({
  activityOrItemId: t.number,
  investor: investorC,
  dateTime: LocalDateTimeFromIsoStringC
});
export type DetailsC = typeof detailsC;
export type Details = t.TypeOf<DetailsC>;


export const documentC = t.type({
  id: t.number,
  title: t.string,
  url: t.string
});
export type DocumentC = typeof documentC;
export type Document = t.TypeOf<DocumentC>;


export const emailC = t.type({
  subject: t.string
});
export type EmailC = typeof emailC;
export type Email = t.TypeOf<EmailC>;


export const pageC = t.type({
  title: t.string,
  url: t.string
});
export type PageC = typeof pageC;
export type Page = t.TypeOf<PageC>;


export const roadShowC = t.type({
  title: t.string,
  url: t.string
});
export type RoadShowC = typeof roadShowC;
export type RoadShow = t.TypeOf<RoadShowC>;


export const documentDownloadedC = t.type({
  _tag: t.literal(`DocumentDownloaded`),
  details: detailsC,
  document: documentC
});
export type DocumentDownloadedC = typeof documentDownloadedC;
export type DocumentDownloaded = t.TypeOf<DocumentDownloadedC>;


export const documentViewedC = t.type({
  _tag: t.literal(`DocumentViewed`),
  details: detailsC,
  document: documentC
});
export type DocumentViewedC = typeof documentViewedC;
export type DocumentViewed = t.TypeOf<DocumentViewedC>;


export const offeringViewedC = t.type({
  _tag: t.literal(`OfferingViewed`),
  details: detailsC,
  offering: imported195_withIdC(imported146_withModInfoC(imported32_bondOfferingDetailsC))
});
export type OfferingViewedC = typeof offeringViewedC;
export type OfferingViewed = t.TypeOf<OfferingViewedC>;


export const rfpViewedC = t.type({
  _tag: t.literal(`RfpViewed`),
  details: detailsC,
  rfp: imported196_withIdC(imported147_withModInfoC(imported43_rfpC))
});
export type RfpViewedC = typeof rfpViewedC;
export type RfpViewed = t.TypeOf<RfpViewedC>;


export const contactFormSubmittedC = t.type({
  _tag: t.literal(`ContactFormSubmitted`),
  details: detailsC,
  submissionInfo: imported15_contactFormSubmissionC
});
export type ContactFormSubmittedC = typeof contactFormSubmittedC;
export type ContactFormSubmitted = t.TypeOf<ContactFormSubmittedC>;


export const notificationSubscriptionsChangedC = t.type({
  _tag: t.literal(`NotificationSubscriptionsChanged`),
  details: detailsC,
  subscriptions: readonlyNonEmptyArrayC(imported4_SubscriptionChangeCU)
});
export type NotificationSubscriptionsChangedC = typeof notificationSubscriptionsChangedC;
export type NotificationSubscriptionsChanged = t.TypeOf<NotificationSubscriptionsChangedC>;


export const siteViewedC = t.type({
  _tag: t.literal(`SiteViewed`),
  details: detailsC,
  pages: readonlyNonEmptyArrayC(pageC)
});
export type SiteViewedC = typeof siteViewedC;
export type SiteViewed = t.TypeOf<SiteViewedC>;


export const roadShowViewedC = t.type({
  _tag: t.literal(`RoadShowViewed`),
  details: detailsC,
  roadShow: roadShowC
});
export type RoadShowViewedC = typeof roadShowViewedC;
export type RoadShowViewed = t.TypeOf<RoadShowViewedC>;


export const newsC = t.type({
  title: t.string,
  body: t.string,
  url: t.string
});
export type NewsC = typeof newsC;
export type News = t.TypeOf<NewsC>;


export const newsViewedC = t.type({
  _tag: t.literal(`NewsViewed`),
  details: detailsC,
  news: newsC
});
export type NewsViewedC = typeof newsViewedC;
export type NewsViewed = t.TypeOf<NewsViewedC>;


export const projectIAC = t.type({
  title: t.string,
  url: t.string
});
export type ProjectIAC = typeof projectIAC;
export type ProjectIA = t.TypeOf<ProjectIAC>;


export const projectViewedC = t.type({
  _tag: t.literal(`ProjectViewed`),
  details: detailsC,
  project: projectIAC
});
export type ProjectViewedC = typeof projectViewedC;
export type ProjectViewed = t.TypeOf<ProjectViewedC>;


export const emailOpenedC = t.type({
  _tag: t.literal(`EmailOpened`),
  details: detailsC,
  email: emailC,
  receivedDate: LocalDateTimeFromIsoStringC
});
export type EmailOpenedC = typeof emailOpenedC;
export type EmailOpened = t.TypeOf<EmailOpenedC>;


export const emailLinkClickedC = t.type({
  _tag: t.literal(`EmailLinkClicked`),
  details: detailsC,
  email: emailC,
  receivedDate: LocalDateTimeFromIsoStringC
});
export type EmailLinkClickedC = typeof emailLinkClickedC;
export type EmailLinkClicked = t.TypeOf<EmailLinkClickedC>;


export const emailReceivedC = t.type({
  _tag: t.literal(`EmailReceived`),
  details: detailsC,
  email: emailC
});
export type EmailReceivedC = typeof emailReceivedC;
export type EmailReceived = t.TypeOf<EmailReceivedC>;


export const issuerLinkIAC = t.type({
  name: t.string,
  url: t.string,
  description: optionFromNullable(markdownC)
});
export type IssuerLinkIAC = typeof issuerLinkIAC;
export type IssuerLinkIA = t.TypeOf<IssuerLinkIAC>;


export const issuerLinkClickedC = t.type({
  _tag: t.literal(`IssuerLinkClicked`),
  details: detailsC,
  issuerLink: issuerLinkIAC
});
export type IssuerLinkClickedC = typeof issuerLinkClickedC;
export type IssuerLinkClicked = t.TypeOf<IssuerLinkClickedC>;


export const allInvestorActivityC = [documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, notificationSubscriptionsChangedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, issuerLinkClickedC] as const;
export const allInvestorActivityNames = [`DocumentDownloaded`, `DocumentViewed`, `OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `NotificationSubscriptionsChanged`, `SiteViewed`, `RoadShowViewed`, `NewsViewed`, `ProjectViewed`, `EmailOpened`, `EmailLinkClicked`, `EmailReceived`, `IssuerLinkClicked`] as const;
export type InvestorActivityName = (typeof allInvestorActivityNames)[number];

export const InvestorActivityCU = t.union([documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, notificationSubscriptionsChangedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, issuerLinkClickedC]);
export type InvestorActivityCU = typeof InvestorActivityCU;
export type InvestorActivityU = t.TypeOf<InvestorActivityCU>;

export type InvestorActivityMap<A> = { [K in InvestorActivityName]: A };


export const allDashboardFeedActivityC = [documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, notificationSubscriptionsChangedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, issuerLinkClickedC] as const;
export const allDashboardFeedActivityNames = [`DocumentDownloaded`, `DocumentViewed`, `OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `NotificationSubscriptionsChanged`, `SiteViewed`, `RoadShowViewed`, `NewsViewed`, `ProjectViewed`, `EmailOpened`, `EmailLinkClicked`, `EmailReceived`, `IssuerLinkClicked`] as const;
export type DashboardFeedActivityName = (typeof allDashboardFeedActivityNames)[number];

export const DashboardFeedActivityCU = t.union([documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, notificationSubscriptionsChangedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, issuerLinkClickedC]);
export type DashboardFeedActivityCU = typeof DashboardFeedActivityCU;
export type DashboardFeedActivityU = t.TypeOf<DashboardFeedActivityCU>;

export type DashboardFeedActivityMap<A> = { [K in DashboardFeedActivityName]: A };


export const allInvestorActivityTabC = [offeringViewedC, rfpViewedC, contactFormSubmittedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, issuerLinkClickedC, emailOpenedC, emailLinkClickedC, emailReceivedC, documentDownloadedC, documentViewedC, notificationSubscriptionsChangedC] as const;
export const allInvestorActivityTabNames = [`OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `SiteViewed`, `RoadShowViewed`, `NewsViewed`, `ProjectViewed`, `IssuerLinkClicked`, `EmailOpened`, `EmailLinkClicked`, `EmailReceived`, `DocumentDownloaded`, `DocumentViewed`, `NotificationSubscriptionsChanged`] as const;
export type InvestorActivityTabName = (typeof allInvestorActivityTabNames)[number];

export const InvestorActivityTabCU = t.union([offeringViewedC, rfpViewedC, contactFormSubmittedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, issuerLinkClickedC, emailOpenedC, emailLinkClickedC, emailReceivedC, documentDownloadedC, documentViewedC, notificationSubscriptionsChangedC]);
export type InvestorActivityTabCU = typeof InvestorActivityTabCU;
export type InvestorActivityTabU = t.TypeOf<InvestorActivityTabCU>;

export type InvestorActivityTabMap<A> = { [K in InvestorActivityTabName]: A };


export const allSiteActivityC = [offeringViewedC, rfpViewedC, contactFormSubmittedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, issuerLinkClickedC] as const;
export const allSiteActivityNames = [`OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `SiteViewed`, `RoadShowViewed`, `NewsViewed`, `ProjectViewed`, `IssuerLinkClicked`] as const;
export type SiteActivityName = (typeof allSiteActivityNames)[number];

export const SiteActivityCU = t.union([offeringViewedC, rfpViewedC, contactFormSubmittedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, issuerLinkClickedC]);
export type SiteActivityCU = typeof SiteActivityCU;
export type SiteActivityU = t.TypeOf<SiteActivityCU>;

export type SiteActivityMap<A> = { [K in SiteActivityName]: A };


export const allEmailActivityC = [emailOpenedC, emailLinkClickedC, emailReceivedC] as const;
export const allEmailActivityNames = [`EmailOpened`, `EmailLinkClicked`, `EmailReceived`] as const;
export type EmailActivityName = (typeof allEmailActivityNames)[number];

export const EmailActivityCU = t.union([emailOpenedC, emailLinkClickedC, emailReceivedC]);
export type EmailActivityCU = typeof EmailActivityCU;
export type EmailActivityU = t.TypeOf<EmailActivityCU>;

export type EmailActivityMap<A> = { [K in EmailActivityName]: A };


export const allDocumentActivityC = [documentDownloadedC, documentViewedC] as const;
export const allDocumentActivityNames = [`DocumentDownloaded`, `DocumentViewed`] as const;
export type DocumentActivityName = (typeof allDocumentActivityNames)[number];

export const DocumentActivityCU = t.union([documentDownloadedC, documentViewedC]);
export type DocumentActivityCU = typeof DocumentActivityCU;
export type DocumentActivityU = t.TypeOf<DocumentActivityCU>;

export type DocumentActivityMap<A> = { [K in DocumentActivityName]: A };


export const allNotificationActivityC = [notificationSubscriptionsChangedC] as const;
export const allNotificationActivityNames = [`NotificationSubscriptionsChanged`] as const;
export type NotificationActivityName = (typeof allNotificationActivityNames)[number];

export const NotificationActivityCU = notificationSubscriptionsChangedC;
export type NotificationActivityCU = typeof NotificationActivityCU;
export type NotificationActivityU = t.TypeOf<NotificationActivityCU>;

export type NotificationActivityMap<A> = { [K in NotificationActivityName]: A };


export class tabCountCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  t: optionFromNullable(A1),
  count: t.number
})}
export const tabCountC = <A1 extends t.Mixed>(A1: A1) => new tabCountCC<A1>().codec(A1);
export type TabCountC<A1 extends t.Mixed> = ReturnType<tabCountCC<A1>["codec"]>;
export type TabCount<A1> = t.TypeOf<TabCountC<t.Type<A1>>>;

export const tabCountsC = t.type({
  all: tabCountC(InvestorActivityCU),
  site: tabCountC(SiteActivityCU),
  email: tabCountC(EmailActivityCU),
  document: tabCountC(DocumentActivityCU),
  notification: tabCountC(NotificationActivityCU)
});
export type TabCountsC = typeof tabCountsC;
export type TabCounts = t.TypeOf<TabCountsC>;


