import { withIdC as imported231_withIdC, withIdC as imported232_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported69_mediaC } from "./media";
import { participantTypeC as imported11_participantTypeC } from "./participantType";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const offeringParticipantC = t.type({
  name: t.string,
  url: optionFromNullable(t.string),
  contactName: optionFromNullable(t.string),
  contactPhone: optionFromNullable(t.string),
  contactEmail: optionFromNullable(t.string),
  sortOrder: t.number,
  photo: optionFromNullable(imported231_withIdC(imported69_mediaC))
});
export type OfferingParticipantC = typeof offeringParticipantC;
export type OfferingParticipant = t.TypeOf<OfferingParticipantC>;


export const offeringParticipantWithTypeC = t.type({
  participant: offeringParticipantC,
  type: imported232_withIdC(imported11_participantTypeC)
});
export type OfferingParticipantWithTypeC = typeof offeringParticipantWithTypeC;
export type OfferingParticipantWithType = t.TypeOf<OfferingParticipantWithTypeC>;


export const offeringParticipantPostBodyC = t.type({
  id: optionFromNullable(t.number),
  contactEmail: optionFromNullable(t.string),
  contactName: optionFromNullable(t.string),
  contactPhone: optionFromNullable(t.string),
  fileSize: optionFromNullable(t.number),
  logoUri: optionFromNullable(t.string),
  mediaId: optionFromNullable(t.number),
  name: t.string,
  url: optionFromNullable(t.string),
  sortOrder: t.number
});
export type OfferingParticipantPostBodyC = typeof offeringParticipantPostBodyC;
export type OfferingParticipantPostBody = t.TypeOf<OfferingParticipantPostBodyC>;


