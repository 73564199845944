import { Route, query, lit, str, end, type, Match } from "fp-ts-routing/lib";
import * as t from "io-ts";
import { either } from "io-ts-types/lib/either";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { htmlC } from "../../codecs/html";
import { literalNumberFromString } from "../../codecs/literalNumberFromString";
import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { NullableToOptionalFields, optionalFieldsToNullable } from "../../fp-ts/Record";
import { issuerIdC } from "../../routes/routing/params/issuerId";
import { urlInterfaceIO } from "../../routes/urlInterface";
import { apiRedirectC as imported8_apiRedirectC } from "../models/apiRedirect";
import { directOfferingDataC as imported0_directOfferingDataC, bankOfferingDataC as imported2_bankOfferingDataC } from "../models/bondOffering";
import { bondProgramWithRelatedContentC as imported7_bondProgramWithRelatedContentC, bondProgramWithRelatedContentC as imported8_bondProgramWithRelatedContentC } from "../models/bondProgram";
import { bondsSsrDataC as imported0_bondsSsrDataC, archivedBondsSsrDataC as imported0_archivedBondsSsrDataC } from "../models/bondsSsrData";
import { browserRedirectC as imported26_browserRedirectC, browserRedirectC as imported27_browserRedirectC } from "../models/browserRedirect";
import { contactPostC as imported2_contactPostC } from "../models/contact";
import { acceptDisclaimerPostC as imported0_acceptDisclaimerPostC } from "../models/disclaimer";
import { documentWithCategoryC as imported22_documentWithCategoryC } from "../models/document";
import { emptyObjectC } from "../models/emptyObject";
import { issuerFaqsWithSectionsC as imported1_issuerFaqsWithSectionsC } from "../models/faq";
import { issuerLinkC as imported7_issuerLinkC, issuerLinkSectionC as imported5_issuerLinkSectionC } from "../models/issuerLinks";
import { newsAndEventsDataC as imported0_newsAndEventsDataC } from "../models/issuerNews";
import { issuerHomepageDataC as imported0_issuerHomepageDataC, issuerDocumentCategoriesPageDataC as imported0_issuerDocumentCategoriesPageDataC, issuerDownloadsPageDataC as imported0_issuerDownloadsPageDataC, issuerDownloadsPageDataC as imported1_issuerDownloadsPageDataC, roadShowSsrDataC as imported0_roadShowSsrDataC, issuerAboutPageDataC as imported0_issuerAboutPageDataC, issuerEsgPageDataC as imported0_issuerEsgPageDataC } from "../models/issuerPageData";
import { mediaC as imported103_mediaC } from "../models/media";
import { officerC as imported19_officerC } from "../models/officer";
import { confirmSitePasswordC as imported0_confirmSitePasswordC } from "../models/privateSite";
import { projectWithPhotosC as imported4_projectWithPhotosC } from "../models/project";
import { issuerAndProgramRatingsByAgencyC as imported1_issuerAndProgramRatingsByAgencyC } from "../models/rating";
import { rfpsSitesListC as imported0_rfpsSitesListC, directRfpSitesDataC as imported0_directRfpSitesDataC, bankRfpSitesDataC as imported2_bankRfpSitesDataC } from "../models/rfp";
import { roadShowProgressPostC as imported0_roadShowProgressPostC } from "../models/roadshow";
import { taggedContentC as imported231_taggedContentC, taggedContentC as imported232_taggedContentC } from "../models/taggedContent";
import { toManyC as imported0_toManyC, WithStatusCU as imported446_WithStatusCU, WithStatusCU as imported448_WithStatusCU, WithStatusCU as imported444_WithStatusCU, withIdC as imported309_withIdC, WithStatusCU as imported449_WithStatusCU, withIdC as imported310_withIdC, WithStatusCU as imported447_WithStatusCU, WithStatusCU as imported445_WithStatusCU } from "../models/threadThrough";

const issuersitesBondOfferingsControllerIndexPath = str("issuerSlug").then(lit("bonds")).then(type("issuerId", issuerIdC));
const issuersitesBondOfferingsControllerIndexPathParts = ["issuerSlug", "bonds", "issuerId"] as const;
const issuersitesBondOfferingsControllerIndexQuery = t.strict({});
export type IssuersitesBondOfferingsControllerIndexParams = Describe<typeof issuersitesBondOfferingsControllerIndexPath._A & typeof issuersitesBondOfferingsControllerIndexQuery._A>;

export function issuersitesBondOfferingsControllerIndexRoute(): { match: Match<IssuersitesBondOfferingsControllerIndexParams>; pathParts: typeof issuersitesBondOfferingsControllerIndexPathParts };
export function issuersitesBondOfferingsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerIndexParams>): { match: Match<IssuersitesBondOfferingsControllerIndexParams & t.TypeOf<A>>; pathParts: typeof issuersitesBondOfferingsControllerIndexPathParts };
export function issuersitesBondOfferingsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerIndexParams>) {
  return {
    match: (q ? issuersitesBondOfferingsControllerIndexPath.then(query(t.intersection([issuersitesBondOfferingsControllerIndexQuery, q]))) : issuersitesBondOfferingsControllerIndexPath.then(query(issuersitesBondOfferingsControllerIndexQuery))).then(end),
    pathParts: issuersitesBondOfferingsControllerIndexPathParts,
  };
}

export const issuersitesBondOfferingsControllerIndexCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_bondsSsrDataC,
};

export const issuersitesBondOfferingsControllerIndex = (p: IssuersitesBondOfferingsControllerIndexParams) =>
  urlInterfaceIO("GET", issuersitesBondOfferingsControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesBondOfferingsControllerIndexCodecs);

const issuersitesBondOfferingsControllerOfferingPath = str("issuerSlug").then(lit("bonds")).then(lit("bond-offering")).then(type("issuerId", issuerIdC));
const issuersitesBondOfferingsControllerOfferingPathParts = [
  "issuerSlug", "bonds", "bond-offering", "issuerId"
] as const;
const issuersitesBondOfferingsControllerOfferingQuery = t.exact(t.type({
  "offeringId": NumberFromString
}));
export type IssuersitesBondOfferingsControllerOfferingParams = Describe<typeof issuersitesBondOfferingsControllerOfferingPath._A & typeof issuersitesBondOfferingsControllerOfferingQuery._A>;

export function issuersitesBondOfferingsControllerOfferingRoute(): { match: Match<IssuersitesBondOfferingsControllerOfferingParams>; pathParts: typeof issuersitesBondOfferingsControllerOfferingPathParts };
export function issuersitesBondOfferingsControllerOfferingRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerOfferingParams>): { match: Match<IssuersitesBondOfferingsControllerOfferingParams & t.TypeOf<A>>; pathParts: typeof issuersitesBondOfferingsControllerOfferingPathParts };
export function issuersitesBondOfferingsControllerOfferingRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerOfferingParams>) {
  return {
    match: (q ? issuersitesBondOfferingsControllerOfferingPath.then(query(t.intersection([issuersitesBondOfferingsControllerOfferingQuery, q]))) : issuersitesBondOfferingsControllerOfferingPath.then(query(issuersitesBondOfferingsControllerOfferingQuery))).then(end),
    pathParts: issuersitesBondOfferingsControllerOfferingPathParts,
  };
}

export const issuersitesBondOfferingsControllerOfferingCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: either(imported2_bankOfferingDataC, imported0_directOfferingDataC),
};

export const issuersitesBondOfferingsControllerOffering = (p: IssuersitesBondOfferingsControllerOfferingParams) =>
  urlInterfaceIO("GET", issuersitesBondOfferingsControllerOfferingRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesBondOfferingsControllerOfferingCodecs);

const issuersitesBondOfferingsControllerArchivedBondsPath = str("issuerSlug").then(lit("bond-offerings")).then(lit("archive")).then(type("issuerId", issuerIdC));
const issuersitesBondOfferingsControllerArchivedBondsPathParts = [
  "issuerSlug", "bond-offerings", "archive", "issuerId"
] as const;
const issuersitesBondOfferingsControllerArchivedBondsQuery = t.strict({});
export type IssuersitesBondOfferingsControllerArchivedBondsParams = Describe<typeof issuersitesBondOfferingsControllerArchivedBondsPath._A & typeof issuersitesBondOfferingsControllerArchivedBondsQuery._A>;

export function issuersitesBondOfferingsControllerArchivedBondsRoute(): { match: Match<IssuersitesBondOfferingsControllerArchivedBondsParams>; pathParts: typeof issuersitesBondOfferingsControllerArchivedBondsPathParts };
export function issuersitesBondOfferingsControllerArchivedBondsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerArchivedBondsParams>): { match: Match<IssuersitesBondOfferingsControllerArchivedBondsParams & t.TypeOf<A>>; pathParts: typeof issuersitesBondOfferingsControllerArchivedBondsPathParts };
export function issuersitesBondOfferingsControllerArchivedBondsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerArchivedBondsParams>) {
  return {
    match: (q ? issuersitesBondOfferingsControllerArchivedBondsPath.then(query(t.intersection([issuersitesBondOfferingsControllerArchivedBondsQuery, q]))) : issuersitesBondOfferingsControllerArchivedBondsPath.then(query(issuersitesBondOfferingsControllerArchivedBondsQuery))).then(end),
    pathParts: issuersitesBondOfferingsControllerArchivedBondsPathParts,
  };
}

export const issuersitesBondOfferingsControllerArchivedBondsCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_archivedBondsSsrDataC,
};

export const issuersitesBondOfferingsControllerArchivedBonds = (p: IssuersitesBondOfferingsControllerArchivedBondsParams) =>
  urlInterfaceIO("GET", issuersitesBondOfferingsControllerArchivedBondsRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesBondOfferingsControllerArchivedBondsCodecs);

const issuersitesBondOfferingsControllerRatingsPath = str("issuerSlug").then(lit("ratings")).then(type("issuerId", issuerIdC));
const issuersitesBondOfferingsControllerRatingsPathParts = ["issuerSlug", "ratings", "issuerId"] as const;
const issuersitesBondOfferingsControllerRatingsQuery = t.strict({});
export type IssuersitesBondOfferingsControllerRatingsParams = Describe<typeof issuersitesBondOfferingsControllerRatingsPath._A & typeof issuersitesBondOfferingsControllerRatingsQuery._A>;

export function issuersitesBondOfferingsControllerRatingsRoute(): { match: Match<IssuersitesBondOfferingsControllerRatingsParams>; pathParts: typeof issuersitesBondOfferingsControllerRatingsPathParts };
export function issuersitesBondOfferingsControllerRatingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerRatingsParams>): { match: Match<IssuersitesBondOfferingsControllerRatingsParams & t.TypeOf<A>>; pathParts: typeof issuersitesBondOfferingsControllerRatingsPathParts };
export function issuersitesBondOfferingsControllerRatingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerRatingsParams>) {
  return {
    match: (q ? issuersitesBondOfferingsControllerRatingsPath.then(query(t.intersection([issuersitesBondOfferingsControllerRatingsQuery, q]))) : issuersitesBondOfferingsControllerRatingsPath.then(query(issuersitesBondOfferingsControllerRatingsQuery))).then(end),
    pathParts: issuersitesBondOfferingsControllerRatingsPathParts,
  };
}

export const issuersitesBondOfferingsControllerRatingsCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported1_issuerAndProgramRatingsByAgencyC,
};

export const issuersitesBondOfferingsControllerRatings = (p: IssuersitesBondOfferingsControllerRatingsParams) =>
  urlInterfaceIO("GET", issuersitesBondOfferingsControllerRatingsRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesBondOfferingsControllerRatingsCodecs);

const issuersitesIssuerControllerIndexPath = str("issuerSlug").then(type("issuerId", issuerIdC));
const issuersitesIssuerControllerIndexPathParts = ["issuerSlug", "issuerId"] as const;
const issuersitesIssuerControllerIndexQuery = t.strict({});
export type IssuersitesIssuerControllerIndexParams = Describe<typeof issuersitesIssuerControllerIndexPath._A & typeof issuersitesIssuerControllerIndexQuery._A>;

export function issuersitesIssuerControllerIndexRoute(): { match: Match<IssuersitesIssuerControllerIndexParams>; pathParts: typeof issuersitesIssuerControllerIndexPathParts };
export function issuersitesIssuerControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerIndexParams>): { match: Match<IssuersitesIssuerControllerIndexParams & t.TypeOf<A>>; pathParts: typeof issuersitesIssuerControllerIndexPathParts };
export function issuersitesIssuerControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerIndexParams>) {
  return {
    match: (q ? issuersitesIssuerControllerIndexPath.then(query(t.intersection([issuersitesIssuerControllerIndexQuery, q]))) : issuersitesIssuerControllerIndexPath.then(query(issuersitesIssuerControllerIndexQuery))).then(end),
    pathParts: issuersitesIssuerControllerIndexPathParts,
  };
}

export const issuersitesIssuerControllerIndexCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_issuerHomepageDataC,
};

export const issuersitesIssuerControllerIndex = (p: IssuersitesIssuerControllerIndexParams) =>
  urlInterfaceIO("GET", issuersitesIssuerControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesIssuerControllerIndexCodecs);

const issuersitesAboutControllerIndexPath = str("issuerSlug").then(lit("about")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerIndexPathParts = ["issuerSlug", "about", "issuerId"] as const;
const issuersitesAboutControllerIndexQuery = t.strict({});
export type IssuersitesAboutControllerIndexParams = Describe<typeof issuersitesAboutControllerIndexPath._A & typeof issuersitesAboutControllerIndexQuery._A>;

export function issuersitesAboutControllerIndexRoute(): { match: Match<IssuersitesAboutControllerIndexParams>; pathParts: typeof issuersitesAboutControllerIndexPathParts };
export function issuersitesAboutControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerIndexParams>): { match: Match<IssuersitesAboutControllerIndexParams & t.TypeOf<A>>; pathParts: typeof issuersitesAboutControllerIndexPathParts };
export function issuersitesAboutControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerIndexParams>) {
  return {
    match: (q ? issuersitesAboutControllerIndexPath.then(query(t.intersection([issuersitesAboutControllerIndexQuery, q]))) : issuersitesAboutControllerIndexPath.then(query(issuersitesAboutControllerIndexQuery))).then(end),
    pathParts: issuersitesAboutControllerIndexPathParts,
  };
}

export const issuersitesAboutControllerIndexCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_issuerAboutPageDataC,
};

export const issuersitesAboutControllerIndex = (p: IssuersitesAboutControllerIndexParams) =>
  urlInterfaceIO("GET", issuersitesAboutControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesAboutControllerIndexCodecs);

const issuersitesAboutControllerEsgProgramPath = str("issuerSlug").then(lit("about")).then(lit("esg-program")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerEsgProgramPathParts = ["issuerSlug", "about", "esg-program", "issuerId"] as const;
const issuersitesAboutControllerEsgProgramQuery = t.strict({});
export type IssuersitesAboutControllerEsgProgramParams = Describe<typeof issuersitesAboutControllerEsgProgramPath._A & typeof issuersitesAboutControllerEsgProgramQuery._A>;

export function issuersitesAboutControllerEsgProgramRoute(): { match: Match<IssuersitesAboutControllerEsgProgramParams>; pathParts: typeof issuersitesAboutControllerEsgProgramPathParts };
export function issuersitesAboutControllerEsgProgramRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerEsgProgramParams>): { match: Match<IssuersitesAboutControllerEsgProgramParams & t.TypeOf<A>>; pathParts: typeof issuersitesAboutControllerEsgProgramPathParts };
export function issuersitesAboutControllerEsgProgramRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerEsgProgramParams>) {
  return {
    match: (q ? issuersitesAboutControllerEsgProgramPath.then(query(t.intersection([issuersitesAboutControllerEsgProgramQuery, q]))) : issuersitesAboutControllerEsgProgramPath.then(query(issuersitesAboutControllerEsgProgramQuery))).then(end),
    pathParts: issuersitesAboutControllerEsgProgramPathParts,
  };
}

export const issuersitesAboutControllerEsgProgramCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_issuerEsgPageDataC,
};

export const issuersitesAboutControllerEsgProgram = (p: IssuersitesAboutControllerEsgProgramParams) =>
  urlInterfaceIO("GET", issuersitesAboutControllerEsgProgramRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesAboutControllerEsgProgramCodecs);

const issuersitesAboutControllerNewsEventsPath = str("issuerSlug").then(lit("about")).then(lit("news-events")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerNewsEventsPathParts = ["issuerSlug", "about", "news-events", "issuerId"] as const;
const issuersitesAboutControllerNewsEventsQuery = t.strict({});
export type IssuersitesAboutControllerNewsEventsParams = Describe<typeof issuersitesAboutControllerNewsEventsPath._A & typeof issuersitesAboutControllerNewsEventsQuery._A>;

export function issuersitesAboutControllerNewsEventsRoute(): { match: Match<IssuersitesAboutControllerNewsEventsParams>; pathParts: typeof issuersitesAboutControllerNewsEventsPathParts };
export function issuersitesAboutControllerNewsEventsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerNewsEventsParams>): { match: Match<IssuersitesAboutControllerNewsEventsParams & t.TypeOf<A>>; pathParts: typeof issuersitesAboutControllerNewsEventsPathParts };
export function issuersitesAboutControllerNewsEventsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerNewsEventsParams>) {
  return {
    match: (q ? issuersitesAboutControllerNewsEventsPath.then(query(t.intersection([issuersitesAboutControllerNewsEventsQuery, q]))) : issuersitesAboutControllerNewsEventsPath.then(query(issuersitesAboutControllerNewsEventsQuery))).then(end),
    pathParts: issuersitesAboutControllerNewsEventsPathParts,
  };
}

export const issuersitesAboutControllerNewsEventsCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_newsAndEventsDataC,
};

export const issuersitesAboutControllerNewsEvents = (p: IssuersitesAboutControllerNewsEventsParams) =>
  urlInterfaceIO("GET", issuersitesAboutControllerNewsEventsRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesAboutControllerNewsEventsCodecs);

const issuersitesAboutControllerProjectItemPath = str("issuerSlug").then(lit("about")).then(lit("project")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerProjectItemPathParts = ["issuerSlug", "about", "project", "issuerId"] as const;
const issuersitesAboutControllerProjectItemQuery = t.exact(t.type({
  "projectId": NumberFromString
}));
export type IssuersitesAboutControllerProjectItemParams = Describe<typeof issuersitesAboutControllerProjectItemPath._A & typeof issuersitesAboutControllerProjectItemQuery._A>;

export function issuersitesAboutControllerProjectItemRoute(): { match: Match<IssuersitesAboutControllerProjectItemParams>; pathParts: typeof issuersitesAboutControllerProjectItemPathParts };
export function issuersitesAboutControllerProjectItemRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerProjectItemParams>): { match: Match<IssuersitesAboutControllerProjectItemParams & t.TypeOf<A>>; pathParts: typeof issuersitesAboutControllerProjectItemPathParts };
export function issuersitesAboutControllerProjectItemRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerProjectItemParams>) {
  return {
    match: (q ? issuersitesAboutControllerProjectItemPath.then(query(t.intersection([issuersitesAboutControllerProjectItemQuery, q]))) : issuersitesAboutControllerProjectItemPath.then(query(issuersitesAboutControllerProjectItemQuery))).then(end),
    pathParts: issuersitesAboutControllerProjectItemPathParts,
  };
}

export const issuersitesAboutControllerProjectItemCodecs = { input: emptyObjectC, output: htmlC, };

export const issuersitesAboutControllerProjectItem = (p: IssuersitesAboutControllerProjectItemParams) =>
  urlInterfaceIO("GET", issuersitesAboutControllerProjectItemRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesAboutControllerProjectItemCodecs);

const issuersitesAboutControllerProjectsPath = str("issuerSlug").then(lit("about")).then(lit("projects")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerProjectsPathParts = ["issuerSlug", "about", "projects", "issuerId"] as const;
const issuersitesAboutControllerProjectsQuery = t.strict({});
export type IssuersitesAboutControllerProjectsParams = Describe<typeof issuersitesAboutControllerProjectsPath._A & typeof issuersitesAboutControllerProjectsQuery._A>;

export function issuersitesAboutControllerProjectsRoute(): { match: Match<IssuersitesAboutControllerProjectsParams>; pathParts: typeof issuersitesAboutControllerProjectsPathParts };
export function issuersitesAboutControllerProjectsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerProjectsParams>): { match: Match<IssuersitesAboutControllerProjectsParams & t.TypeOf<A>>; pathParts: typeof issuersitesAboutControllerProjectsPathParts };
export function issuersitesAboutControllerProjectsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerProjectsParams>) {
  return {
    match: (q ? issuersitesAboutControllerProjectsPath.then(query(t.intersection([issuersitesAboutControllerProjectsQuery, q]))) : issuersitesAboutControllerProjectsPath.then(query(issuersitesAboutControllerProjectsQuery))).then(end),
    pathParts: issuersitesAboutControllerProjectsPathParts,
  };
}

export const issuersitesAboutControllerProjectsCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: t.readonlyArray(imported444_WithStatusCU(imported231_taggedContentC(imported4_projectWithPhotosC))),
};

export const issuersitesAboutControllerProjects = (p: IssuersitesAboutControllerProjectsParams) =>
  urlInterfaceIO("GET", issuersitesAboutControllerProjectsRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesAboutControllerProjectsCodecs);

const issuersitesAboutControllerTeamPath = str("issuerSlug").then(lit("about")).then(lit("our-team")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerTeamPathParts = ["issuerSlug", "about", "our-team", "issuerId"] as const;
const issuersitesAboutControllerTeamQuery = t.strict({});
export type IssuersitesAboutControllerTeamParams = Describe<typeof issuersitesAboutControllerTeamPath._A & typeof issuersitesAboutControllerTeamQuery._A>;

export function issuersitesAboutControllerTeamRoute(): { match: Match<IssuersitesAboutControllerTeamParams>; pathParts: typeof issuersitesAboutControllerTeamPathParts };
export function issuersitesAboutControllerTeamRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerTeamParams>): { match: Match<IssuersitesAboutControllerTeamParams & t.TypeOf<A>>; pathParts: typeof issuersitesAboutControllerTeamPathParts };
export function issuersitesAboutControllerTeamRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerTeamParams>) {
  return {
    match: (q ? issuersitesAboutControllerTeamPath.then(query(t.intersection([issuersitesAboutControllerTeamQuery, q]))) : issuersitesAboutControllerTeamPath.then(query(issuersitesAboutControllerTeamQuery))).then(end),
    pathParts: issuersitesAboutControllerTeamPathParts,
  };
}

export const issuersitesAboutControllerTeamCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: t.readonlyArray(imported445_WithStatusCU(imported19_officerC)),
};

export const issuersitesAboutControllerTeam = (p: IssuersitesAboutControllerTeamParams) =>
  urlInterfaceIO("GET", issuersitesAboutControllerTeamRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesAboutControllerTeamCodecs);

const issuersitesBondProgramsControllerBondProgramsPath = str("issuerSlug").then(lit("bond-programs")).then(type("issuerId", issuerIdC));
const issuersitesBondProgramsControllerBondProgramsPathParts = ["issuerSlug", "bond-programs", "issuerId"] as const;
const issuersitesBondProgramsControllerBondProgramsQuery = t.strict({});
export type IssuersitesBondProgramsControllerBondProgramsParams = Describe<typeof issuersitesBondProgramsControllerBondProgramsPath._A & typeof issuersitesBondProgramsControllerBondProgramsQuery._A>;

export function issuersitesBondProgramsControllerBondProgramsRoute(): { match: Match<IssuersitesBondProgramsControllerBondProgramsParams>; pathParts: typeof issuersitesBondProgramsControllerBondProgramsPathParts };
export function issuersitesBondProgramsControllerBondProgramsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondProgramsControllerBondProgramsParams>): { match: Match<IssuersitesBondProgramsControllerBondProgramsParams & t.TypeOf<A>>; pathParts: typeof issuersitesBondProgramsControllerBondProgramsPathParts };
export function issuersitesBondProgramsControllerBondProgramsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondProgramsControllerBondProgramsParams>) {
  return {
    match: (q ? issuersitesBondProgramsControllerBondProgramsPath.then(query(t.intersection([issuersitesBondProgramsControllerBondProgramsQuery, q]))) : issuersitesBondProgramsControllerBondProgramsPath.then(query(issuersitesBondProgramsControllerBondProgramsQuery))).then(end),
    pathParts: issuersitesBondProgramsControllerBondProgramsPathParts,
  };
}

export const issuersitesBondProgramsControllerBondProgramsCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: t.readonlyArray(imported446_WithStatusCU(imported7_bondProgramWithRelatedContentC)),
};

export const issuersitesBondProgramsControllerBondPrograms = (p: IssuersitesBondProgramsControllerBondProgramsParams) =>
  urlInterfaceIO("GET", issuersitesBondProgramsControllerBondProgramsRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesBondProgramsControllerBondProgramsCodecs);

const issuersitesBondProgramsControllerBondProgramPath = str("issuerSlug").then(lit("bond-programs")).then(type("issuerId", issuerIdC));
const issuersitesBondProgramsControllerBondProgramPathParts = ["issuerSlug", "bond-programs", "issuerId"] as const;
const issuersitesBondProgramsControllerBondProgramQuery = t.exact(t.type({
  "programId": NumberFromString
}));
export type IssuersitesBondProgramsControllerBondProgramParams = Describe<typeof issuersitesBondProgramsControllerBondProgramPath._A & typeof issuersitesBondProgramsControllerBondProgramQuery._A>;

export function issuersitesBondProgramsControllerBondProgramRoute(): { match: Match<IssuersitesBondProgramsControllerBondProgramParams>; pathParts: typeof issuersitesBondProgramsControllerBondProgramPathParts };
export function issuersitesBondProgramsControllerBondProgramRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondProgramsControllerBondProgramParams>): { match: Match<IssuersitesBondProgramsControllerBondProgramParams & t.TypeOf<A>>; pathParts: typeof issuersitesBondProgramsControllerBondProgramPathParts };
export function issuersitesBondProgramsControllerBondProgramRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondProgramsControllerBondProgramParams>) {
  return {
    match: (q ? issuersitesBondProgramsControllerBondProgramPath.then(query(t.intersection([issuersitesBondProgramsControllerBondProgramQuery, q]))) : issuersitesBondProgramsControllerBondProgramPath.then(query(issuersitesBondProgramsControllerBondProgramQuery))).then(end),
    pathParts: issuersitesBondProgramsControllerBondProgramPathParts,
  };
}

export const issuersitesBondProgramsControllerBondProgramCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported447_WithStatusCU(imported8_bondProgramWithRelatedContentC),
};

export const issuersitesBondProgramsControllerBondProgram = (p: IssuersitesBondProgramsControllerBondProgramParams) =>
  urlInterfaceIO("GET", issuersitesBondProgramsControllerBondProgramRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesBondProgramsControllerBondProgramCodecs);

const issuersitesResourcesControllerFaqPath = str("issuerSlug").then(lit("resources")).then(lit("faq")).then(type("issuerId", issuerIdC));
const issuersitesResourcesControllerFaqPathParts = ["issuerSlug", "resources", "faq", "issuerId"] as const;
const issuersitesResourcesControllerFaqQuery = t.strict({});
export type IssuersitesResourcesControllerFaqParams = Describe<typeof issuersitesResourcesControllerFaqPath._A & typeof issuersitesResourcesControllerFaqQuery._A>;

export function issuersitesResourcesControllerFaqRoute(): { match: Match<IssuersitesResourcesControllerFaqParams>; pathParts: typeof issuersitesResourcesControllerFaqPathParts };
export function issuersitesResourcesControllerFaqRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesResourcesControllerFaqParams>): { match: Match<IssuersitesResourcesControllerFaqParams & t.TypeOf<A>>; pathParts: typeof issuersitesResourcesControllerFaqPathParts };
export function issuersitesResourcesControllerFaqRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesResourcesControllerFaqParams>) {
  return {
    match: (q ? issuersitesResourcesControllerFaqPath.then(query(t.intersection([issuersitesResourcesControllerFaqQuery, q]))) : issuersitesResourcesControllerFaqPath.then(query(issuersitesResourcesControllerFaqQuery))).then(end),
    pathParts: issuersitesResourcesControllerFaqPathParts,
  };
}

export const issuersitesResourcesControllerFaqCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported1_issuerFaqsWithSectionsC,
};

export const issuersitesResourcesControllerFaq = (p: IssuersitesResourcesControllerFaqParams) =>
  urlInterfaceIO("GET", issuersitesResourcesControllerFaqRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesResourcesControllerFaqCodecs);

const issuersitesEmmaControllerLinksPath = str("issuerSlug").then(lit("resources")).then(lit("msrb-emma-links")).then(type("issuerId", issuerIdC));
const issuersitesEmmaControllerLinksPathParts = ["issuerSlug", "resources", "msrb-emma-links", "issuerId"] as const;
const issuersitesEmmaControllerLinksQuery = t.exact(t.partial({
  "cusip9": t.string
}));
export type IssuersitesEmmaControllerLinksParamsRaw = Describe<typeof issuersitesEmmaControllerLinksPath._A & typeof issuersitesEmmaControllerLinksQuery._A>;
export type IssuersitesEmmaControllerLinksParams = Describe<NullableToOptionalFields<IssuersitesEmmaControllerLinksParamsRaw>>;

export function issuersitesEmmaControllerLinksRoute(): { match: Match<IssuersitesEmmaControllerLinksParamsRaw>; pathParts: typeof issuersitesEmmaControllerLinksPathParts };
export function issuersitesEmmaControllerLinksRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesEmmaControllerLinksParamsRaw>): { match: Match<IssuersitesEmmaControllerLinksParamsRaw & t.TypeOf<A>>; pathParts: typeof issuersitesEmmaControllerLinksPathParts };
export function issuersitesEmmaControllerLinksRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesEmmaControllerLinksParamsRaw>) {
  return {
    match: (q ? issuersitesEmmaControllerLinksPath.then(query(t.intersection([issuersitesEmmaControllerLinksQuery, q]))) : issuersitesEmmaControllerLinksPath.then(query(issuersitesEmmaControllerLinksQuery))).then(end),
    pathParts: issuersitesEmmaControllerLinksPathParts,
  };
}

export const issuersitesEmmaControllerLinksCodecs = { input: emptyObjectC, output: htmlC, };

export const issuersitesEmmaControllerLinks = (p: IssuersitesEmmaControllerLinksParams) =>
  urlInterfaceIO("GET", issuersitesEmmaControllerLinksRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true), issuersitesEmmaControllerLinksCodecs);

const issuersitesReportsControllerDownloadRedirectPath = str("issuerSlug").then(lit("documents")).then(lit("download-report-direct")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerDownloadRedirectPathParts = [
  "issuerSlug", "documents", "download-report-direct", "issuerId"
] as const;
const issuersitesReportsControllerDownloadRedirectQuery = t.exact(t.type({
  "mediaId": NumberFromString
}));
export type IssuersitesReportsControllerDownloadRedirectParams = Describe<typeof issuersitesReportsControllerDownloadRedirectPath._A & typeof issuersitesReportsControllerDownloadRedirectQuery._A>;

export function issuersitesReportsControllerDownloadRedirectRoute(): { match: Match<IssuersitesReportsControllerDownloadRedirectParams>; pathParts: typeof issuersitesReportsControllerDownloadRedirectPathParts };
export function issuersitesReportsControllerDownloadRedirectRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerDownloadRedirectParams>): { match: Match<IssuersitesReportsControllerDownloadRedirectParams & t.TypeOf<A>>; pathParts: typeof issuersitesReportsControllerDownloadRedirectPathParts };
export function issuersitesReportsControllerDownloadRedirectRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerDownloadRedirectParams>) {
  return {
    match: (q ? issuersitesReportsControllerDownloadRedirectPath.then(query(t.intersection([issuersitesReportsControllerDownloadRedirectQuery, q]))) : issuersitesReportsControllerDownloadRedirectPath.then(query(issuersitesReportsControllerDownloadRedirectQuery))).then(end),
    pathParts: issuersitesReportsControllerDownloadRedirectPathParts,
  };
}

export const issuersitesReportsControllerDownloadRedirectCodecs = {
  input: emptyObjectC,
  output: imported26_browserRedirectC,
};

export const issuersitesReportsControllerDownloadRedirect = (p: IssuersitesReportsControllerDownloadRedirectParams) =>
  urlInterfaceIO("GET", issuersitesReportsControllerDownloadRedirectRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesReportsControllerDownloadRedirectCodecs);

const issuersitesRoadShowControllerRoadShowPath = str("issuerSlug").then(lit("bonds")).then(lit("roadshow")).then(type("issuerId", issuerIdC));
const issuersitesRoadShowControllerRoadShowPathParts = ["issuerSlug", "bonds", "roadshow", "issuerId"] as const;
const issuersitesRoadShowControllerRoadShowQuery = t.exact(t.type({
  "roadShowId": NumberFromString
}));
export type IssuersitesRoadShowControllerRoadShowParams = Describe<typeof issuersitesRoadShowControllerRoadShowPath._A & typeof issuersitesRoadShowControllerRoadShowQuery._A>;

export function issuersitesRoadShowControllerRoadShowRoute(): { match: Match<IssuersitesRoadShowControllerRoadShowParams>; pathParts: typeof issuersitesRoadShowControllerRoadShowPathParts };
export function issuersitesRoadShowControllerRoadShowRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRoadShowControllerRoadShowParams>): { match: Match<IssuersitesRoadShowControllerRoadShowParams & t.TypeOf<A>>; pathParts: typeof issuersitesRoadShowControllerRoadShowPathParts };
export function issuersitesRoadShowControllerRoadShowRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRoadShowControllerRoadShowParams>) {
  return {
    match: (q ? issuersitesRoadShowControllerRoadShowPath.then(query(t.intersection([issuersitesRoadShowControllerRoadShowQuery, q]))) : issuersitesRoadShowControllerRoadShowPath.then(query(issuersitesRoadShowControllerRoadShowQuery))).then(end),
    pathParts: issuersitesRoadShowControllerRoadShowPathParts,
  };
}

export const issuersitesRoadShowControllerRoadShowCodecs = { input: emptyObjectC, output: htmlC, };

export const issuersitesRoadShowControllerRoadShow = (p: IssuersitesRoadShowControllerRoadShowParams) =>
  urlInterfaceIO("GET", issuersitesRoadShowControllerRoadShowRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesRoadShowControllerRoadShowCodecs);

const issuersitesRoadShowControllerRoadShowPlayerPath = str("issuerSlug").then(lit("bonds")).then(lit("roadshow-player")).then(type("issuerId", issuerIdC));
const issuersitesRoadShowControllerRoadShowPlayerPathParts = [
  "issuerSlug", "bonds", "roadshow-player", "issuerId"
] as const;
const issuersitesRoadShowControllerRoadShowPlayerQuery = t.exact(t.type({
  "roadShowId": NumberFromString
}));
export type IssuersitesRoadShowControllerRoadShowPlayerParams = Describe<typeof issuersitesRoadShowControllerRoadShowPlayerPath._A & typeof issuersitesRoadShowControllerRoadShowPlayerQuery._A>;

export function issuersitesRoadShowControllerRoadShowPlayerRoute(): { match: Match<IssuersitesRoadShowControllerRoadShowPlayerParams>; pathParts: typeof issuersitesRoadShowControllerRoadShowPlayerPathParts };
export function issuersitesRoadShowControllerRoadShowPlayerRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRoadShowControllerRoadShowPlayerParams>): { match: Match<IssuersitesRoadShowControllerRoadShowPlayerParams & t.TypeOf<A>>; pathParts: typeof issuersitesRoadShowControllerRoadShowPlayerPathParts };
export function issuersitesRoadShowControllerRoadShowPlayerRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRoadShowControllerRoadShowPlayerParams>) {
  return {
    match: (q ? issuersitesRoadShowControllerRoadShowPlayerPath.then(query(t.intersection([issuersitesRoadShowControllerRoadShowPlayerQuery, q]))) : issuersitesRoadShowControllerRoadShowPlayerPath.then(query(issuersitesRoadShowControllerRoadShowPlayerQuery))).then(end),
    pathParts: issuersitesRoadShowControllerRoadShowPlayerPathParts,
  };
}

export const issuersitesRoadShowControllerRoadShowPlayerCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_roadShowSsrDataC,
};

export const issuersitesRoadShowControllerRoadShowPlayer = (p: IssuersitesRoadShowControllerRoadShowPlayerParams) =>
  urlInterfaceIO("GET", issuersitesRoadShowControllerRoadShowPlayerRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesRoadShowControllerRoadShowPlayerCodecs);

const issuersitesRoadShowControllerReportProgressPath = lit("roadshow").then(lit("report"));
const issuersitesRoadShowControllerReportProgressPathParts = ["roadshow", "report"] as const;
const issuersitesRoadShowControllerReportProgressQuery = t.strict({});
export type IssuersitesRoadShowControllerReportProgressParams = Describe<typeof issuersitesRoadShowControllerReportProgressPath._A & typeof issuersitesRoadShowControllerReportProgressQuery._A>;

export function issuersitesRoadShowControllerReportProgressRoute(): { match: Match<IssuersitesRoadShowControllerReportProgressParams>; pathParts: typeof issuersitesRoadShowControllerReportProgressPathParts };
export function issuersitesRoadShowControllerReportProgressRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRoadShowControllerReportProgressParams>): { match: Match<IssuersitesRoadShowControllerReportProgressParams & t.TypeOf<A>>; pathParts: typeof issuersitesRoadShowControllerReportProgressPathParts };
export function issuersitesRoadShowControllerReportProgressRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRoadShowControllerReportProgressParams>) {
  return {
    match: (q ? issuersitesRoadShowControllerReportProgressPath.then(query(t.intersection([issuersitesRoadShowControllerReportProgressQuery, q]))) : issuersitesRoadShowControllerReportProgressPath.then(query(issuersitesRoadShowControllerReportProgressQuery))).then(end),
    pathParts: issuersitesRoadShowControllerReportProgressPathParts,
  };
}

export const issuersitesRoadShowControllerReportProgressCodecs = {
  input: imported0_roadShowProgressPostC,
  output: emptyObjectC,
};

export const issuersitesRoadShowControllerReportProgress = () =>
  urlInterfaceIO("POST", issuersitesRoadShowControllerReportProgressRoute().match.formatter.run(Route.empty, {}).toString(true), issuersitesRoadShowControllerReportProgressCodecs);

const issuersitesReportsControllerIndexPath = str("issuerSlug").then(lit("documents")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerIndexPathParts = ["issuerSlug", "documents", "issuerId"] as const;
const issuersitesReportsControllerIndexQuery = t.strict({});
export type IssuersitesReportsControllerIndexParams = Describe<typeof issuersitesReportsControllerIndexPath._A & typeof issuersitesReportsControllerIndexQuery._A>;

export function issuersitesReportsControllerIndexRoute(): { match: Match<IssuersitesReportsControllerIndexParams>; pathParts: typeof issuersitesReportsControllerIndexPathParts };
export function issuersitesReportsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerIndexParams>): { match: Match<IssuersitesReportsControllerIndexParams & t.TypeOf<A>>; pathParts: typeof issuersitesReportsControllerIndexPathParts };
export function issuersitesReportsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerIndexParams>) {
  return {
    match: (q ? issuersitesReportsControllerIndexPath.then(query(t.intersection([issuersitesReportsControllerIndexQuery, q]))) : issuersitesReportsControllerIndexPath.then(query(issuersitesReportsControllerIndexQuery))).then(end),
    pathParts: issuersitesReportsControllerIndexPathParts,
  };
}

export const issuersitesReportsControllerIndexCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_issuerDocumentCategoriesPageDataC,
};

export const issuersitesReportsControllerIndex = (p: IssuersitesReportsControllerIndexParams) =>
  urlInterfaceIO("GET", issuersitesReportsControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesReportsControllerIndexCodecs);

const issuersitesReportsControllerDownloadsPath = str("issuerSlug").then(lit("documents")).then(lit("downloads")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerDownloadsPathParts = ["issuerSlug", "documents", "downloads", "issuerId"] as const;
const issuersitesReportsControllerDownloadsQuery = t.exact(t.partial({
  "mediaCategoryId": NumberFromString,
  "docTypeId": NumberFromString
}));
export type IssuersitesReportsControllerDownloadsParamsRaw = Describe<typeof issuersitesReportsControllerDownloadsPath._A & typeof issuersitesReportsControllerDownloadsQuery._A>;
export type IssuersitesReportsControllerDownloadsParams = Describe<NullableToOptionalFields<IssuersitesReportsControllerDownloadsParamsRaw>>;

export function issuersitesReportsControllerDownloadsRoute(): { match: Match<IssuersitesReportsControllerDownloadsParamsRaw>; pathParts: typeof issuersitesReportsControllerDownloadsPathParts };
export function issuersitesReportsControllerDownloadsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerDownloadsParamsRaw>): { match: Match<IssuersitesReportsControllerDownloadsParamsRaw & t.TypeOf<A>>; pathParts: typeof issuersitesReportsControllerDownloadsPathParts };
export function issuersitesReportsControllerDownloadsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerDownloadsParamsRaw>) {
  return {
    match: (q ? issuersitesReportsControllerDownloadsPath.then(query(t.intersection([issuersitesReportsControllerDownloadsQuery, q]))) : issuersitesReportsControllerDownloadsPath.then(query(issuersitesReportsControllerDownloadsQuery))).then(end),
    pathParts: issuersitesReportsControllerDownloadsPathParts,
  };
}

export const issuersitesReportsControllerDownloadsCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_issuerDownloadsPageDataC,
};

export const issuersitesReportsControllerDownloads = (p: IssuersitesReportsControllerDownloadsParams) =>
  urlInterfaceIO("GET", issuersitesReportsControllerDownloadsRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true), issuersitesReportsControllerDownloadsCodecs);

const issuersitesReportsControllerArchiveDownloadsPath = str("issuerSlug").then(lit("documents")).then(lit("archive")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerArchiveDownloadsPathParts = [
  "issuerSlug", "documents", "archive", "issuerId"
] as const;
const issuersitesReportsControllerArchiveDownloadsQuery = t.exact(t.partial({
  "mediaCategoryId": NumberFromString,
  "docTypeId": NumberFromString
}));
export type IssuersitesReportsControllerArchiveDownloadsParamsRaw = Describe<typeof issuersitesReportsControllerArchiveDownloadsPath._A & typeof issuersitesReportsControllerArchiveDownloadsQuery._A>;
export type IssuersitesReportsControllerArchiveDownloadsParams = Describe<NullableToOptionalFields<IssuersitesReportsControllerArchiveDownloadsParamsRaw>>;

export function issuersitesReportsControllerArchiveDownloadsRoute(): { match: Match<IssuersitesReportsControllerArchiveDownloadsParamsRaw>; pathParts: typeof issuersitesReportsControllerArchiveDownloadsPathParts };
export function issuersitesReportsControllerArchiveDownloadsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerArchiveDownloadsParamsRaw>): { match: Match<IssuersitesReportsControllerArchiveDownloadsParamsRaw & t.TypeOf<A>>; pathParts: typeof issuersitesReportsControllerArchiveDownloadsPathParts };
export function issuersitesReportsControllerArchiveDownloadsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerArchiveDownloadsParamsRaw>) {
  return {
    match: (q ? issuersitesReportsControllerArchiveDownloadsPath.then(query(t.intersection([issuersitesReportsControllerArchiveDownloadsQuery, q]))) : issuersitesReportsControllerArchiveDownloadsPath.then(query(issuersitesReportsControllerArchiveDownloadsQuery))).then(end),
    pathParts: issuersitesReportsControllerArchiveDownloadsPathParts,
  };
}

export const issuersitesReportsControllerArchiveDownloadsCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported1_issuerDownloadsPageDataC,
};

export const issuersitesReportsControllerArchiveDownloads = (p: IssuersitesReportsControllerArchiveDownloadsParams) =>
  urlInterfaceIO("GET", issuersitesReportsControllerArchiveDownloadsRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true), issuersitesReportsControllerArchiveDownloadsCodecs);

const issuersitesReportsControllerViewFilePath = str("issuerSlug").then(lit("documents")).then(lit("view-file")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerViewFilePathParts = ["issuerSlug", "documents", "view-file", "issuerId"] as const;
const issuersitesReportsControllerViewFileQuery = t.exact(t.type({
  "mediaId": NumberFromString
}));
export type IssuersitesReportsControllerViewFileParams = Describe<typeof issuersitesReportsControllerViewFilePath._A & typeof issuersitesReportsControllerViewFileQuery._A>;

export function issuersitesReportsControllerViewFileRoute(): { match: Match<IssuersitesReportsControllerViewFileParams>; pathParts: typeof issuersitesReportsControllerViewFilePathParts };
export function issuersitesReportsControllerViewFileRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerViewFileParams>): { match: Match<IssuersitesReportsControllerViewFileParams & t.TypeOf<A>>; pathParts: typeof issuersitesReportsControllerViewFilePathParts };
export function issuersitesReportsControllerViewFileRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerViewFileParams>) {
  return {
    match: (q ? issuersitesReportsControllerViewFilePath.then(query(t.intersection([issuersitesReportsControllerViewFileQuery, q]))) : issuersitesReportsControllerViewFilePath.then(query(issuersitesReportsControllerViewFileQuery))).then(end),
    pathParts: issuersitesReportsControllerViewFilePathParts,
  };
}

export const issuersitesReportsControllerViewFileCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported448_WithStatusCU(imported232_taggedContentC(imported22_documentWithCategoryC)),
};

export const issuersitesReportsControllerViewFile = (p: IssuersitesReportsControllerViewFileParams) =>
  urlInterfaceIO("GET", issuersitesReportsControllerViewFileRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesReportsControllerViewFileCodecs);

const issuersitesReportsControllerIrmaLetterPath = str("issuerSlug").then(lit("documents")).then(lit("irma-letter")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerIrmaLetterPathParts = ["issuerSlug", "documents", "irma-letter", "issuerId"] as const;
const issuersitesReportsControllerIrmaLetterQuery = t.strict({});
export type IssuersitesReportsControllerIrmaLetterParams = Describe<typeof issuersitesReportsControllerIrmaLetterPath._A & typeof issuersitesReportsControllerIrmaLetterQuery._A>;

export function issuersitesReportsControllerIrmaLetterRoute(): { match: Match<IssuersitesReportsControllerIrmaLetterParams>; pathParts: typeof issuersitesReportsControllerIrmaLetterPathParts };
export function issuersitesReportsControllerIrmaLetterRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerIrmaLetterParams>): { match: Match<IssuersitesReportsControllerIrmaLetterParams & t.TypeOf<A>>; pathParts: typeof issuersitesReportsControllerIrmaLetterPathParts };
export function issuersitesReportsControllerIrmaLetterRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerIrmaLetterParams>) {
  return {
    match: (q ? issuersitesReportsControllerIrmaLetterPath.then(query(t.intersection([issuersitesReportsControllerIrmaLetterQuery, q]))) : issuersitesReportsControllerIrmaLetterPath.then(query(issuersitesReportsControllerIrmaLetterQuery))).then(end),
    pathParts: issuersitesReportsControllerIrmaLetterPathParts,
  };
}

export const issuersitesReportsControllerIrmaLetterCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported449_WithStatusCU(imported103_mediaC),
};

export const issuersitesReportsControllerIrmaLetter = (p: IssuersitesReportsControllerIrmaLetterParams) =>
  urlInterfaceIO("GET", issuersitesReportsControllerIrmaLetterRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesReportsControllerIrmaLetterCodecs);

const issuersitesResourcesControllerIndexPath = str("issuerSlug").then(lit("resources")).then(type("issuerId", issuerIdC));
const issuersitesResourcesControllerIndexPathParts = ["issuerSlug", "resources", "issuerId"] as const;
const issuersitesResourcesControllerIndexQuery = t.strict({});
export type IssuersitesResourcesControllerIndexParams = Describe<typeof issuersitesResourcesControllerIndexPath._A & typeof issuersitesResourcesControllerIndexQuery._A>;

export function issuersitesResourcesControllerIndexRoute(): { match: Match<IssuersitesResourcesControllerIndexParams>; pathParts: typeof issuersitesResourcesControllerIndexPathParts };
export function issuersitesResourcesControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesResourcesControllerIndexParams>): { match: Match<IssuersitesResourcesControllerIndexParams & t.TypeOf<A>>; pathParts: typeof issuersitesResourcesControllerIndexPathParts };
export function issuersitesResourcesControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesResourcesControllerIndexParams>) {
  return {
    match: (q ? issuersitesResourcesControllerIndexPath.then(query(t.intersection([issuersitesResourcesControllerIndexQuery, q]))) : issuersitesResourcesControllerIndexPath.then(query(issuersitesResourcesControllerIndexQuery))).then(end),
    pathParts: issuersitesResourcesControllerIndexPathParts,
  };
}

export const issuersitesResourcesControllerIndexCodecs = { input: emptyObjectC, output: htmlC, };

export const issuersitesResourcesControllerIndex = (p: IssuersitesResourcesControllerIndexParams) =>
  urlInterfaceIO("GET", issuersitesResourcesControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesResourcesControllerIndexCodecs);

const issuersitesResourcesControllerLinksPath = str("issuerSlug").then(lit("resources")).then(lit("links")).then(type("issuerId", issuerIdC));
const issuersitesResourcesControllerLinksPathParts = ["issuerSlug", "resources", "links", "issuerId"] as const;
const issuersitesResourcesControllerLinksQuery = t.strict({});
export type IssuersitesResourcesControllerLinksParams = Describe<typeof issuersitesResourcesControllerLinksPath._A & typeof issuersitesResourcesControllerLinksQuery._A>;

export function issuersitesResourcesControllerLinksRoute(): { match: Match<IssuersitesResourcesControllerLinksParams>; pathParts: typeof issuersitesResourcesControllerLinksPathParts };
export function issuersitesResourcesControllerLinksRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesResourcesControllerLinksParams>): { match: Match<IssuersitesResourcesControllerLinksParams & t.TypeOf<A>>; pathParts: typeof issuersitesResourcesControllerLinksPathParts };
export function issuersitesResourcesControllerLinksRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesResourcesControllerLinksParams>) {
  return {
    match: (q ? issuersitesResourcesControllerLinksPath.then(query(t.intersection([issuersitesResourcesControllerLinksQuery, q]))) : issuersitesResourcesControllerLinksPath.then(query(issuersitesResourcesControllerLinksQuery))).then(end),
    pathParts: issuersitesResourcesControllerLinksPathParts,
  };
}

export const issuersitesResourcesControllerLinksCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: t.readonlyArray(imported0_toManyC(imported309_withIdC(imported5_issuerLinkSectionC), imported310_withIdC(imported7_issuerLinkC))),
};

export const issuersitesResourcesControllerLinks = (p: IssuersitesResourcesControllerLinksParams) =>
  urlInterfaceIO("GET", issuersitesResourcesControllerLinksRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesResourcesControllerLinksCodecs);

const issuersitesResourcesControllerLinkPath = str("issuerSlug").then(lit("resources")).then(lit("view-link")).then(type("issuerId", issuerIdC));
const issuersitesResourcesControllerLinkPathParts = ["issuerSlug", "resources", "view-link", "issuerId"] as const;
const issuersitesResourcesControllerLinkQuery = t.exact(t.type({
  "linkId": NumberFromString
}));
export type IssuersitesResourcesControllerLinkParams = Describe<typeof issuersitesResourcesControllerLinkPath._A & typeof issuersitesResourcesControllerLinkQuery._A>;

export function issuersitesResourcesControllerLinkRoute(): { match: Match<IssuersitesResourcesControllerLinkParams>; pathParts: typeof issuersitesResourcesControllerLinkPathParts };
export function issuersitesResourcesControllerLinkRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesResourcesControllerLinkParams>): { match: Match<IssuersitesResourcesControllerLinkParams & t.TypeOf<A>>; pathParts: typeof issuersitesResourcesControllerLinkPathParts };
export function issuersitesResourcesControllerLinkRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesResourcesControllerLinkParams>) {
  return {
    match: (q ? issuersitesResourcesControllerLinkPath.then(query(t.intersection([issuersitesResourcesControllerLinkQuery, q]))) : issuersitesResourcesControllerLinkPath.then(query(issuersitesResourcesControllerLinkQuery))).then(end),
    pathParts: issuersitesResourcesControllerLinkPathParts,
  };
}

export const issuersitesResourcesControllerLinkCodecs = { input: emptyObjectC, output: imported27_browserRedirectC, };

export const issuersitesResourcesControllerLink = (p: IssuersitesResourcesControllerLinkParams) =>
  urlInterfaceIO("GET", issuersitesResourcesControllerLinkRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesResourcesControllerLinkCodecs);

const issuersitesAboutControllerNewsItemPath = str("issuerSlug").then(lit("about")).then(lit("news")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerNewsItemPathParts = ["issuerSlug", "about", "news", "issuerId"] as const;
const issuersitesAboutControllerNewsItemQuery = t.exact(t.type({
  "newsId": NumberFromString
}));
export type IssuersitesAboutControllerNewsItemParams = Describe<typeof issuersitesAboutControllerNewsItemPath._A & typeof issuersitesAboutControllerNewsItemQuery._A>;

export function issuersitesAboutControllerNewsItemRoute(): { match: Match<IssuersitesAboutControllerNewsItemParams>; pathParts: typeof issuersitesAboutControllerNewsItemPathParts };
export function issuersitesAboutControllerNewsItemRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerNewsItemParams>): { match: Match<IssuersitesAboutControllerNewsItemParams & t.TypeOf<A>>; pathParts: typeof issuersitesAboutControllerNewsItemPathParts };
export function issuersitesAboutControllerNewsItemRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerNewsItemParams>) {
  return {
    match: (q ? issuersitesAboutControllerNewsItemPath.then(query(t.intersection([issuersitesAboutControllerNewsItemQuery, q]))) : issuersitesAboutControllerNewsItemPath.then(query(issuersitesAboutControllerNewsItemQuery))).then(end),
    pathParts: issuersitesAboutControllerNewsItemPathParts,
  };
}

export const issuersitesAboutControllerNewsItemCodecs = { input: emptyObjectC, output: htmlC, };

export const issuersitesAboutControllerNewsItem = (p: IssuersitesAboutControllerNewsItemParams) =>
  urlInterfaceIO("GET", issuersitesAboutControllerNewsItemRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesAboutControllerNewsItemCodecs);

const issuersitesIssuerControllerCustomPath = str("issuerSlug").then(lit("additional-info")).then(type("issuerId", issuerIdC));
const issuersitesIssuerControllerCustomPathParts = ["issuerSlug", "additional-info", "issuerId"] as const;
const issuersitesIssuerControllerCustomQuery = t.exact(t.type({
  "i": t.union([
    literalNumberFromString(1), literalNumberFromString(2), literalNumberFromString(3), literalNumberFromString(4), literalNumberFromString(5)
  ])
}));
export type IssuersitesIssuerControllerCustomParams = Describe<typeof issuersitesIssuerControllerCustomPath._A & typeof issuersitesIssuerControllerCustomQuery._A>;

export function issuersitesIssuerControllerCustomRoute(): { match: Match<IssuersitesIssuerControllerCustomParams>; pathParts: typeof issuersitesIssuerControllerCustomPathParts };
export function issuersitesIssuerControllerCustomRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerCustomParams>): { match: Match<IssuersitesIssuerControllerCustomParams & t.TypeOf<A>>; pathParts: typeof issuersitesIssuerControllerCustomPathParts };
export function issuersitesIssuerControllerCustomRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerCustomParams>) {
  return {
    match: (q ? issuersitesIssuerControllerCustomPath.then(query(t.intersection([issuersitesIssuerControllerCustomQuery, q]))) : issuersitesIssuerControllerCustomPath.then(query(issuersitesIssuerControllerCustomQuery))).then(end),
    pathParts: issuersitesIssuerControllerCustomPathParts,
  };
}

export const issuersitesIssuerControllerCustomCodecs = { input: emptyObjectC, output: htmlC, };

export const issuersitesIssuerControllerCustom = (p: IssuersitesIssuerControllerCustomParams) =>
  urlInterfaceIO("GET", issuersitesIssuerControllerCustomRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesIssuerControllerCustomCodecs);

const issuersitesDisclaimerControllerAcceptPath = lit("v2").then(str("issuerSlug")).then(lit("accept-disclaimer")).then(type("issuerId", issuerIdC));
const issuersitesDisclaimerControllerAcceptPathParts = ["v2", "issuerSlug", "accept-disclaimer", "issuerId"] as const;
const issuersitesDisclaimerControllerAcceptQuery = t.strict({});
export type IssuersitesDisclaimerControllerAcceptParams = Describe<typeof issuersitesDisclaimerControllerAcceptPath._A & typeof issuersitesDisclaimerControllerAcceptQuery._A>;

export function issuersitesDisclaimerControllerAcceptRoute(): { match: Match<IssuersitesDisclaimerControllerAcceptParams>; pathParts: typeof issuersitesDisclaimerControllerAcceptPathParts };
export function issuersitesDisclaimerControllerAcceptRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesDisclaimerControllerAcceptParams>): { match: Match<IssuersitesDisclaimerControllerAcceptParams & t.TypeOf<A>>; pathParts: typeof issuersitesDisclaimerControllerAcceptPathParts };
export function issuersitesDisclaimerControllerAcceptRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesDisclaimerControllerAcceptParams>) {
  return {
    match: (q ? issuersitesDisclaimerControllerAcceptPath.then(query(t.intersection([issuersitesDisclaimerControllerAcceptQuery, q]))) : issuersitesDisclaimerControllerAcceptPath.then(query(issuersitesDisclaimerControllerAcceptQuery))).then(end),
    pathParts: issuersitesDisclaimerControllerAcceptPathParts,
  };
}

export const issuersitesDisclaimerControllerAcceptCodecs = {
  input: imported0_acceptDisclaimerPostC,
  output: emptyObjectC,
};

export const issuersitesDisclaimerControllerAccept = (p: IssuersitesDisclaimerControllerAcceptParams) =>
  urlInterfaceIO("POST", issuersitesDisclaimerControllerAcceptRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesDisclaimerControllerAcceptCodecs);

const issuersitesIssuerControllerContactPostPath = str("issuerSlug").then(lit("contact")).then(type("issuerId", issuerIdC));
const issuersitesIssuerControllerContactPostPathParts = ["issuerSlug", "contact", "issuerId"] as const;
const issuersitesIssuerControllerContactPostQuery = t.strict({});
export type IssuersitesIssuerControllerContactPostParams = Describe<typeof issuersitesIssuerControllerContactPostPath._A & typeof issuersitesIssuerControllerContactPostQuery._A>;

export function issuersitesIssuerControllerContactPostRoute(): { match: Match<IssuersitesIssuerControllerContactPostParams>; pathParts: typeof issuersitesIssuerControllerContactPostPathParts };
export function issuersitesIssuerControllerContactPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerContactPostParams>): { match: Match<IssuersitesIssuerControllerContactPostParams & t.TypeOf<A>>; pathParts: typeof issuersitesIssuerControllerContactPostPathParts };
export function issuersitesIssuerControllerContactPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerContactPostParams>) {
  return {
    match: (q ? issuersitesIssuerControllerContactPostPath.then(query(t.intersection([issuersitesIssuerControllerContactPostQuery, q]))) : issuersitesIssuerControllerContactPostPath.then(query(issuersitesIssuerControllerContactPostQuery))).then(end),
    pathParts: issuersitesIssuerControllerContactPostPathParts,
  };
}

export const issuersitesIssuerControllerContactPostCodecs = { input: imported2_contactPostC, output: emptyObjectC, };

export const issuersitesIssuerControllerContactPost = (p: IssuersitesIssuerControllerContactPostParams) =>
  urlInterfaceIO("POST", issuersitesIssuerControllerContactPostRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesIssuerControllerContactPostCodecs);

const issuersitesIssuerControllerConfirmSitePasswordPath = lit("v2").then(lit("confirm-site-password"));
const issuersitesIssuerControllerConfirmSitePasswordPathParts = ["v2", "confirm-site-password"] as const;
const issuersitesIssuerControllerConfirmSitePasswordQuery = t.strict({});
export type IssuersitesIssuerControllerConfirmSitePasswordParams = Describe<typeof issuersitesIssuerControllerConfirmSitePasswordPath._A & typeof issuersitesIssuerControllerConfirmSitePasswordQuery._A>;

export function issuersitesIssuerControllerConfirmSitePasswordRoute(): { match: Match<IssuersitesIssuerControllerConfirmSitePasswordParams>; pathParts: typeof issuersitesIssuerControllerConfirmSitePasswordPathParts };
export function issuersitesIssuerControllerConfirmSitePasswordRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerConfirmSitePasswordParams>): { match: Match<IssuersitesIssuerControllerConfirmSitePasswordParams & t.TypeOf<A>>; pathParts: typeof issuersitesIssuerControllerConfirmSitePasswordPathParts };
export function issuersitesIssuerControllerConfirmSitePasswordRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerConfirmSitePasswordParams>) {
  return {
    match: (q ? issuersitesIssuerControllerConfirmSitePasswordPath.then(query(t.intersection([issuersitesIssuerControllerConfirmSitePasswordQuery, q]))) : issuersitesIssuerControllerConfirmSitePasswordPath.then(query(issuersitesIssuerControllerConfirmSitePasswordQuery))).then(end),
    pathParts: issuersitesIssuerControllerConfirmSitePasswordPathParts,
  };
}

export const issuersitesIssuerControllerConfirmSitePasswordCodecs = {
  input: imported0_confirmSitePasswordC,
  output: imported8_apiRedirectC,
};

export const issuersitesIssuerControllerConfirmSitePassword = () =>
  urlInterfaceIO("POST", issuersitesIssuerControllerConfirmSitePasswordRoute().match.formatter.run(Route.empty, {}).toString(true), issuersitesIssuerControllerConfirmSitePasswordCodecs);

const issuersitesRfpsControllerRfpsPath = str("issuerSlug").then(lit("rfps")).then(type("issuerId", issuerIdC));
const issuersitesRfpsControllerRfpsPathParts = ["issuerSlug", "rfps", "issuerId"] as const;
const issuersitesRfpsControllerRfpsQuery = t.strict({});
export type IssuersitesRfpsControllerRfpsParams = Describe<typeof issuersitesRfpsControllerRfpsPath._A & typeof issuersitesRfpsControllerRfpsQuery._A>;

export function issuersitesRfpsControllerRfpsRoute(): { match: Match<IssuersitesRfpsControllerRfpsParams>; pathParts: typeof issuersitesRfpsControllerRfpsPathParts };
export function issuersitesRfpsControllerRfpsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRfpsControllerRfpsParams>): { match: Match<IssuersitesRfpsControllerRfpsParams & t.TypeOf<A>>; pathParts: typeof issuersitesRfpsControllerRfpsPathParts };
export function issuersitesRfpsControllerRfpsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRfpsControllerRfpsParams>) {
  return {
    match: (q ? issuersitesRfpsControllerRfpsPath.then(query(t.intersection([issuersitesRfpsControllerRfpsQuery, q]))) : issuersitesRfpsControllerRfpsPath.then(query(issuersitesRfpsControllerRfpsQuery))).then(end),
    pathParts: issuersitesRfpsControllerRfpsPathParts,
  };
}

export const issuersitesRfpsControllerRfpsCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: imported0_rfpsSitesListC,
};

export const issuersitesRfpsControllerRfps = (p: IssuersitesRfpsControllerRfpsParams) =>
  urlInterfaceIO("GET", issuersitesRfpsControllerRfpsRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesRfpsControllerRfpsCodecs);

const issuersitesRfpsControllerRfpPath = str("issuerSlug").then(lit("rfp")).then(type("issuerId", issuerIdC));
const issuersitesRfpsControllerRfpPathParts = ["issuerSlug", "rfp", "issuerId"] as const;
const issuersitesRfpsControllerRfpQuery = t.exact(t.type({
  "rfpId": NumberFromString
}));
export type IssuersitesRfpsControllerRfpParams = Describe<typeof issuersitesRfpsControllerRfpPath._A & typeof issuersitesRfpsControllerRfpQuery._A>;

export function issuersitesRfpsControllerRfpRoute(): { match: Match<IssuersitesRfpsControllerRfpParams>; pathParts: typeof issuersitesRfpsControllerRfpPathParts };
export function issuersitesRfpsControllerRfpRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRfpsControllerRfpParams>): { match: Match<IssuersitesRfpsControllerRfpParams & t.TypeOf<A>>; pathParts: typeof issuersitesRfpsControllerRfpPathParts };
export function issuersitesRfpsControllerRfpRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRfpsControllerRfpParams>) {
  return {
    match: (q ? issuersitesRfpsControllerRfpPath.then(query(t.intersection([issuersitesRfpsControllerRfpQuery, q]))) : issuersitesRfpsControllerRfpPath.then(query(issuersitesRfpsControllerRfpQuery))).then(end),
    pathParts: issuersitesRfpsControllerRfpPathParts,
  };
}

export const issuersitesRfpsControllerRfpCodecs = {
  input: emptyObjectC,
  output: htmlC,
  ssrInput: either(imported2_bankRfpSitesDataC, imported0_directRfpSitesDataC),
};

export const issuersitesRfpsControllerRfp = (p: IssuersitesRfpsControllerRfpParams) =>
  urlInterfaceIO("GET", issuersitesRfpsControllerRfpRoute().match.formatter.run(Route.empty, p).toString(true), issuersitesRfpsControllerRfpCodecs);

const issuersitesIssuerControllerEnterPreviewPath = lit("preview").then(lit("on"));
const issuersitesIssuerControllerEnterPreviewPathParts = ["preview", "on"] as const;
const issuersitesIssuerControllerEnterPreviewQuery = t.strict({});
export type IssuersitesIssuerControllerEnterPreviewParams = Describe<typeof issuersitesIssuerControllerEnterPreviewPath._A & typeof issuersitesIssuerControllerEnterPreviewQuery._A>;

export function issuersitesIssuerControllerEnterPreviewRoute(): { match: Match<IssuersitesIssuerControllerEnterPreviewParams>; pathParts: typeof issuersitesIssuerControllerEnterPreviewPathParts };
export function issuersitesIssuerControllerEnterPreviewRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerEnterPreviewParams>): { match: Match<IssuersitesIssuerControllerEnterPreviewParams & t.TypeOf<A>>; pathParts: typeof issuersitesIssuerControllerEnterPreviewPathParts };
export function issuersitesIssuerControllerEnterPreviewRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerEnterPreviewParams>) {
  return {
    match: (q ? issuersitesIssuerControllerEnterPreviewPath.then(query(t.intersection([issuersitesIssuerControllerEnterPreviewQuery, q]))) : issuersitesIssuerControllerEnterPreviewPath.then(query(issuersitesIssuerControllerEnterPreviewQuery))).then(end),
    pathParts: issuersitesIssuerControllerEnterPreviewPathParts,
  };
}

export const issuersitesIssuerControllerEnterPreviewCodecs = { input: emptyObjectC, output: emptyObjectC, };

export const issuersitesIssuerControllerEnterPreview = () =>
  urlInterfaceIO("GET", issuersitesIssuerControllerEnterPreviewRoute().match.formatter.run(Route.empty, {}).toString(true), issuersitesIssuerControllerEnterPreviewCodecs);

const issuersitesIssuerControllerExitPreviewPath = lit("preview").then(lit("off"));
const issuersitesIssuerControllerExitPreviewPathParts = ["preview", "off"] as const;
const issuersitesIssuerControllerExitPreviewQuery = t.strict({});
export type IssuersitesIssuerControllerExitPreviewParams = Describe<typeof issuersitesIssuerControllerExitPreviewPath._A & typeof issuersitesIssuerControllerExitPreviewQuery._A>;

export function issuersitesIssuerControllerExitPreviewRoute(): { match: Match<IssuersitesIssuerControllerExitPreviewParams>; pathParts: typeof issuersitesIssuerControllerExitPreviewPathParts };
export function issuersitesIssuerControllerExitPreviewRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerExitPreviewParams>): { match: Match<IssuersitesIssuerControllerExitPreviewParams & t.TypeOf<A>>; pathParts: typeof issuersitesIssuerControllerExitPreviewPathParts };
export function issuersitesIssuerControllerExitPreviewRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerExitPreviewParams>) {
  return {
    match: (q ? issuersitesIssuerControllerExitPreviewPath.then(query(t.intersection([issuersitesIssuerControllerExitPreviewQuery, q]))) : issuersitesIssuerControllerExitPreviewPath.then(query(issuersitesIssuerControllerExitPreviewQuery))).then(end),
    pathParts: issuersitesIssuerControllerExitPreviewPathParts,
  };
}

export const issuersitesIssuerControllerExitPreviewCodecs = { input: emptyObjectC, output: emptyObjectC, };

export const issuersitesIssuerControllerExitPreview = () =>
  urlInterfaceIO("GET", issuersitesIssuerControllerExitPreviewRoute().match.formatter.run(Route.empty, {}).toString(true), issuersitesIssuerControllerExitPreviewCodecs);