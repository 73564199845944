import * as t from "io-ts";
import { LocalDateOrd } from "../../syntax/date/jodaSyntax";
import { LocalDateC } from "../../codecs/localDate";
import { either } from "io-ts-types/lib/either";
import { AnalyticsIntervalCU as imported1_AnalyticsIntervalCU } from "../domaintables/analyticsInterval";
import { WithStatusCU as imported291_WithStatusCU, WithStatusCU as imported292_WithStatusCU, withModInfoC as imported170_withModInfoC, withModInfoC as imported171_withModInfoC } from "./threadThrough";
import { withIssuerC as imported9_withIssuerC, withIssuerC as imported8_withIssuerC } from "./issuer";
import { bondOfferingC as imported18_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported51_rfpC } from "./rfpBase";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export const activityByTypeC = t.type({
  pageViews: t.number,
  documentDownloads: t.number,
  roadshowViews: t.number,
  infoRequests: t.number,
  emailOpens: t.number,
  emailClicks: t.number
});
export type ActivityByTypeC = typeof activityByTypeC;
export type ActivityByType = t.TypeOf<ActivityByTypeC>;


export const activityByActorC = t.type({
  byUser: readonlyMapFromEntries(t.number, numberOrd, activityByTypeC),
  byEmailContact: readonlyMapFromEntries(t.number, numberOrd, activityByTypeC)
});
export type ActivityByActorC = typeof activityByActorC;
export type ActivityByActor = t.TypeOf<ActivityByActorC>;


export const activityByDateC = t.type({
  byDate: readonlyMapFromEntries(LocalDateC, LocalDateOrd, activityByActorC)
});
export type ActivityByDateC = typeof activityByDateC;
export type ActivityByDate = t.TypeOf<ActivityByDateC>;


export class activityFCC<A1 extends t.Mixed, A2 extends t.Mixed>{ codec = (A1: A1, A2: A2) => t.type({
  interval: imported1_AnalyticsIntervalCU,
  byBond: readonlyMapFromEntries(t.number, numberOrd, activityByDateC),
  byRfp: readonlyMapFromEntries(t.number, numberOrd, activityByDateC),
  emailActivity: activityByDateC,
  bonds: t.readonlyArray(imported8_withIssuerC(imported291_WithStatusCU(imported170_withModInfoC(imported18_bondOfferingC)))),
  rfps: t.readonlyArray(imported9_withIssuerC(imported292_WithStatusCU(imported171_withModInfoC(imported51_rfpC)))),
  actors: t.readonlyArray(either(A1, A2))
})}
export const activityFC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2) => new activityFCC<A1,A2>().codec(A1,A2);
export type ActivityFC<A1 extends t.Mixed, A2 extends t.Mixed> = ReturnType<activityFCC<A1,A2>["codec"]>;
export type ActivityF<A1,A2> = t.TypeOf<ActivityFC<t.Type<A1>,t.Type<A2>>>;

