// This file was generated by postcss-transpiler. Do not edit by hand.

export const card = {
  ".card-grid": {
    css: ".card-grid",
    html: "card-grid"
  },
  ".related-issuer-grid": {
    css: ".related-issuer-grid",
    html: "related-issuer-grid"
  },
  ".card": {
    css: ".card",
    html: "card",
    ".card-body": {
      css: ".card-body",
      html: "card-body",
      ":first-child": {
        css: ":first-child",
        html: "first-child"
      },
      ":last-child": {
        css: ":last-child",
        html: "last-child"
      },
      ".pagelink": {
        css: ".pagelink",
        html: "pagelink"
      },
      "p": {
        css: "p",
        html: "p"
      },
      "[class*=\" truncate-\"]": {
        css: "[class*=\" truncate-\"]",
        html: "[class*=\" truncate-\"]",
        ":last-child": {
          css: ":last-child",
          html: "last-child"
        }
      },
      "[class^=\"truncate-\"]": {
        css: "[class^=\"truncate-\"]",
        html: "[class^=\"truncate-\"]",
        ":last-child": {
          css: ":last-child",
          html: "last-child"
        }
      }
    },
    ".data-vertical": {
      css: ".data-vertical",
      html: "data-vertical",
      ".card-body": {
        css: ".card-body",
        html: "card-body",
        "dd": {
          css: "dd",
          html: "dd"
        }
      }
    },
    ".card-footer": {
      css: ".card-footer",
      html: "card-footer"
    }
  },
  ".data-vertical": {
    css: ".data-vertical",
    html: "data-vertical",
    ".card": {
      css: ".card",
      html: "card",
      ".card-body": {
        css: ".card-body",
        html: "card-body",
        "dd": {
          css: "dd",
          html: "dd"
        }
      }
    }
  },
  ".card-border": {
    css: ".card-border",
    html: "card-border"
  },
  ".card-hover": {
    css: ".card-hover",
    html: "card-hover",
    attrs: {
      ".card-hover-disabled": {
        css: ".card-hover-disabled",
        html: "card-hover-disabled"
      }
    },
    ".card-link": {
      css: ".card-link",
      html: "card-link"
    },
    "[data-href]": {
      css: "[data-href]",
      html: "[data-href]"
    },
    "[data-toggle]": {
      css: "[data-toggle]",
      html: "[data-toggle]"
    }
  },
  ".card-media": {
    css: ".card-media",
    html: "card-media",
    "img": {
      css: "img",
      html: "img"
    },
    ".card-body": {
      css: ".card-body",
      html: "card-body"
    },
    ".pagelink": {
      css: ".pagelink",
      html: "pagelink"
    }
  },
  ".card-img-small": {
    css: ".card-img-small",
    html: "card-img-small",
    ".card-body": {
      css: ".card-body",
      html: "card-body"
    },
    "h5": {
      css: "h5",
      html: "h5"
    },
    ".background-img": {
      css: ".background-img",
      html: "background-img"
    },
    "img": {
      css: "img",
      html: "img"
    }
  },
  ".card-bond-sale": {
    css: ".card-bond-sale",
    html: "card-bond-sale",
    ".pagelink": {
      css: ".pagelink",
      html: "pagelink"
    },
    ".data-horizontal": {
      css: ".data-horizontal",
      html: "data-horizontal"
    },
    ".deal-issuer-logo": {
      css: ".deal-issuer-logo",
      html: "deal-issuer-logo"
    },
    ".series-name": {
      css: ".series-name",
      html: "series-name"
    },
    ".series-wrapper": {
      css: ".series-wrapper",
      html: "series-wrapper"
    },
    ".actions-links": {
      css: ".actions-links",
      html: "actions-links"
    }
  },
  ".card-content-item": {
    css: ".card-content-item",
    html: "card-content-item",
    ".pagelink": {
      css: ".pagelink",
      html: "pagelink"
    },
    ".data-horizontal": {
      css: ".data-horizontal",
      html: "data-horizontal"
    },
    ".deal-issuer-logo": {
      css: ".deal-issuer-logo",
      html: "deal-issuer-logo"
    },
    ".series-name": {
      css: ".series-name",
      html: "series-name"
    },
    ".series-wrapper": {
      css: ".series-wrapper",
      html: "series-wrapper"
    },
    ".actions-links": {
      css: ".actions-links",
      html: "actions-links"
    },
    ".badge": {
      css: ".badge",
      html: "badge"
    }
  },
  ".card-related-issuer": {
    css: ".card-related-issuer",
    html: "card-related-issuer",
    attrs: {
      ".current": {
        css: ".current",
        html: "current"
      },
      ".disabled": {
        css: ".disabled",
        html: "disabled"
      }
    },
    ".related-issuer": {
      css: ".related-issuer",
      html: "related-issuer",
      attrs: {
        ".long": {
          css: ".long",
          html: "long"
        }
      },
      "svg": {
        css: "svg",
        html: "svg"
      }
    }
  },
  ".card-category-grid": {
    css: ".card-category-grid",
    html: "card-category-grid",
    ".card": {
      css: ".card",
      html: "card",
      ".card-body": {
        css: ".card-body",
        html: "card-body"
      }
    }
  },
  ".aspect-ratio-container": {
    css: ".aspect-ratio-container",
    html: "aspect-ratio-container",
    ".flex-centered": {
      css: ".flex-centered",
      html: "flex-centered",
      "img": {
        css: "img",
        html: "img"
      }
    },
    ".aspect-ratio-content": {
      css: ".aspect-ratio-content",
      html: "aspect-ratio-content"
    }
  },
  ".card-title": {
    css: ".card-title",
    html: "card-title"
  },
  ".card-roadshow": {
    css: ".card-roadshow",
    html: "card-roadshow",
    ".logo-slide": {
      css: ".logo-slide",
      html: "logo-slide"
    }
  },
  ".card-new-issuer": {
    css: ".card-new-issuer",
    html: "card-new-issuer",
    ".badge": {
      css: ".badge",
      html: "badge",
      "i": {
        css: "i",
        html: "i"
      }
    },
    ".image": {
      css: ".image",
      html: "image",
      "img": {
        css: "img",
        html: "img"
      }
    }
  },
  ".data-point-card": {
    css: ".data-point-card",
    html: "data-point-card",
    ".badge": {
      css: ".badge",
      html: "badge"
    },
    ".card-body": {
      css: ".card-body",
      html: "card-body",
      ".facts-with-label": {
        css: ".facts-with-label",
        html: "facts-with-label",
        ".data-horizontal": {
          css: ".data-horizontal",
          html: "data-horizontal"
        }
      }
    },
    ".card-footer": {
      css: ".card-footer",
      html: "card-footer",
      ".data-horizontal": {
        css: ".data-horizontal",
        html: "data-horizontal"
      },
      ".data-point": {
        css: ".data-point",
        html: "data-point",
        "dt": {
          css: "dt",
          html: "dt"
        },
        ".analytics-metric": {
          css: ".analytics-metric",
          html: "analytics-metric"
        }
      }
    }
  }
};
