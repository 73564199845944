import type { PropsWithChildren, ReactElement } from "react";

import type { MarkdownTag } from "@scripts/codecs/markdown";
import { O, pipe } from "@scripts/fp-ts";
import { AnchorUnsafe } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Grid7525, GridLeft } from "@scripts/react/components/layout/PageGrid";
import { Markdown } from "@scripts/react/components/Markdown";
import { klass, klassConditionalArr, type KlassProp, klassPropO } from "@scripts/react/util/classnames";
import { fromNullableOrOption } from "@scripts/util/fromNullableOrOption";

type HeaderComponent = ReactElement;
type HeaderComponentO = O.Option<HeaderComponent>;
type SideBarContent = { sidebarContent: ReactElement };

const HeaderComponent = (props: { headerComponent: HeaderComponentO, mobileVersion: boolean }) => pipe(
  props.headerComponent,
  mapOrEmpty((header) => <div {...klassConditionalArr(["d-md-none", "d-none-until-md"], [])([!props.mobileVersion, props.mobileVersion])}>{header}</div>)
);

export const DirectSitesPageLayoutBase = (props: PropsWithChildren<{
  headerComponent: HeaderComponentO;
  rightGridKlass?: KlassProp;
  rightGridLast?: boolean;
  appendRightGrid?: true;
} & SideBarContent>) => <div {...klass("container")}>
    <Grid7525>
      <HeaderComponent headerComponent={props.headerComponent} mobileVersion={false} />
      {!props.appendRightGrid && <div {...klassPropO(["grid-right"])(props.rightGridKlass)}>
        {props.sidebarContent}
      </div>}
      <GridLeft>
        <HeaderComponent headerComponent={props.headerComponent} mobileVersion={true} />
        {props.children}
      </GridLeft>
      {props.appendRightGrid && <div {...klassPropO(["grid-right"])(props.rightGridKlass)}>
        {props.sidebarContent}
      </div>}
    </Grid7525>
  </div>;

export const DirectSitesPageLayoutJumpLinks = (props: PropsWithChildren<{
  headerComponent: HeaderComponent;
} & SideBarContent>) =>
  <DirectSitesPageLayoutBase
    headerComponent={O.some(props.headerComponent)}
    sidebarContent={props.sidebarContent}
    rightGridKlass={O.some("jump-links-sticky")}
  >
    {props.children}
  </DirectSitesPageLayoutBase>;

export const DirectSitesPageDescriptionLayout = (props: PropsWithChildren<{
  description: MarkdownTag;
  relatedPage?: O.Option<{ text: string, url: string }>;
} & SideBarContent>) => {
  const relPageO = fromNullableOrOption(props.relatedPage);
  const marginClasses = O.fold(() => "mb-1 mb-md-2", () => "mb-1")(relPageO);
  const relPageLink = pipe(
    relPageO,
    mapOrEmpty(({ text, url }) => <div>
      <AnchorUnsafe
        target="_self"
        href={url}
        title={text}
        klasses="pagelink mt-0 mb-1 d-inline-block"
      />
    </div>),
  );

  return <DirectSitesPageLayoutJumpLinks
    {...props}
    headerComponent={<>
      <div {...klass(`large ${marginClasses}`)}>
        <Markdown content={props.description} klasses={["last-child-mb-0"]} />
      </div>
      {relPageLink}
    </>}
  />;
};
