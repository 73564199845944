import { type ReactNode, useEffect } from "react";
import { withProfiler } from "@sentry/react";
import { pipe } from "fp-ts/lib/function";
import type { IssuerSitesPageMeta, IssuerSitesPageTagU } from "ssr/issuersites/assets/scripts/router";

import type { ContentSubGroup } from "@scripts/analytics";
import type { BLConfigWithLog } from "@scripts/bondlink";
import { O, RNEA } from "@scripts/fp-ts";
import type { Bank } from "@scripts/generated/models/bank";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { useConfig } from "@scripts/react/context/Config";
import { useModalStableO } from "@scripts/react/util/useModal";

import { useBootstrap, useDraftPreview } from "@scripts-ssr/client/bootstrap";

import { useIssuerSitesDispatch, useIssuerSitesSelector } from "../state/store";
import { Chrome as DirectIssuerChrome } from "./Chrome";
import { DueDisclaimerModal, type DueDisclaimers } from "./disclaimer/DueDisclaimerModal";

export type PageProps = {
  children: ReactNode;
  title: string;
  url: string;
  routeMeta: Omit<IssuerSitesPageMeta, "render">;
};

const pageTagToAnalyticsContentGroup = (pageTag: IssuerSitesPageTagU, config: BLConfigWithLog, bank: O.Option<Bank>): ContentSubGroup => {
  switch (pageTag) {
    case "rfp":
    case "rfps":
      return "RFP Page";
    case "offering-page":
      return O.isSome(bank) ? "BLP Offering Page" : "Direct Offering Page";
    case "projects":
    case "team":
    case "bond-program":
    case "bonds":
    case "home":
    case "news-and-events":
    case "programs":
    case "esg-program":
    case "ratings":
    case "faq":
    case "about":
    case "downloads":
    case "archived-documents":
    case "links":
    case "irma-letter":
    case "view-file":
    case "document-categories":
    case "roadshow-player":
      return "IR Page";
    case "NotFound":
      return "Not Found";
  }
  config.exhaustive(pageTag);
};

const Page = (props: PageProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const dueDisclaimers = useIssuerSitesSelector("dueDisclaimer");
  const dispatch = useIssuerSitesDispatch();
  const config = useConfig();
  const bank = useIssuerSitesSelector("bank");
  const url = props.routeMeta.url();
  const title = props.routeMeta.title(pages, issuer);

  const [dueDisclaimerModalOpen, dueDisclaimersO, openDueDisclaimerModal, closeDueDisclaimerModal] =
    useModalStableO<DueDisclaimers>("Due Disclaimer Modal");

  useBootstrap([title, url], dispatch, "Issuer Sites", pageTagToAnalyticsContentGroup(props.routeMeta._tag, config, bank));
  useDraftPreview();

  useEffect(() => {
    pipe(
      RNEA.fromReadonlyArray(dueDisclaimers),
      O.map((openDueDisclaimerModal)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {props.routeMeta.renderChrome
        ? <DirectIssuerChrome routeMeta={props.routeMeta}>
          {props.children}
        </DirectIssuerChrome>
        : props.children
      }
      {pipe(
        dueDisclaimersO,
        mapOrEmpty((dds) => <DueDisclaimerModal
          modalOpen={dueDisclaimerModalOpen}
          dismissAction={closeDueDisclaimerModal}
          dueDisclaimers={dds}
          dispatch={dispatch}
        />
        )
      )}
    </>
  );
};

export const PageWithProfiler = withProfiler(Page);
