import { WithStatusCU as imported341_WithStatusCU, WithStatusCU as imported339_WithStatusCU, WithStatusCU as imported342_WithStatusCU, WithStatusCU as imported346_WithStatusCU, hasManyLinkC as imported158_hasManyLinkC, hasManyLinkC as imported164_hasManyLinkC, WithStatusCU as imported340_WithStatusCU, hasManyLinkC as imported165_hasManyLinkC, hasManyLinkC as imported159_hasManyLinkC, hasManyLinkC as imported166_hasManyLinkC, WithStatusCU as imported337_WithStatusCU, WithStatusCU as imported344_WithStatusCU, hasManyLinkC as imported167_hasManyLinkC, hasManyLinkC as imported168_hasManyLinkC, hasManyLinkC as imported161_hasManyLinkC, WithStatusCU as imported347_WithStatusCU, WithStatusCU as imported345_WithStatusCU, WithStatusCU as imported338_WithStatusCU, hasManyLinkC as imported163_hasManyLinkC, hasManyLinkC as imported162_hasManyLinkC, hasManyLinkC as imported160_hasManyLinkC, WithStatusCU as imported336_WithStatusCU, hasManyLinkC as imported157_hasManyLinkC, WithStatusCU as imported343_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { externalLinkC as imported22_externalLinkC, externalLinkC as imported23_externalLinkC } from "./externalLink";
import { issuerNewsC as imported20_issuerNewsC } from "./issuerNewsBase";
import { imageUploadResponseC as imported8_imageUploadResponseC, mediaC as imported75_mediaC, mediaC as imported78_mediaC, mediaUploadResponseWithCategoryC as imported12_mediaUploadResponseWithCategoryC, mediaC as imported77_mediaC, mediaC as imported76_mediaC } from "./media";
import { taggedContentC as imported172_taggedContentC, taggedContentC as imported175_taggedContentC, taggedContentC as imported174_taggedContentC, taggedContentC as imported173_taggedContentC } from "./taggedContent";
import { quickFactC as imported11_quickFactC, quickFactC as imported12_quickFactC, quickFactC as imported13_quickFactC } from "./quickFact";
import { bondProgramC as imported19_bondProgramC } from "./bondProgramBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { eventC as imported18_eventC } from "./event";
import { sortOrderC as imported32_sortOrderC, sortOrderC as imported33_sortOrderC, sortOrderC as imported34_sortOrderC, sortOrderC as imported35_sortOrderC } from "./sortOrder";
import { postAttachedC as imported23_postAttachedC, postRelatedC as imported59_postRelatedC, relatedRfpLinkC as imported10_relatedRfpLinkC, relatedNewsLinkC as imported20_relatedNewsLinkC, relatedOfferingLinkC as imported10_relatedOfferingLinkC, relatedPhotoLinkC as imported7_relatedPhotoLinkC, relatedExternalLinkLinkC as imported12_relatedExternalLinkLinkC, relatedQuickFactLinkC as imported5_relatedQuickFactLinkC, relatedDocumentLinkC as imported32_relatedDocumentLinkC, postAttachedC as imported22_postAttachedC, relatedDocumentLinkC as imported33_relatedDocumentLinkC, postRelatedC as imported57_postRelatedC, relatedQuickFactLinkC as imported4_relatedQuickFactLinkC, relatedProgramLinkC as imported8_relatedProgramLinkC, postRelatedC as imported56_postRelatedC, isAttachedC as imported20_isAttachedC, relatedProgramLinkC as imported9_relatedProgramLinkC, relatedPhotoLinkC as imported6_relatedPhotoLinkC, relatedNewsLinkC as imported21_relatedNewsLinkC, postRelatedAndAttachedC as imported12_postRelatedAndAttachedC, relatedEventLinkC as imported21_relatedEventLinkC, relatedPhotoLinkC as imported5_relatedPhotoLinkC, relatedRfpLinkC as imported11_relatedRfpLinkC, postRelatedC as imported55_postRelatedC, relatedOfferingLinkC as imported11_relatedOfferingLinkC, postRelatedC as imported58_postRelatedC, postAttachedC as imported24_postAttachedC, relatedEventLinkC as imported20_relatedEventLinkC } from "./relatedContent";
import { markdownC } from "../../codecs/markdown";
import { bondOfferingC as imported23_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported55_rfpC } from "./rfpBase";

export const projectC = t.type({
  projectTitle: t.string,
  projectText: markdownC,
  projectOrder: t.number
});
export type ProjectC = typeof projectC;
export type Project = t.TypeOf<ProjectC>;


export const projectPostRelatedContentC = t.type({
  offerings: imported55_postRelatedC(imported10_relatedOfferingLinkC),
  news: imported56_postRelatedC(imported20_relatedNewsLinkC),
  events: imported57_postRelatedC(imported20_relatedEventLinkC),
  quickFacts: imported22_postAttachedC(imported11_quickFactC, imported32_sortOrderC),
  photos: imported23_postAttachedC(imported8_imageUploadResponseC, imported33_sortOrderC),
  documents: imported12_postRelatedAndAttachedC(imported32_relatedDocumentLinkC, imported12_mediaUploadResponseWithCategoryC, imported34_sortOrderC),
  externalLinks: imported24_postAttachedC(imported22_externalLinkC, imported35_sortOrderC),
  rfps: imported58_postRelatedC(imported10_relatedRfpLinkC),
  programs: imported59_postRelatedC(imported8_relatedProgramLinkC)
});
export type ProjectPostRelatedContentC = typeof projectPostRelatedContentC;
export type ProjectPostRelatedContent = t.TypeOf<ProjectPostRelatedContentC>;


export const projectPostBodyC = t.type({
  id: optionFromNullable(t.number),
  projectTitle: t.string,
  projectText: markdownC,
  projectOrder: t.number,
  esgRelated: t.boolean,
  relatedContent: projectPostRelatedContentC
});
export type ProjectPostBodyC = typeof projectPostBodyC;
export type ProjectPostBody = t.TypeOf<ProjectPostBodyC>;


export const projectRelatedContentC = t.type({
  offerings: t.readonlyArray(imported157_hasManyLinkC(imported336_WithStatusCU(imported172_taggedContentC(imported23_bondOfferingC)), imported11_relatedOfferingLinkC)),
  news: t.readonlyArray(imported158_hasManyLinkC(imported337_WithStatusCU(imported173_taggedContentC(imported20_issuerNewsC)), imported21_relatedNewsLinkC)),
  events: t.readonlyArray(imported159_hasManyLinkC(imported338_WithStatusCU(imported18_eventC), imported21_relatedEventLinkC)),
  quickFacts: t.readonlyArray(imported160_hasManyLinkC(imported339_WithStatusCU(imported12_quickFactC), imported4_relatedQuickFactLinkC)),
  photos: t.readonlyArray(imported161_hasManyLinkC(imported340_WithStatusCU(imported75_mediaC), imported5_relatedPhotoLinkC)),
  documents: t.readonlyArray(imported162_hasManyLinkC(imported341_WithStatusCU(imported174_taggedContentC(imported76_mediaC)), imported20_isAttachedC(imported33_relatedDocumentLinkC))),
  externalLinks: t.readonlyArray(imported163_hasManyLinkC(imported342_WithStatusCU(imported23_externalLinkC), imported12_relatedExternalLinkLinkC)),
  rfps: t.readonlyArray(imported164_hasManyLinkC(imported343_WithStatusCU(imported175_taggedContentC(imported55_rfpC)), imported11_relatedRfpLinkC)),
  programs: t.readonlyArray(imported165_hasManyLinkC(imported344_WithStatusCU(imported19_bondProgramC), imported9_relatedProgramLinkC))
});
export type ProjectRelatedContentC = typeof projectRelatedContentC;
export type ProjectRelatedContent = t.TypeOf<ProjectRelatedContentC>;


export const projectWithRelatedContentC = t.type({
  project: projectC,
  relatedContent: projectRelatedContentC
});
export type ProjectWithRelatedContentC = typeof projectWithRelatedContentC;
export type ProjectWithRelatedContent = t.TypeOf<ProjectWithRelatedContentC>;


export const projectWithPhotosC = t.type({
  project: projectC,
  photos: t.readonlyArray(imported166_hasManyLinkC(imported345_WithStatusCU(imported77_mediaC), imported6_relatedPhotoLinkC))
});
export type ProjectWithPhotosC = typeof projectWithPhotosC;
export type ProjectWithPhotos = t.TypeOf<ProjectWithPhotosC>;


export const projectWithPhotosAndQuickFactsC = t.type({
  project: projectC,
  photos: t.readonlyArray(imported167_hasManyLinkC(imported346_WithStatusCU(imported78_mediaC), imported7_relatedPhotoLinkC)),
  quickFacts: t.readonlyArray(imported168_hasManyLinkC(imported347_WithStatusCU(imported13_quickFactC), imported5_relatedQuickFactLinkC))
});
export type ProjectWithPhotosAndQuickFactsC = typeof projectWithPhotosAndQuickFactsC;
export type ProjectWithPhotosAndQuickFacts = t.TypeOf<ProjectWithPhotosAndQuickFactsC>;


