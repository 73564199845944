import { taggedContentC as imported195_taggedContentC, taggedContentC as imported196_taggedContentC } from "./taggedContent";
import { bondOfferingWithRelatedContentC as imported16_bondOfferingWithRelatedContentC, bondOfferingWithRelatedContentC as imported17_bondOfferingWithRelatedContentC } from "./bondOffering";
import { WithStatusCU as imported363_WithStatusCU, WithStatusCU as imported364_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";

export const portalOfferingsC = t.type({
  active: t.readonlyArray(imported363_WithStatusCU(imported195_taggedContentC(imported16_bondOfferingWithRelatedContentC))),
  archived: t.readonlyArray(imported364_WithStatusCU(imported196_taggedContentC(imported17_bondOfferingWithRelatedContentC)))
});
export type PortalOfferingsC = typeof portalOfferingsC;
export type PortalOfferings = t.TypeOf<PortalOfferingsC>;


