import { withIdC as imported280_withIdC, withIdC as imported281_withIdC, withModInfoC as imported229_withModInfoC, withModInfoC as imported228_withModInfoC } from "./threadThrough";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { rfpC as imported59_rfpC } from "./rfpBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { bondOfferingDetailsC as imported41_bondOfferingDetailsC } from "./bondOffering";
import { actorC as imported9_actorC } from "./actor";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export const activitiesC = t.type({
  visitedSite: t.number,
  viewedOffering: readonlyMapFromEntries(t.number, numberOrd, t.number),
  viewedRfp: readonlyMapFromEntries(t.number, numberOrd, t.number),
  viewedRoadshow: t.number,
  downloadedDocument: t.number,
  viewedDocument: t.number,
  submittedContactForm: t.number,
  subscribedToNotifications: t.number,
  subscribedToEvents: t.number,
  subscribedToBonds: t.number,
  subscribedToRfps: t.number,
  subscribedToDocs: t.number,
  subscribedToNews: t.number,
  subscribedToRatings: t.number,
  receivedEmail: t.number,
  openedEmail: t.number,
  clickedEmail: t.number,
  clickedLink: t.number,
  didAnythingOtherThanReceivedEmail: t.number
});
export type ActivitiesC = typeof activitiesC;
export type Activities = t.TypeOf<ActivitiesC>;


export const investorActivityRowC = t.type({
  actor: imported9_actorC,
  activities: activitiesC,
  lastActive: LocalDateTimeFromIsoStringC,
  lastContact: optionFromNullable(LocalDateTimeFromIsoStringC)
});
export type InvestorActivityRowC = typeof investorActivityRowC;
export type InvestorActivityRow = t.TypeOf<InvestorActivityRowC>;


export const investorActivityTableC = t.type({
  investorActivityRows: t.readonlyArray(investorActivityRowC),
  offerings: readonlyMapFromEntries(t.number, numberOrd, imported280_withIdC(imported228_withModInfoC(imported41_bondOfferingDetailsC))),
  rfps: readonlyMapFromEntries(t.number, numberOrd, imported281_withIdC(imported229_withModInfoC(imported59_rfpC)))
});
export type InvestorActivityTableC = typeof investorActivityTableC;
export type InvestorActivityTable = t.TypeOf<InvestorActivityTableC>;


