import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";

export const contentNoteC = t.type({
  content: t.string
});
export type ContentNoteC = typeof contentNoteC;
export type ContentNote = t.TypeOf<ContentNoteC>;


export const offeringContentNotePostC = t.type({
  content: t.string,
  offeringId: t.number,
  id: optionFromNullable(t.number)
});
export type OfferingContentNotePostC = typeof offeringContentNotePostC;
export type OfferingContentNotePost = t.TypeOf<OfferingContentNotePostC>;


export const rfpContentNotePostC = t.type({
  content: t.string,
  rfpId: t.number,
  id: optionFromNullable(t.number)
});
export type RfpContentNotePostC = typeof rfpContentNotePostC;
export type RfpContentNotePost = t.TypeOf<RfpContentNotePostC>;


