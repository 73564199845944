import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { PostEmailRecipientCU as imported2_PostEmailRecipientCU, PostEmailRecipientCU as imported3_PostEmailRecipientCU } from "./email";
import { htmlC } from "../../codecs/html";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { mediaUploadResponseC as imported20_mediaUploadResponseC } from "./media";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { userIdC as imported5_userIdC, emailContactIdC as imported5_emailContactIdC, ActorIdCU as imported3_ActorIdCU } from "./actor";
import { markdownC } from "../../codecs/markdown";

export const issuerContactEmailPostBodyC = t.type({
  recipients: readonlyNonEmptyArrayC(imported2_PostEmailRecipientCU),
  subject: t.string,
  message: markdownC,
  ccs: optionFromNullable(readonlyNonEmptyArrayC(t.string)),
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
});
export type IssuerContactEmailPostBodyC = typeof issuerContactEmailPostBodyC;
export type IssuerContactEmailPostBody = t.TypeOf<IssuerContactEmailPostBodyC>;


export const bankContactEmailPostBodyC = t.type({
  recipients: readonlyNonEmptyArrayC(imported3_PostEmailRecipientCU),
  subject: t.string,
  message: markdownC,
  ccs: optionFromNullable(readonlyNonEmptyArrayC(t.string)),
  associatedIssuerId: optionFromNullable(t.number),
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
});
export type BankContactEmailPostBodyC = typeof bankContactEmailPostBodyC;
export type BankContactEmailPostBody = t.TypeOf<BankContactEmailPostBodyC>;


export const contactFormSubmissionC = t.type({
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  firstName: t.string,
  lastName: t.string,
  subject: t.string,
  body: t.string,
  company: optionFromNullable(t.string),
  title: optionFromNullable(t.string),
  userId: optionFromNullable(t.number),
  sent: LocalDateTimeFromIsoStringC
});
export type ContactFormSubmissionC = typeof contactFormSubmissionC;
export type ContactFormSubmission = t.TypeOf<ContactFormSubmissionC>;


export const emailUserRecipientC = t.type({
  _tag: t.literal(`EmailUserRecipient`),
  id: imported5_userIdC,
  name: t.string
});
export type EmailUserRecipientC = typeof emailUserRecipientC;
export type EmailUserRecipient = t.TypeOf<EmailUserRecipientC>;


export const emailContactRecipientC = t.type({
  _tag: t.literal(`EmailContactRecipient`),
  id: imported5_emailContactIdC
});
export type EmailContactRecipientC = typeof emailContactRecipientC;
export type EmailContactRecipient = t.TypeOf<EmailContactRecipientC>;


export const allEmailRecipientC = [emailUserRecipientC, emailContactRecipientC] as const;
export const allEmailRecipientNames = [`EmailUserRecipient`, `EmailContactRecipient`] as const;
export type EmailRecipientName = (typeof allEmailRecipientNames)[number];

export const EmailRecipientCU = t.union([emailUserRecipientC, emailContactRecipientC]);
export type EmailRecipientCU = typeof EmailRecipientCU;
export type EmailRecipientU = t.TypeOf<EmailRecipientCU>;

export type EmailRecipientMap<A> = { [K in EmailRecipientName]: A };


export const emailInfoC = t.type({
  recipient: optionFromNullable(EmailRecipientCU),
  emailAddress: t.string,
  subject: t.string,
  hash: t.string,
  created: LocalDateTimeFromIsoStringC
});
export type EmailInfoC = typeof emailInfoC;
export type EmailInfo = t.TypeOf<EmailInfoC>;


export const previewEmailC = t.type({
  preview: htmlC
});
export type PreviewEmailC = typeof previewEmailC;
export type PreviewEmail = t.TypeOf<PreviewEmailC>;


export const bulkUploadEmailsPostC = t.type({
  dryRun: t.boolean,
  uploadResponse: imported20_mediaUploadResponseC
});
export type BulkUploadEmailsPostC = typeof bulkUploadEmailsPostC;
export type BulkUploadEmailsPost = t.TypeOf<BulkUploadEmailsPostC>;


export const bulkUploadEmailsRespC = t.type({
  count: t.number,
  actorIds: t.readonlyArray(imported3_ActorIdCU),
  emails: t.readonlyArray(t.string)
});
export type BulkUploadEmailsRespC = typeof bulkUploadEmailsRespC;
export type BulkUploadEmailsResp = t.TypeOf<BulkUploadEmailsRespC>;


export const sentEmailReportC = t.type({
  received: t.readonlyArray(emailInfoC),
  suppressed: t.readonlyArray(emailInfoC)
});
export type SentEmailReportC = typeof sentEmailReportC;
export type SentEmailReport = t.TypeOf<SentEmailReportC>;


