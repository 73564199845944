import { type ReactNode, useCallback, useState } from "react";

import type { Markdown as MarkdownType } from "@scripts/codecs/markdown";
import { E, O, pipe, RNEA } from "@scripts/fp-ts";
import { documents } from "@scripts/generated/domaintables/featureFlags";
import { ET } from "@scripts/generated/domaintables/timeZones";
import type { BankRfpSitesData, GenRfpWithRelatedContent, RfpSitesRelatedContent } from "@scripts/generated/models/rfp";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as sitesRouter from "@scripts/generated/routers/sitesRouter";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { RfpFacts } from "@scripts/react/actions/rfp-pages/RfpSummary";
import { constEmpty, mapOrEmpty } from "@scripts/react/components/Empty";
import { RFPQuestionsAndAnswers } from "@scripts/react/components/Faq";
import { BidSubmissionModalCallout } from "@scripts/react/components/form/submit-bid/BidSubmissionModal";
import { Grid } from "@scripts/react/components/layout/Grid";
import { MainGridLeft, SideBarGridRightWithBorder } from "@scripts/react/components/layout/PageGrid";
import { AccentDividerSection, DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { Participants } from "@scripts/react/components/offering-pages/OfferingParticipants";
import { makeRelatedContentDataO, makeRoadshowDataO, parseRoadshows, ProgramWithRatingRelatedContent, RoadshowsSection, SummaryRelatedContent } from "@scripts/react/components/offering-pages/RelatedContent";
import { WinningBidSectionSites } from "@scripts/react/components/rfp-pages/WinningBidOverview";
import { SidebarAboutSection } from "@scripts/react/components/SidebarAbout";
import { ProjectSidebarSection, TeamSidebarSection } from "@scripts/react/components/SidebarModalLinks";
import { RfpBadges, useRfpBidSubmissionState } from "@scripts/react/rfp/rfpBadge";
import { klass } from "@scripts/react/util/classnames";
import { humanDateFull, humanDateFullAtTime } from "@scripts/syntax/date/joda";
import { dateWithOptionalTimeToString } from "@scripts/syntax/dateWithOptionalTime";
import { isDrafted, modifyWithStatus } from "@scripts/syntax/threadThrough";

import { useRfpSubscribe } from "../../api/watchlist";
import { BankIssuerChrome } from "../../components/Chrome";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { HeaderWithSubscription } from "../../components/offering-pages/Header";
import { useIssuerSitesSelector } from "../../state/store";
import { useOnSubscribe } from "../../syntax/onSubscribe";
import { bidSubmissionLens } from "../../syntax/rfp";
import { RfpQuestionsAndAnswersCallout } from "../direct-rfp/RfpRelatedContent";

type BankRfp = Subscribed<WithStatusU<TaggedContent<GenRfpWithRelatedContent<RfpSitesRelatedContent>>>>;

export const BankRfpPage = (props: BankRfpSitesData) => {
  const [rfp, setRfp] = useState(props.rfp);
  const iffs = useIssuerSitesSelector("iffs");
  const issuer = useIssuerSitesSelector("issuer");
  const makeOnSubscribe = useRfpSubscribe();
  const user = useIssuerSitesSelector("user");

  const [isSubscribed, onSubscribe, setIsSubscribed] = useOnSubscribe(rfp.subscribed, makeOnSubscribe, rfp.data.data.id);

  const setSubscribedToRfp = useCallback(() => {
    if (O.isSome(user)) {
      setIsSubscribed(true);
    }
  }, [setIsSubscribed, user]);

  const bidSubmissionState = useRfpBidSubmissionState(
    rfp.data.data.id,
    rfp.data.data.record.data.rfp,
    rfp.data.data.record.data.relatedContent.bidSubmissionTemplate,
    rfp.data.data.record.data.relatedContent.bidSubmission,
  );
  const bidsDueBy = bidSubmissionState.isBiddingOpen
    ? pipe(
      rfp.data.data.record.data.rfp.bidsDue,
      O.fold(
        (): ReactNode => "The due date for bids has not yet been scheduled.",
        E.fold(
          (d): ReactNode => <>Bids are due by <strong>{dateWithOptionalTimeToString(humanDateFull)(d)}</strong>.</>,
          ongoing => `Bids are accepted on an ${ongoing.name} basis.`
        ),
      )
    )
    : "Bidding is now closed.";
  const submittedBidText = pipe(
    rfp.data.data.record.data.relatedContent.bidSubmission,
    O.fold(
      () => O.fromPredicate(() => bidSubmissionState.isBidInProgress)(
        "You currently have a bid submission in progress that has not yet been completed."
      ),
      s => O.some(
        `You submitted a bid on ${humanDateFullAtTime(O.getOrElse(() => s.data.record.created)(s.data.record.published))} ${ET.name}.`
        + (
          bidSubmissionState.isBiddingOpen
            ? " You can edit and re-submit this bid as long as"
            + pipe(
              rfp.data.data.record.data.rfp.bidsDue,
              O.fold(
                () => " bids are still being accepted.",
                E.fold(
                  () => " it is before the submission deadline.",
                  () => " bids are still being accepted.",
                ),
              ),
            )
            + " You can also review this bid at any time."
            : ""
        )
      )
    )
  );

  const roadshowDataO = makeRoadshowDataO(iffs, parseRoadshows(rfp.data.data.record.data.relatedContent.roadShows));
  const documentsDataO = makeRelatedContentDataO(documents, iffs, rfp.data.data.record.data.relatedContent.documents.map(_ => _.data));
  const linksDataO = RNEA.fromReadonlyArray(rfp.data.data.record.data.relatedContent.externalLinks);

  const loginRedirect = sitesRouter.issuersitesRfpsControllerRfp({
    issuerSlug: issuer.slug,
    issuerId: issuer.id,
    rfpId: rfp.data.data.id,
  }).url;

  return (
    <BankIssuerChrome bank={props.bank} loginRequired={true} loginRedirectUrl={loginRedirect} offeringId={rfp.data.data.id}>
      <div {...klass("container")}>
        <Grid klasses={O.none} attrs={O.none}>
          <MainGridLeft>
            <AccentDividerSection title={O.none} suppressDivider="xs" klasses={"mb-0"}>
              <RfpBadges
                rfp={rfp.data.data.record.data.rfp}
                bidSubmissionState={bidSubmissionState}
              />
              <HeaderWithSubscription
                isSubscribed={isSubscribed}
                onSubscribe={onSubscribe}
                taggedContent={O.some(rfp.data.data.record)}
                title={rfp.data.data.record.data.rfp.name}
                viewAllRoute={O.none}
                subscribeDisabled={isDrafted(rfp.data)}
              />
            </AccentDividerSection>
            <AccentDividerSection title={O.some("Summary")}>
              <DividerSection title={O.none}>
                <RfpFacts rfp={rfp.data.data.record.data.rfp} />
              </DividerSection>
              {mapOrEmpty((md: MarkdownType) =>
                <DividerSection
                  klasses={O.none}
                  title={O.some("Project Description")}
                >
                  <Markdown content={md} />
                </DividerSection>
              )(rfp.data.data.record.data.rfp.projectDescription)}
              {mapOrEmpty((md: MarkdownType) =>
                <DividerSection
                  klasses={O.none}
                  title={O.some("Submission Requirements")}
                >
                  <Markdown content={md} />
                </DividerSection>
              )(rfp.data.data.record.data.rfp.submissionRequirements)}
              <SummaryRelatedContent
                documentsO={documentsDataO}
                documentsHeadline="Documents"
                documentDownloadRoute={(issuerId, mediaId) => V2Router.investorPortalRfpsControllerDownloadDocument({ issuerId, rfpId: rfp.data.data.id, mediaId })}
                issuer={issuer}
                linksO={linksDataO}
                variant="page"
                leafIcon={IssuerSitesLeafIcon}
              />
              {pipe(
                rfp.data.data.record.data.relatedContent.program,
                mapOrEmpty(_ =>
                  <ProgramWithRatingRelatedContent
                    program={_}
                    issuer={issuer}
                    variant={"page"}
                  />
                )
              )}
            </AccentDividerSection>
            {bidSubmissionState.acceptsBidsOnSite && <AccentDividerSection title={O.some("Bid Submission")}>
              <p>{bidsDueBy} {O.getOrElse<ReactNode>(constEmpty)(submittedBidText)}</p>
              <BidSubmissionModalCallout
                user={props.user}
                rfp={pipe(rfp.data, modifyWithStatus(_ => _.data.rfp))}
                bidSubmissionTemplate={rfp.data.data.record.data.relatedContent.bidSubmissionTemplate}
                bidSubmission={rfp.data.data.record.data.relatedContent.bidSubmission}
                setBidSubmission={s => setRfp(bidSubmissionLens<BankRfp>().set(O.some(s))(rfp))}
                issuer={issuer}
                bidSubmissionState={bidSubmissionState}
                subscription={O.some({ isSubscribed, onSubscribe })}
                // the BE is going to subscribe the user to the RFP, need to keep UI in sync
                successAction={setSubscribedToRfp}
              />
            </AccentDividerSection>}
            <WinningBidSectionSites
              rfp={props.rfp.data.data.record.data.rfp}
              sidebarLinkHandle={O.none}
            />
            {pipe(
              roadshowDataO,
              mapOrEmpty(roadshows =>
                <RoadshowsSection
                  issuer={issuer}
                  roadshows={roadshows}
                  leafIcon={IssuerSitesLeafIcon}
                />
              ))}
            {pipe(
              RNEA.fromReadonlyArray(props.participants),
              mapOrEmpty(offeringParticipants =>
                <Participants
                  title="Participants"
                  participants={offeringParticipants}
                />
              ))}
            <RFPQuestionsAndAnswers
              rfpData={props.rfp.data.data.record.data}
              contactCallout={<RfpQuestionsAndAnswersCallout
                rfp={rfp.data.data.record.data.rfp}
                rfpId={rfp.data.data.id}
              />}
            />
          </MainGridLeft>
          <SideBarGridRightWithBorder>
            {pipe(
              props.issuerAboutText,
              mapOrEmpty(issuerAboutText =>
                <SidebarAboutSection issuer={issuer}>
                  <Markdown content={issuerAboutText.data} />
                </SidebarAboutSection>
              )
            )}
            {pipe(
              props.officers,
              RNEA.fromReadonlyArray,
              mapOrEmpty(team => <TeamSidebarSection team={team} />)
            )}
            {pipe(
              props.projects,
              RNEA.fromReadonlyArray,
              mapOrEmpty(projects => <ProjectSidebarSection projects={projects} />)
            )}
          </SideBarGridRightWithBorder>
        </Grid>
      </div>
    </BankIssuerChrome>
  );
};
