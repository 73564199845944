import { WithStatusCU as imported366_WithStatusCU, withIdC as imported264_withIdC, WithStatusCU as imported374_WithStatusCU, withIdC as imported265_withIdC, WithStatusCU as imported367_WithStatusCU, WithStatusCU as imported377_WithStatusCU, withIdC as imported263_withIdC, WithStatusCU as imported368_WithStatusCU, WithStatusCU as imported381_WithStatusCU, WithStatusCU as imported369_WithStatusCU, WithStatusCU as imported370_WithStatusCU, WithStatusCU as imported376_WithStatusCU, WithStatusCU as imported373_WithStatusCU, WithStatusCU as imported380_WithStatusCU, WithStatusCU as imported378_WithStatusCU, WithStatusCU as imported382_WithStatusCU, WithStatusCU as imported379_WithStatusCU, WithStatusCU as imported365_WithStatusCU, WithStatusCU as imported372_WithStatusCU, WithStatusCU as imported375_WithStatusCU, WithStatusCU as imported371_WithStatusCU } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { genRfpWithRelatedContentC as imported22_genRfpWithRelatedContentC, rfpCardRelatedContentC as imported6_rfpCardRelatedContentC, rfpCardRelatedContentC as imported7_rfpCardRelatedContentC, genRfpWithRelatedContentC as imported23_genRfpWithRelatedContentC } from "./rfp";
import { issuerRatingC as imported15_issuerRatingC, programRatingC as imported15_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported46_RatingAgencyCU, RatingAgencyCU as imported47_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { mediaC as imported85_mediaC, mediaC as imported86_mediaC, mediaC as imported87_mediaC, mediaC as imported88_mediaC } from "./media";
import { taggedContentC as imported206_taggedContentC, taggedContentC as imported199_taggedContentC, taggedContentC as imported209_taggedContentC, taggedContentC as imported204_taggedContentC, taggedContentC as imported203_taggedContentC, taggedContentC as imported208_taggedContentC, taggedContentC as imported201_taggedContentC, taggedContentC as imported207_taggedContentC, taggedContentC as imported202_taggedContentC, taggedContentC as imported197_taggedContentC, taggedContentC as imported205_taggedContentC, taggedContentC as imported210_taggedContentC, taggedContentC as imported198_taggedContentC, taggedContentC as imported200_taggedContentC } from "./taggedContent";
import { quickFactC as imported16_quickFactC, quickFactC as imported17_quickFactC } from "./quickFact";
import { bondProgramC as imported21_bondProgramC } from "./bondProgramBase";
import { subscribedC as imported39_subscribedC, subscribedC as imported38_subscribedC, subscribedC as imported41_subscribedC, subscribedC as imported40_subscribedC } from "./subscribed";
import { featuredItemC as imported1_featuredItemC } from "./featuredItem";
import { customPageDataOC as imported4_customPageDataOC, customPageDataOC as imported5_customPageDataOC } from "./customPages";
import { either } from "io-ts-types/lib/either";
import { bondOfferingC as imported27_bondOfferingC } from "./bondOfferingBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { addressC as imported13_addressC } from "./address";
import { roadShowDataC as imported31_roadShowDataC, roadShowViewC as imported1_roadShowViewC, roadShowDataC as imported32_roadShowDataC } from "./roadshow";
import { officerC as imported12_officerC, officerC as imported13_officerC } from "./officer";
import { projectWithPhotosC as imported2_projectWithPhotosC, projectWithPhotosC as imported3_projectWithPhotosC } from "./project";
import { MediaCategoryCU as imported9_MediaCategoryCU, MediaCategoryCU as imported10_MediaCategoryCU, MediaCategoryCU as imported11_MediaCategoryCU } from "../domaintables/mediaCategories";
import { issuerNewsC as imported23_issuerNewsC, issuerNewsC as imported24_issuerNewsC } from "./issuerNewsBase";
import { documentTypeC as imported11_documentTypeC, documentTypeC as imported12_documentTypeC, documentTypeC as imported13_documentTypeC, documentWithCategoryC as imported20_documentWithCategoryC } from "./document";
import { bondOfferingWithRoadshowsAndDocsC as imported4_bondOfferingWithRoadshowsAndDocsC, bondOfferingWithRoadshowsAndDocsC as imported5_bondOfferingWithRoadshowsAndDocsC } from "./bondOffering";
import { clientTextItemC as imported25_clientTextItemC, clientTextItemC as imported26_clientTextItemC, clientTextItemC as imported27_clientTextItemC, clientTextItemC as imported28_clientTextItemC } from "./clientTextItem";

export const issuerHomepageDataC = t.type({
  featuredItems: optionFromNullable(t.readonlyArray(imported197_taggedContentC(imported1_featuredItemC))),
  officer: optionFromNullable(imported365_WithStatusCU(imported12_officerC)),
  welcome: optionFromNullable(imported25_clientTextItemC),
  issuerRatings: t.readonlyArray(t.tuple([imported46_RatingAgencyCU, readonlyNonEmptyArrayC(imported366_WithStatusCU(imported15_issuerRatingC))])),
  programRatings: t.readonlyArray(t.tuple([imported263_withIdC(imported21_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported47_RatingAgencyCU, readonlyNonEmptyArrayC(imported367_WithStatusCU(imported15_programRatingC))]))])),
  offerings: t.readonlyArray(imported368_WithStatusCU(imported198_taggedContentC(imported27_bondOfferingC)))
});
export type IssuerHomepageDataC = typeof issuerHomepageDataC;
export type IssuerHomepageData = t.TypeOf<IssuerHomepageDataC>;


export const issuerAboutPageDataC = t.type({
  about: optionFromNullable(imported26_clientTextItemC),
  news: t.readonlyArray(imported369_WithStatusCU(imported199_taggedContentC(imported23_issuerNewsC))),
  projects: t.readonlyArray(imported370_WithStatusCU(imported200_taggedContentC(imported2_projectWithPhotosC))),
  team: t.readonlyArray(imported371_WithStatusCU(imported13_officerC)),
  callouts: t.readonlyArray(imported16_quickFactC),
  photos: t.readonlyArray(imported372_WithStatusCU(imported85_mediaC)),
  customPages: t.readonlyArray(imported4_customPageDataOC)
});
export type IssuerAboutPageDataC = typeof issuerAboutPageDataC;
export type IssuerAboutPageData = t.TypeOf<IssuerAboutPageDataC>;


export const issuerEsgPageDataC = t.type({
  overview: optionFromNullable(imported27_clientTextItemC),
  callouts: t.readonlyArray(imported17_quickFactC),
  photos: t.readonlyArray(imported373_WithStatusCU(imported86_mediaC)),
  bondOfferings: t.readonlyArray(imported38_subscribedC(imported374_WithStatusCU(imported201_taggedContentC(imported4_bondOfferingWithRoadshowsAndDocsC)))),
  rfps: t.readonlyArray(imported39_subscribedC(imported375_WithStatusCU(imported202_taggedContentC(imported22_genRfpWithRelatedContentC(imported6_rfpCardRelatedContentC))))),
  roadshows: t.readonlyArray(imported264_withIdC(imported203_taggedContentC(imported31_roadShowDataC))),
  projects: t.readonlyArray(imported376_WithStatusCU(imported204_taggedContentC(imported3_projectWithPhotosC))),
  documents: t.readonlyArray(imported377_WithStatusCU(imported205_taggedContentC(imported87_mediaC))),
  news: t.readonlyArray(imported378_WithStatusCU(imported206_taggedContentC(imported24_issuerNewsC)))
});
export type IssuerEsgPageDataC = typeof issuerEsgPageDataC;
export type IssuerEsgPageData = t.TypeOf<IssuerEsgPageDataC>;


export const issuerDocumentCategoriesPageDataC = t.type({
  categories: t.readonlyArray(t.tuple([either(imported11_documentTypeC, imported9_MediaCategoryCU), t.number])),
  archivedCategories: t.readonlyArray(t.tuple([either(imported12_documentTypeC, imported10_MediaCategoryCU), t.number])),
  irmaLetter: optionFromNullable(imported379_WithStatusCU(imported88_mediaC)),
  customPages: t.readonlyArray(imported5_customPageDataOC)
});
export type IssuerDocumentCategoriesPageDataC = typeof issuerDocumentCategoriesPageDataC;
export type IssuerDocumentCategoriesPageData = t.TypeOf<IssuerDocumentCategoriesPageDataC>;


export const issuerDownloadsPageDataC = t.type({
  documents: t.readonlyArray(imported380_WithStatusCU(imported207_taggedContentC(imported20_documentWithCategoryC))),
  category: optionFromNullable(either(imported13_documentTypeC, imported11_MediaCategoryCU))
});
export type IssuerDownloadsPageDataC = typeof issuerDownloadsPageDataC;
export type IssuerDownloadsPageData = t.TypeOf<IssuerDownloadsPageDataC>;


export const issuerResourcesPageDataC = t.type({
  address: optionFromNullable(imported13_addressC),
  customContact: optionFromNullable(imported28_clientTextItemC)
});
export type IssuerResourcesPageDataC = typeof issuerResourcesPageDataC;
export type IssuerResourcesPageData = t.TypeOf<IssuerResourcesPageDataC>;


export const roadShowSsrDataC = t.type({
  show: imported265_withIdC(imported208_taggedContentC(imported32_roadShowDataC)),
  view: optionFromNullable(imported1_roadShowViewC),
  offerings: t.readonlyArray(imported40_subscribedC(imported381_WithStatusCU(imported209_taggedContentC(imported5_bondOfferingWithRoadshowsAndDocsC)))),
  rfps: t.readonlyArray(imported41_subscribedC(imported382_WithStatusCU(imported210_taggedContentC(imported23_genRfpWithRelatedContentC(imported7_rfpCardRelatedContentC))))),
  sessionId: t.string
});
export type RoadShowSsrDataC = typeof roadShowSsrDataC;
export type RoadShowSsrData = t.TypeOf<RoadShowSsrDataC>;


