import { withIdC as imported179_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { Ord as numberOrd } from "fp-ts/lib/number";

export const participantTypeC = t.type({
  name: t.string,
  sortOrder: t.number
});
export type ParticipantTypeC = typeof participantTypeC;
export type ParticipantType = t.TypeOf<ParticipantTypeC>;


export const participantTypesC = t.type({
  types: t.readonlyArray(imported179_withIdC(participantTypeC)),
  usedTypes: readonlySetFromArray(t.number, numberOrd)
});
export type ParticipantTypesC = typeof participantTypesC;
export type ParticipantTypes = t.TypeOf<ParticipantTypesC>;


export const participantTypePostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  sortOrder: t.number
});
export type ParticipantTypePostC = typeof participantTypePostC;
export type ParticipantTypePost = t.TypeOf<ParticipantTypePostC>;


