import { issuerC as imported52_issuerC } from "./issuer";
import * as t from "io-ts";
import { bondOfferingC as imported25_bondOfferingC, bondOfferingC as imported26_bondOfferingC } from "./bondOfferingBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { addressC as imported12_addressC } from "./address";

export const issuerWithOfferingsC = t.type({
  issuer: imported52_issuerC,
  address: optionFromNullable(imported12_addressC),
  activeOfferings: t.readonlyArray(imported25_bondOfferingC),
  archivedOfferings: t.readonlyArray(imported26_bondOfferingC)
});
export type IssuerWithOfferingsC = typeof issuerWithOfferingsC;
export type IssuerWithOfferings = t.TypeOf<IssuerWithOfferingsC>;


export const issuersWithOfferingsC = t.type({
  issuers: t.readonlyArray(issuerWithOfferingsC)
});
export type IssuersWithOfferingsC = typeof issuersWithOfferingsC;
export type IssuersWithOfferings = t.TypeOf<IssuersWithOfferingsC>;


