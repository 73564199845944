import { withModInfoC as imported124_withModInfoC, withModInfoC as imported126_withModInfoC, withModInfoC as imported131_withModInfoC, withModInfoC as imported125_withModInfoC, withModInfoC as imported132_withModInfoC, withModInfoC as imported128_withModInfoC, withModInfoC as imported129_withModInfoC, withIdC as imported159_withIdC, withIdC as imported155_withIdC, withIdC as imported154_withIdC, withIdC as imported162_withIdC, withIdC as imported163_withIdC, withIdC as imported160_withIdC, withModInfoC as imported123_withModInfoC, withModInfoC as imported127_withModInfoC, withIdC as imported161_withIdC, withModInfoC as imported130_withModInfoC, withIdC as imported156_withIdC, withIdC as imported158_withIdC, withIdC as imported157_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { theseC } from "../../codecs/these";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { detailsC as imported96_detailsC, tabCountC as imported10_tabCountC, detailsC as imported94_detailsC, detailsC as imported99_detailsC, detailsC as imported98_detailsC, tabCountC as imported11_tabCountC, tabCountC as imported12_tabCountC, tabCountC as imported9_tabCountC, detailsC as imported91_detailsC, detailsC as imported97_detailsC, detailsC as imported95_detailsC, detailsC as imported93_detailsC, detailsC as imported92_detailsC } from "./investorActivityActions";
import { either } from "io-ts-types/lib/either";
import { rfpC as imported34_rfpC, rfpC as imported32_rfpC, rfpC as imported31_rfpC, rfpC as imported33_rfpC, rfpC as imported35_rfpC } from "./rfpBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { bondOfferingDetailsC as imported23_bondOfferingDetailsC, bondOfferingDetailsC as imported25_bondOfferingDetailsC, bondOfferingDetailsC as imported21_bondOfferingDetailsC, bondOfferingDetailsC as imported24_bondOfferingDetailsC, bondOfferingDetailsC as imported22_bondOfferingDetailsC } from "./bondOffering";
import { ipreoHoldingsC as imported7_ipreoHoldingsC } from "./ipreoHoldings";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";
import { actorC as imported5_actorC } from "./actor";
import { contactFormSubmissionC as imported11_contactFormSubmissionC } from "./investorActivityTypes";

export const offeringIdsC = t.type({
  offeringIds: t.readonlyArray(t.number)
});
export type OfferingIdsC = typeof offeringIdsC;
export type OfferingIds = t.TypeOf<OfferingIdsC>;


export const offeringAndRfpIdsC = t.type({
  offeringIds: t.readonlyArray(t.number),
  rfpIds: t.readonlyArray(t.number)
});
export type OfferingAndRfpIdsC = typeof offeringAndRfpIdsC;
export type OfferingAndRfpIds = t.TypeOf<OfferingAndRfpIdsC>;


export const emailC = t.type({
  emailCampaignId: optionFromNullable(t.number),
  subject: t.string,
  issuerId: optionFromNullable(t.number),
  rfpOrOfferingData: optionFromNullable(either(imported154_withIdC(imported123_withModInfoC(imported31_rfpC)), imported155_withIdC(imported124_withModInfoC(imported21_bondOfferingDetailsC))))
});
export type EmailC = typeof emailC;
export type Email = t.TypeOf<EmailC>;


export const roadShowC = t.type({
  title: t.string,
  issuerId: t.number,
  rfpOrOfferingData: theseC(readonlyNonEmptyArrayC(imported156_withIdC(imported125_withModInfoC(imported32_rfpC))), readonlyNonEmptyArrayC(imported157_withIdC(imported126_withModInfoC(imported22_bondOfferingDetailsC))))
});
export type RoadShowC = typeof roadShowC;
export type RoadShow = t.TypeOf<RoadShowC>;


export const documentC = t.type({
  title: t.string,
  issuerId: t.number,
  rfpOrOfferingData: optionFromNullable(either(imported158_withIdC(imported127_withModInfoC(imported33_rfpC)), imported159_withIdC(imported128_withModInfoC(imported23_bondOfferingDetailsC))))
});
export type DocumentC = typeof documentC;
export type Document = t.TypeOf<DocumentC>;


export const activitiesC = t.type({
  viewedOffering: t.number,
  viewedRoadshow: readonlyMapFromEntries(t.number, numberOrd, t.number),
  downloadedDocument: readonlyMapFromEntries(t.number, numberOrd, t.number),
  viewedDocument: t.number,
  submittedContactForm: t.number,
  receivedEmail: t.number,
  openedEmail: t.number,
  clickedEmail: t.number,
  didAnythingOtherThanReceivedEmail: t.number,
  viewedRfp: t.number
});
export type ActivitiesC = typeof activitiesC;
export type Activities = t.TypeOf<ActivitiesC>;


export const documentDownloadedC = t.type({
  _tag: t.literal(`DocumentDownloaded`),
  details: imported91_detailsC,
  document: documentC
});
export type DocumentDownloadedC = typeof documentDownloadedC;
export type DocumentDownloaded = t.TypeOf<DocumentDownloadedC>;


export const documentViewedC = t.type({
  _tag: t.literal(`DocumentViewed`),
  details: imported92_detailsC,
  document: documentC
});
export type DocumentViewedC = typeof documentViewedC;
export type DocumentViewed = t.TypeOf<DocumentViewedC>;


export const emailOpenedC = t.type({
  _tag: t.literal(`EmailOpened`),
  details: imported93_detailsC,
  email: emailC,
  receivedDate: LocalDateTimeFromIsoStringC
});
export type EmailOpenedC = typeof emailOpenedC;
export type EmailOpened = t.TypeOf<EmailOpenedC>;


export const emailLinkClickedC = t.type({
  _tag: t.literal(`EmailLinkClicked`),
  details: imported94_detailsC,
  email: emailC,
  receivedDate: LocalDateTimeFromIsoStringC
});
export type EmailLinkClickedC = typeof emailLinkClickedC;
export type EmailLinkClicked = t.TypeOf<EmailLinkClickedC>;


export const emailReceivedC = t.type({
  _tag: t.literal(`EmailReceived`),
  details: imported95_detailsC,
  email: emailC
});
export type EmailReceivedC = typeof emailReceivedC;
export type EmailReceived = t.TypeOf<EmailReceivedC>;


export const offeringViewedC = t.type({
  _tag: t.literal(`OfferingViewed`),
  details: imported96_detailsC,
  offering: imported160_withIdC(imported129_withModInfoC(imported24_bondOfferingDetailsC))
});
export type OfferingViewedC = typeof offeringViewedC;
export type OfferingViewed = t.TypeOf<OfferingViewedC>;


export const rfpViewedC = t.type({
  _tag: t.literal(`RfpViewed`),
  details: imported97_detailsC,
  rfp: imported161_withIdC(imported130_withModInfoC(imported34_rfpC))
});
export type RfpViewedC = typeof rfpViewedC;
export type RfpViewed = t.TypeOf<RfpViewedC>;


export const contactFormSubmittedC = t.type({
  _tag: t.literal(`ContactFormSubmitted`),
  details: imported98_detailsC,
  submissionInfo: imported11_contactFormSubmissionC,
  issuerId: t.number,
  rfpOrOfferingData: either(imported162_withIdC(imported131_withModInfoC(imported35_rfpC)), imported163_withIdC(imported132_withModInfoC(imported25_bondOfferingDetailsC)))
});
export type ContactFormSubmittedC = typeof contactFormSubmittedC;
export type ContactFormSubmitted = t.TypeOf<ContactFormSubmittedC>;


export const roadShowViewedC = t.type({
  _tag: t.literal(`RoadShowViewed`),
  details: imported99_detailsC,
  roadShow: roadShowC
});
export type RoadShowViewedC = typeof roadShowViewedC;
export type RoadShowViewed = t.TypeOf<RoadShowViewedC>;


export const allBankerActivityTabC = [documentDownloadedC, documentViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, roadShowViewedC] as const;
export const allBankerActivityTabNames = [`DocumentDownloaded`, `DocumentViewed`, `EmailOpened`, `EmailLinkClicked`, `EmailReceived`, `OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `RoadShowViewed`] as const;
export type BankerActivityTabName = (typeof allBankerActivityTabNames)[number];

export const BankerActivityTabCU = t.union([documentDownloadedC, documentViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, roadShowViewedC]);
export type BankerActivityTabCU = typeof BankerActivityTabCU;
export type BankerActivityTabU = t.TypeOf<BankerActivityTabCU>;

export type BankerActivityTabMap<A> = { [K in BankerActivityTabName]: A };


export const allDocumentActivityC = [documentDownloadedC, documentViewedC] as const;
export const allDocumentActivityNames = [`DocumentDownloaded`, `DocumentViewed`] as const;
export type DocumentActivityName = (typeof allDocumentActivityNames)[number];

export const DocumentActivityCU = t.union([documentDownloadedC, documentViewedC]);
export type DocumentActivityCU = typeof DocumentActivityCU;
export type DocumentActivityU = t.TypeOf<DocumentActivityCU>;

export type DocumentActivityMap<A> = { [K in DocumentActivityName]: A };


export const allEmailActivityC = [emailOpenedC, emailLinkClickedC, emailReceivedC] as const;
export const allEmailActivityNames = [`EmailOpened`, `EmailLinkClicked`, `EmailReceived`] as const;
export type EmailActivityName = (typeof allEmailActivityNames)[number];

export const EmailActivityCU = t.union([emailOpenedC, emailLinkClickedC, emailReceivedC]);
export type EmailActivityCU = typeof EmailActivityCU;
export type EmailActivityU = t.TypeOf<EmailActivityCU>;

export type EmailActivityMap<A> = { [K in EmailActivityName]: A };


export const allSiteActivityC = [offeringViewedC, rfpViewedC, contactFormSubmittedC, roadShowViewedC] as const;
export const allSiteActivityNames = [`OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `RoadShowViewed`] as const;
export type SiteActivityName = (typeof allSiteActivityNames)[number];

export const SiteActivityCU = t.union([offeringViewedC, rfpViewedC, contactFormSubmittedC, roadShowViewedC]);
export type SiteActivityCU = typeof SiteActivityCU;
export type SiteActivityU = t.TypeOf<SiteActivityCU>;

export type SiteActivityMap<A> = { [K in SiteActivityName]: A };


export const allBankerActivityC = [documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, roadShowViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC] as const;
export const allBankerActivityNames = [`DocumentDownloaded`, `DocumentViewed`, `OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `RoadShowViewed`, `EmailOpened`, `EmailLinkClicked`, `EmailReceived`] as const;
export type BankerActivityName = (typeof allBankerActivityNames)[number];

export const BankerActivityCU = t.union([documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, roadShowViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC]);
export type BankerActivityCU = typeof BankerActivityCU;
export type BankerActivityU = t.TypeOf<BankerActivityCU>;

export type BankerActivityMap<A> = { [K in BankerActivityName]: A };


export const tabCountsC = t.type({
  all: imported9_tabCountC(BankerActivityCU),
  site: imported10_tabCountC(SiteActivityCU),
  email: imported11_tabCountC(EmailActivityCU),
  document: imported12_tabCountC(DocumentActivityCU)
});
export type TabCountsC = typeof tabCountsC;
export type TabCounts = t.TypeOf<TabCountsC>;


export const investorProfileInfoC = t.type({
  actor: imported5_actorC,
  investorActivities: t.readonlyArray(BankerActivityCU),
  tabCounts: tabCountsC,
  holdings: t.readonlyArray(imported7_ipreoHoldingsC)
});
export type InvestorProfileInfoC = typeof investorProfileInfoC;
export type InvestorProfileInfo = t.TypeOf<InvestorProfileInfoC>;


export const allNonEmailSendActivityC = [documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, roadShowViewedC, emailOpenedC, emailLinkClickedC] as const;
export const allNonEmailSendActivityNames = [`DocumentDownloaded`, `DocumentViewed`, `OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `RoadShowViewed`, `EmailOpened`, `EmailLinkClicked`] as const;
export type NonEmailSendActivityName = (typeof allNonEmailSendActivityNames)[number];

export const NonEmailSendActivityCU = t.union([documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, roadShowViewedC, emailOpenedC, emailLinkClickedC]);
export type NonEmailSendActivityCU = typeof NonEmailSendActivityCU;
export type NonEmailSendActivityU = t.TypeOf<NonEmailSendActivityCU>;

export type NonEmailSendActivityMap<A> = { [K in NonEmailSendActivityName]: A };


