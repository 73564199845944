import type { AnchorHTMLAttributes, MouseEvent, MouseEventHandler, PropsWithChildren, ReactNode } from "react";
import { Fragment, useCallback } from "react";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { SVGString } from "*.svg";

import { Static } from "@scripts/bondlinkStatic";
import { social } from "@scripts/generated/models/blConfig";
import type { EmptyObject } from "@scripts/generated/models/emptyObject";
import { type Variant } from "@scripts/react/components/Button";
import { Svg } from "@scripts/react/components/Svg";
import type { PageRouteLink } from "@scripts/routes/routing/base";
import { getRouteInfo } from "@scripts/routes/routing/base";
import type { ChildrenWithAriaLabelReq, ChildStringOrAriaLabel } from "@scripts/types/ariaLabel";
import { delProp } from "@scripts/util/delProp";
import { exhaustiveNoLog } from "@scripts/util/exhaustive";
import type { LabelOrAriaLabel, ReactChildOrAriaLabel } from "@scripts/util/labelOrAriaLabel";

import download from "@svgs/download.svg";
import externalLink from "@svgs/external-link.svg";
import linkedInIcon from "@svgs/linkedin.svg";
import twitterIcon from "@svgs/twitter.svg";

import { useConfig } from "../context/Config";
import type { KlassProp } from "../util/classnames";
import { klass, klassPropO, mergeKlassPropO } from "../util/classnames";
import type { CardNavigationType } from "./card/Card";
import { constEmpty } from "./Empty";

type ExternalLinkLocation = "prepend" | "append" | "none";
export type Target = "_blank" | "_self";

interface AnchorRawProps<A extends Element> {
  href: string;
  targetRaw: string;
  onClick?: MouseEventHandler<A>;
  klasses: KlassProp;
  tabIndex?: number;
  disabled?: boolean;
}

interface AnchorBasePropsI {
  target: Target;
  externalLinkLocation?: ExternalLinkLocation;
  klasses?: KlassProp;
  disabled?: boolean;
}

type OmittedAnchorProps = "href" | "rel" | "target" | "title";

type AnchorBaseProps = AnchorBasePropsI & Omit<AnchorHTMLAttributes<HTMLElement>, OmittedAnchorProps>;

export type AnchorProps = AnchorBaseProps & {
  route: PageRouteLink;
};

type ArrowTypeU = "left" | "right";
export type ArrowType = { arrowType?: ArrowTypeU };

type AnchorUnsafeProps = AnchorBaseProps & {
  href: string;
  title: string;
};

export type AnchorUnsafeWithChildrenProps = AnchorBaseProps & Omit<AnchorUnsafeProps, "title">;

type AnchorButtonProps = AnchorBaseProps & AnchorProps & {
  variant: Variant;
};

type AnchorButtonUnsafeProps = AnchorBaseProps & AnchorUnsafeProps & {
  variant: Variant;
};

type AnchorButtonUnsafeWithChildrenProps = AnchorBaseProps & Omit<AnchorButtonUnsafeProps, "title">;

const externalLinkContainer = <Svg src={externalLink} />;

const externalLinkIcon =
  (target: Target, location: ExternalLinkLocation | undefined, node: ReactNode) => {
    const l: ExternalLinkLocation = isTargetBlank(target) ? (location || "append") : "none";

    switch (l) {
      case "prepend": return <Fragment>{externalLinkContainer}&nbsp;{node}</Fragment>;
      case "append": return <Fragment>{node}&nbsp;{externalLinkContainer}</Fragment>;
      case "none": return node;
    }

    return exhaustiveNoLog(l);
  };

export const arrowTypeKlassO = (arrowType: O.Option<ArrowTypeU>, klasses?: KlassProp) => {
  const arrowTypeNullable = O.toUndefined(arrowType);
  return klassPropO([!arrowTypeNullable ? "" : (arrowTypeNullable === "left" ? "pagelink-left" : "pagelink")])(klasses);
};


const isTargetBlank = (t: string): boolean => t === "_blank";
const rel = (t: string): string => isTargetBlank(t) ? "noopener noreferrer" : "";
const variant = (v: string): string => `btn ${v !== "none" ? `btn-${v}` : ""}`;
const windowReload = () => window.location.reload();
const preventDefault = (e: MouseEvent<HTMLAnchorElement>): void => {
  e.preventDefault();
  e.nativeEvent.stopImmediatePropagation();
};


// Raw Anchor used by all others
const AnchorRaw = (props: PropsWithChildren<AnchorRawProps<HTMLAnchorElement>>) => {
  const aProps = delProp(props, "children", "klasses", "targetRaw");

  const handleDisabledClick = (e: MouseEvent<HTMLAnchorElement>) => {
    preventDefault(e);
    return false;
  };

  return (
    <a
      {...aProps}
      onClick={props.disabled
        ? handleDisabledClick
        : aProps.onClick
      }
      target={props.targetRaw}
      rel={rel(props.targetRaw)}
      {...klassPropO(props.disabled ? "disabled" : O.none)(props.klasses)}
    >
      {props.children}
    </a>
  );
};
// Normal Anchors

export const Anchor = (props: AnchorProps & ArrowType) => {
  const { url, title } = getRouteInfo(props.route);
  const anchorRawProps = delProp(props, "externalLinkLocation", "route", "arrowType");

  return (
    <AnchorRaw {...anchorRawProps}
      href={url}
      targetRaw={props.target}
      klasses={arrowTypeKlassO(O.fromNullable(props.arrowType), props.klasses).className}
    >
      {externalLinkIcon(props.target, props.externalLinkLocation, title)}
    </AnchorRaw>
  );
};

export const AnchorDownloadWithChildren = (props: AnchorProps) => {
  const title = getRouteInfo(props.route).title;
  return (
    <AnchorWithChildren
      {...props}
      aria-label={title}
      externalLinkLocation="none"
    >
      {props.children}{title}
    </AnchorWithChildren>
  );
};

export const AnchorDownload = (props: Omit<AnchorProps, "icon">) => <AnchorDownloadWithChildren {...props}>
  <Svg src={download} />
</AnchorDownloadWithChildren>;

export const AnchorWithChildren = (props: AnchorProps & ChildStringOrAriaLabel & ArrowType) => {
  const { url } = getRouteInfo(props.route);
  const anchorRawProps = delProp(props, "externalLinkLocation", "route", "arrowType");

  const ariaLabel = typeof props.children !== "string"
    ? { "aria-label": props["aria-label"] }
    : {};

  return (
    <AnchorRaw
      {...anchorRawProps}
      {...ariaLabel}
      href={url}
      targetRaw={props.target}
      klasses={arrowTypeKlassO(O.fromNullable(props.arrowType), props.klasses).className}
    >
      {externalLinkIcon(props.target, props.externalLinkLocation, props.children)}
    </AnchorRaw>
  );
};

export const AnchorUnsafe = (props: AnchorUnsafeProps & ArrowType) => {
  const anchorRawProps = delProp(props, "externalLinkLocation", "title", "arrowType");

  return (
    <AnchorRaw
      {...anchorRawProps}
      targetRaw={props.target}
      klasses={arrowTypeKlassO(O.fromNullable(props.arrowType), props.klasses).className}
    >
      {externalLinkIcon(props.target, props.externalLinkLocation, props.title)}
    </AnchorRaw>
  );
};

export const AnchorMailTo = (props:
  Pick<AnchorUnsafeProps, "klasses" | "disabled">
  & { email: string, title?: AnchorUnsafeProps["title"] }
) => <AnchorUnsafe
    externalLinkLocation="none"
    href={`mailto:${props.email}`}
    target={"_blank"}
    title={props.title ?? props.email}
    klasses={props.klasses}
    disabled={props.disabled}
/>;

export const AnchorUnsafeWithChildren = (props: AnchorUnsafeWithChildrenProps & ChildrenWithAriaLabelReq) => {
  const anchorRawProps = delProp(props, "externalLinkLocation");

  return (
    <AnchorRaw {...anchorRawProps}
      targetRaw={props.target}
      klasses={klassPropO([])(props.klasses).className}
    >
      {externalLinkIcon(props.target, props.externalLinkLocation, props.children)}
    </AnchorRaw>
  );
};

export type ContactBondLinkAnchorProps = Omit<AnchorUnsafeProps, "href" | "target" | "externalLinkLocation">;
export const ContactBondLinkAnchor = (props: ContactBondLinkAnchorProps) => {
  const config = useConfig();

  return <AnchorMailTo
    email={config.customerSuccessEmail}
    klasses={props.klasses}
    title={props.title}
    disabled={props.disabled}
  />;

};

export type ContactBLPAnchorProps = Omit<AnchorUnsafeProps, "href" | "target" | "externalLinkLocation">;
export const ContactBLPAnchor = (props: ContactBLPAnchorProps) => {
  const config = useConfig();

  return <AnchorMailTo
    email={config.bondlinkPrimaryEmail}
    klasses={props.klasses}
    title={props.title}
    disabled={props.disabled}
  />;
};

export const BondLinkHelpPageAnchor = (props: PropsWithChildren<ContactBondLinkAnchorProps>) =>
  <AnchorUnsafe
    title={props.title}
    href={Static.issuerHelpUrl}
    target="_blank"
    externalLinkLocation="none"
    klasses={props.klasses}
    disabled={props.disabled}
  />;

type ContactBondLinkAnchorButtonProps = Omit<AnchorButtonUnsafeWithChildrenProps, "href" | "target" | "externalLinkLocation">;
export const ContactBondLinkAnchorButton = (props: PropsWithChildren<ContactBondLinkAnchorButtonProps>) => {
  const config = useConfig();

  return <AnchorButtonUnsafeWithChildren
    variant={props.variant}
    href={`mailto:${config.customerSuccessEmail}`}
    target="_blank"
    externalLinkLocation="none"
    klasses={props.klasses}
    disabled={props.disabled}
  >
    {props.children}
  </AnchorButtonUnsafeWithChildren>;
};

type ReloadAnchorProps = PropsWithChildren<Omit<AnchorUnsafeProps, "href" | "target" | "externalLinkLocation" | "onClick">>;
export const ReloadAnchor = (props: ReloadAnchorProps) =>
  <AnchorUnsafe
    href={""}
    target="_self"
    externalLinkLocation="none"
    onClick={windowReload}
    klasses={props.klasses}
    title={props.title}
  />;

export const BondLinkLinkedInAnchor = () =>
  <AnchorUnsafeWithChildren
    aria-label="LinkedIn account for BondLink"
    externalLinkLocation="none"
    klasses={"btn btn-social"}
    target="_blank"
    href={social.linkedInUrl}
  >
    <Svg src={linkedInIcon} />
  </AnchorUnsafeWithChildren>;

export const BondLinkTwitterAnchor = () =>
  <AnchorUnsafeWithChildren
    aria-label="Twitter account for BondLink"
    externalLinkLocation="none"
    klasses={"btn btn-social"}
    href={social.twitterUrl}
    target="_blank"
  >
    <Svg src={twitterIcon} />
  </AnchorUnsafeWithChildren>;

// Anchor Buttons

export const AnchorButton = (props: AnchorButtonProps) => {
  const { url, title } = getRouteInfo(props.route);
  const anchorRawProps = delProp(props, "externalLinkLocation", "route", "variant");

  return (
    <AnchorRaw {...anchorRawProps}
      href={url}
      targetRaw={props.target}
      klasses={klassPropO([variant(props.variant)])(props.klasses).className}
    >
      {externalLinkIcon(props.target, props.externalLinkLocation, title)}
    </AnchorRaw>
  );
};

export const AnchorButtonWithChildren = (props: PropsWithChildren<AnchorButtonProps>) => {
  const { url } = getRouteInfo(props.route);
  const anchorRawProps = delProp(props, "externalLinkLocation", "route", "variant");

  return (
    <AnchorRaw {...anchorRawProps}
      href={url}
      targetRaw={props.target}
      klasses={klassPropO([variant(props.variant)])(props.klasses).className}
    >
      {externalLinkIcon(props.target, props.externalLinkLocation, props.children)}
    </AnchorRaw>
  );
};

export const AnchorButtonUnsafe = (props: AnchorButtonUnsafeProps) => {
  const anchorRawProps = delProp(props, "externalLinkLocation", "variant");
  return (
    <AnchorRaw {...anchorRawProps}
      href={props.href}
      targetRaw={props.target}
      klasses={klassPropO([variant(props.variant)])(props.klasses).className}
    >
      {externalLinkIcon(props.target, props.externalLinkLocation, props.title)}
    </AnchorRaw>
  );
};

export const AnchorButtonUnsafeWithChildren = (props: PropsWithChildren<AnchorButtonUnsafeWithChildrenProps>) => {
  const anchorRawProps = delProp(props, "externalLinkLocation", "variant");
  return (
    <AnchorRaw {...anchorRawProps}
      href={props.href}
      targetRaw={props.target}
      klasses={klassPropO([variant(props.variant)])(props.klasses).className}
    >
      {externalLinkIcon(props.target, props.externalLinkLocation, props.children)}
    </AnchorRaw>
  );
};

type AnchorOverrideIntUnsafeProps = {
  url: string;
  navigation: { dispatch: () => void };
  title: string;
  klasses?: KlassProp;
};
const AnchorOverrideIntUnsafe = (props: AnchorOverrideIntUnsafeProps) => {
  const onClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    preventDefault(e);
    props.navigation.dispatch();
    return false;
  }, [props.navigation]);

  return <AnchorRaw
    href={props.url}
    targetRaw="_self"
    onClick={onClick}
    klasses={klassPropO([])(props.klasses).className}
  >
    {props.title}
  </AnchorRaw>;
};

type AnchorOverrideExtUnsafeProps = {
  url: string;
  title: string;
  klasses?: KlassProp;
};
const AnchorOverrideExtUnsafe = (props: AnchorOverrideExtUnsafeProps) => {
  const onClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    preventDefault(e);
    return false;
  }, []);

  return <AnchorRaw
    href={props.url}
    targetRaw="_blank"
    onClick={onClick}
    klasses={klassPropO([])(props.klasses).className}
  >
    {props.title}
  </AnchorRaw>;
};

type AnchorOverrideNavProps = {
  route: PageRouteLink;
  navigation: CardNavigationType;
  title?: string;
  klasses?: KlassProp;
};
export const AnchorOverrideNav = (props: AnchorOverrideNavProps) => {
  const { url, title } = getRouteInfo(props.route);
  return pipe(
    props.navigation,
    E.fold(
      nav => <AnchorOverrideIntUnsafe url={url} title={props.title ? props.title : title} navigation={nav} klasses={props.klasses} />,
      () => <AnchorOverrideExtUnsafe url={url} title={title} klasses={props.klasses} />
    )
  );
};

// Without this structure with inline the underline on the link will be on the entire element
// rather than under all of the text
export type AnchorIconLayoutProps = Pick<AnchorProps, "disabled"> & {
  appendIcon?: true;
  icon: SVGString;
  textOrAriaLabel: ReactChildOrAriaLabel;
};

export const AnchorIconLayout = (props: AnchorIconLayoutProps) =>
  <div className={`icon-link-style ${props.disabled ? "disabled" : ""}`}>
    {props.appendIcon || (
      <div {...klass("link-icon-container", "mr-025")}>
        <Svg src={props.icon} />
      </div>
    )}
    {pipe(
      props.textOrAriaLabel,
      E.fold(text =>
        <div {...klass("d-inline", "text-left")}>
          <span>{text}</span>
        </div>,
        constEmpty,
      )
    )}
    {props.appendIcon && (
      <div {...klass("link-icon-container", "ml-025")}>
        <Svg src={props.icon} />
      </div>
    )}
  </div>;

export type AnchorIconProps = Omit<AnchorIconLayoutProps, "title" | "textOrAriaLabel"> & Pick<AnchorProps, "route" | "target" | "klasses" | "id">
  & Pick<AnchorHTMLAttributes<HTMLElement>, "onClick"> & Pick<Partial<AnchorIconLayoutProps>, "textOrAriaLabel">;

export const AnchorIcon = (props: AnchorIconProps) =>
  <AnchorWithChildren
    aria-label={getRouteInfo(props.route).title}
    externalLinkLocation="none"
    id={props.id}
    onClick={props.onClick}
    route={props.route}
    target={props.target}
    disabled={props.disabled}
    klasses={mergeKlassPropO("bb-0")(props.klasses)}
  >
    <AnchorIconLayout
      appendIcon={props.appendIcon}
      disabled={props.disabled || false}
      icon={props.icon}
      textOrAriaLabel={props.textOrAriaLabel ?? E.left(getRouteInfo(props.route).title)}
    />
  </AnchorWithChildren>;

export const AnchorIconSubtitle = (props: PropsWithChildren<EmptyObject>) =>
  <div {...klass("icon-link-subtitle-container")}>
    <span>{props.children}</span>
  </div>;

type AnchorIconUnsafeProps = Omit<AnchorIconProps, "route"> & Pick<AnchorUnsafeProps, "href"> & { textOrAriaLabel: LabelOrAriaLabel };

export const AnchorIconUnsafe = (props: AnchorIconUnsafeProps) =>
  <AnchorUnsafeWithChildren
    aria-label={E.toUnion(props.textOrAriaLabel)}
    externalLinkLocation="none"
    href={props.href}
    onClick={props.onClick}
    target={props.target}
    disabled={props.disabled}
    klasses={klassPropO("no-decoration")(props.klasses).className}
  >
    <AnchorIconLayout
      appendIcon={props.appendIcon}
      disabled={props.disabled || false}
      icon={props.icon}
      textOrAriaLabel={props.textOrAriaLabel}
    />
  </AnchorUnsafeWithChildren>;
