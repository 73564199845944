import { WithStatusCU as imported239_WithStatusCU } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { programRatingC as imported13_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported36_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { ratingWithRelatedContentC as imported21_ratingWithRelatedContentC } from "./rating";
import { bondProgramC as imported17_bondProgramC } from "./bondProgramBase";

export const bondProgramWithRatingsC = t.type({
  program: imported17_bondProgramC,
  ratings: t.readonlyArray(t.tuple([imported36_RatingAgencyCU, readonlyNonEmptyArrayC(imported239_WithStatusCU(imported21_ratingWithRelatedContentC(imported13_programRatingC)))]))
});
export type BondProgramWithRatingsC = typeof bondProgramWithRatingsC;
export type BondProgramWithRatings = t.TypeOf<BondProgramWithRatingsC>;


