import { withModInfoC as imported177_withModInfoC, withIdC as imported233_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported73_mediaC, mediaUploadResponseC as imported21_mediaUploadResponseC } from "./media";
import { HrefLocationCU as imported2_HrefLocationCU, HrefLocationCU as imported3_HrefLocationCU } from "../domaintables/hrefLocations";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BucketCU as imported9_BucketCU } from "../domaintables/pages";

export const hrefC = t.type({
  href: optionFromNullable(t.string),
  text: t.string,
  calloutText: optionFromNullable(t.string),
  sortOrder: t.number,
  location: imported2_HrefLocationCU,
  pageHeader: optionFromNullable(imported9_BucketCU),
  media: optionFromNullable(imported233_withIdC(imported177_withModInfoC(imported73_mediaC))),
  url: t.string
});
export type HrefC = typeof hrefC;
export type Href = t.TypeOf<HrefC>;


export const hrefPostBodyC = t.type({
  id: optionFromNullable(t.number),
  href: optionFromNullable(t.string),
  location: imported3_HrefLocationCU,
  text: t.string,
  pageHeaderId: optionFromNullable(t.number),
  media: optionFromNullable(imported21_mediaUploadResponseC),
  mediaId: optionFromNullable(t.number),
  sortOrder: t.number
});
export type HrefPostBodyC = typeof hrefPostBodyC;
export type HrefPostBody = t.TypeOf<HrefPostBodyC>;


