import type { ReactElement } from "react";
import { Fragment } from "react";
import * as b from "fp-ts/lib/boolean";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as RNA from "fp-ts/lib/ReadonlyNonEmptyArray";

export const oxfordCommaStringN = (strings: RNA.ReadonlyNonEmptyArray<string>) =>
  pipe(
    strings.length === 1,
    b.fold(
      () => pipe(
        strings.length === 2,
        b.fold(
          () => pipe(
            strings,
            RNA.matchRight((firsts, last) => `${firsts.join(", ")}, and ${last}`)
          ),
          () => strings.join(" and ")
        )),
      () => strings[0]
    )
  );

export const oxfordCommaString = (as: string[]) => O.map(oxfordCommaStringN)(RNA.fromArray(as));

export const oxfordCommaElements = (as: ReactElement[]): O.Option<ReactElement> => pipe(
  RNA.fromArray(as),
  O.map(ls => pipe(
    ls.length === 1,
    b.fold(
      () => pipe(
        ls.length === 2,
        b.fold(
          () => pipe(ls,
            RNA.matchRight<ReactElement, ReactElement>((fs, l) =>
              <>{RA.mapWithIndex((i, e: ReactElement) => <Fragment key={`${i}-comma`}>{e}, </Fragment>)(fs)}and {l}</>)
          ),
          () => <>{RNA.head(ls)} and {RNA.last(ls)}</>,
        )
      ),
      () => RNA.head(ls)
    )
  )),
);
