import { withIdC as imported259_withIdC, withIdC as imported260_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { markdownC } from "../../codecs/markdown";
import { mediaC as imported82_mediaC, mediaC as imported83_mediaC } from "./media";
import { BondLinkResourceTypeCU as imported1_BondLinkResourceTypeCU } from "../domaintables/bondLinkResourceTypes";
import { LocalDateC } from "../../codecs/localDate";

export const bondLinkResourceC = t.type({
  resourceType: imported1_BondLinkResourceTypeCU,
  title: t.string,
  description: markdownC,
  resourceDate: LocalDateC,
  resource: imported259_withIdC(imported82_mediaC),
  image: imported260_withIdC(imported83_mediaC)
});
export type BondLinkResourceC = typeof bondLinkResourceC;
export type BondLinkResource = t.TypeOf<BondLinkResourceC>;


